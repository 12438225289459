<template>
  <div class="login_view" :style="{'width': ifPC ? '480px' : '350px', 'padding': ifPC ? '20px 30px 50px 30px' : '20px 0px 50px 0px'}">
    <div class="login_title">登录/注册</div>
    <el-form label-position="top" :model="loginForm" status-icon ref="loginForm" label-width="60px">
      <el-form-item label="邮箱" prop="email" style="margin-right: 30px;margin-left: 30px;">
        <EmailInput ref="emailInput" @change="emailChange" />
      </el-form-item>
      <el-form-item label="验证码" prop="code" style="margin-right: 30px;margin-left: 30px;">
        <div class="code_content">
          <el-input size="mini" v-model="loginForm.code" autocomplete="off"></el-input>
          <div v-if="count === 0" class="code_btn code_btn_common" @click="sendCode">发送验证码</div>
          <div v-else class="code_btn code_btn_success">{{ count }}</div>
        </div>
      </el-form-item>
      <div class="btn_content">
        <el-button class="submit" size="mini" type="primary" @click="submitForm()">登录 - 注册</el-button>
        <el-button class="reset" size="mini" @click="resetForm('loginForm')">清空</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { loginByEmail, verifyEmailCode, registerUser, getEmailCode } from '@/api/login'
  import { getUserInfo } from '@/api/user'
  import EmailInput from '@/components/Input/EmailInput.vue'
  export default {
    name: 'LoginView',
    components: { EmailInput },
    data() {
      return {
        timer: null,
        count: 0,
        loginForm: {
          email: '',
          code: ''
        }
      }
    },
    computed: {
      ifPC() {
        return this.$store.state.ifPC;
      }
    },
    methods: {
      // 获取当前登录账号信息，存入内存
      async getUserInfo () {
        try {
          const res = await getUserInfo({
            email: this.loginForm.email
          })
          // console.log('res：：：+ ', res)
          const token = 'your_token_value'
          localStorage.setItem('personlog_token', token)
          this.$store.commit('SET_USER_INFO', res)
        } catch (error) {
          console.log('error：：：+ ', error)
        }
      },
      validateEmail (email) {
        // 使用正则表达式验证邮箱格式
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
      },
      async sendCode() {
        if (!this.validateEmail(this.loginForm.email)) {
          return this.$message.error("邮箱格式不正确！")
        }
        this.loginForm.code = ''
        if (this.timer) {
          clearInterval(this.timer); // 清理之前的计时器
        }
        this.count = 60;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            clearInterval(this.timer); // 倒计时结束后清理计时器
            this.timer = null;
          }
        }, 1000);
        const loading = this.$loading({
          lock: true,
          text: '数据处理中',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        try {
          const res = await getEmailCode({ email: this.loginForm.email })
          console.log('res：：：+ ', res)
          this.$message.success(`验证码已发送至邮箱${this.loginForm.email}`)
        } catch (error) {
          console.log('error：：：+ ', error)
        } finally {
          loading.close()
        }
      },
      emailChange(val) {
        this.loginForm.email = val
      },
      async submitForm() {
        if (!this.validateEmail(this.loginForm.email)) {
          return this.$message.error("邮箱格式不正确！")
        }
        const loading = this.$loading({
          lock: true,
          text: '数据处理中',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        try {
          const res = await verifyEmailCode(this.loginForm)
          if (res == '邮箱和验证码匹配成功') {
            const res1 = await registerUser(this.loginForm)
            console.log('res1：：：+ ', res1)
            if(res1 == '注册成功') {
              const res2 = await loginByEmail(this.loginForm)
              console.log('res2：：：+ ', res2)
              if (res2 == '登录成功') {
                this.$message.success('登录成功')
                this.getUserInfo()
                this.$store.commit('SET_SHOW_LOGIN', false)
                // setTimeout(() => {
                //   this.$router.push('/')
                // }, 1000);
              }
            }
          }
        } catch (error) {
          console.log('error：：：+ ', error)
          if (error.response.data == '邮箱和验证码不匹配') {
            this.$message.error('验证码错误')
          }
          if (error.response.data == '邮箱已被注册') {
            const res2 = await loginByEmail(this.loginForm)
            console.log('res2：：：+ ', res2)
            if (res2 == '登录成功') {
              this.$message.success('登录成功')
              this.getUserInfo()
              this.$store.commit('SET_SHOW_LOGIN', false)
              // setTimeout(() => {
              //   this.$router.push('/')
              // }, 1000);
            }
          }
        } finally {
          loading.close()
        }
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
        this.$refs.emailInput.resetValue()
      }
    }
  }
</script>

<style lang="scss" scoped>
.login_view {
  // width: 350px;
  height: auto;
  background: linear-gradient(135deg, #f9f9f9, #e9e9e9);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 50px auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  .login_title {
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    background-color: transparent;
  }

  ::v-deep {
    .el-form-item__label {
      padding: 0;
      line-height: 20px;
      font-size: 14px;
      color: #666;
      margin-bottom: 5px;
    }

    .el-input--mini .el-input__inner {
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      border: 1px solid #ccc;
      padding: 0 15px;
      font-size: 14px;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
      }
    }
  }

  .code_content {
    display: grid;
    grid-template-columns: 3fr 2fr;
    .code_btn {
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &_common {
        background-color: #007bff;
        color: #fff;
        margin-top: 3px;
        &:hover {
          background-color: #0056b3;
        }
      }

      &_success {
        background-color: #2e683c;
        color: #fff;
        cursor: default;
        margin-top: 3px;
      }
    }
  }

  .btn_content {
    display: grid;
    grid-template-columns: 3fr 2fr;
    margin-top: 30px;
    padding: 0 30px;

    .submit {
      height: 40px;
      background-color: #007bff;
      border: none;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }

    .reset {
      height: 40px;
      background-color: #f8f9fa;
      border: 1px solid #ccc;
      border-radius: 8px;
      font-size: 14px;
      color: #333;
      transition: background-color 0.3s ease;
      margin: 0px;

      &:hover {
        background-color: #e2e6ea;
      }
    }
  }
}
</style>
