import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入 Element UI 样式
// 引入使代码行高亮的插件
import Highlight from "./plugins/highlight-plugin.js"
// 引入vue-markdown
import VueMarkdown from "vue-markdown"
// 导入字体文件
import '@/assets/fonts/dsfont.css'
// 引入svg相关文件
import '@/assets/index.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
// 引入主题样式
import '@/assets/css/theme.css';
// 引入全局样式
import '@/assets/css/global.css';
// 引入 Tailwind CSS 样式
// import './assets/css/tailwind.css'
// 将vue-markdown注册为全局组件
Vue.component("vue-markdown",  VueMarkdown)
Vue.use(Highlight);
Vue.use(ElementUI); // 使用 Element UI 插件
Vue.config.productionTip = false

// 初始化主题
if (store.state.theme === 'dark') {
  document.documentElement.classList.add('dark-mode');
}

// 初始化自动主题定时器（默认启用）
store.dispatch('initAutoThemeTimer');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
