<template>
  <div ref="aboutView" class="about-view">
    <!-- 个人信息区 -->
    <div class="header-info">
      <div class="header-bg">
        <img :src="headerBgUrl" alt="header background" />
      </div>
      <div class="header-content">
        <div class="header-details">
          <div v-if="ifPC" class="avatar">
            <img :src="avatarUrl" alt="avatar" />
          </div>
          <div class="user-info">
            <h1>Sweek</h1>
            <p>
              资深全栈工程师，专注于Web3.0与人工智能技术的探索与实践。热衷于分享技术见解和学习心得，期待与志同道合的朋友交流。
            </p>
            <div class="social-links">
              <a v-for="social in socials" :key="social.name" :href="social.link">
                <i :class="social.icon"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 主要内容区 -->
    <div class="main-content">
      <div class="content-wrapper">
        <!-- 文章列表 -->
        <div class="article-list">
          <div class="article-grid">
            <article v-for="post in posts" :key="post.id">
              <div class="article-cover">
                <img :src="post.cover" :alt="post.title" />
              </div>
              <div class="article-details">
                <div class="article-tags">
                  <span v-for="tag in post.tags" :key="tag">
                    {{ tag }}
                  </span>
                </div>
                <h2>{{ post.title }}</h2>
                <p>{{ post.excerpt }}</p>
                <div class="article-meta">
                  <span><i class="fas fa-calendar-alt"></i>{{ post.date }}</span>
                  <span><i class="fas fa-clock"></i>{{ post.readTime }}</span>
                </div>
              </div>
            </article>
          </div>
        </div>

        <!-- 侧边栏 -->
        <div class="sidebar">
          <!-- 分类统计 -->
          <div class="category-stats">
            <h3>文章分类</h3>
            <div class="category-list">
              <div v-for="category in categories" :key="category.name">
                <span>{{ category.name }}</span>
                <span>{{ category.count }}</span>
              </div>
            </div>
          </div>

          <!-- 热门文章 -->
          <div class="hot-articles">
            <h3>热门文章</h3>
            <div class="hot-article-list">
              <a v-for="article in hotArticles" :key="article.id" href="#">
                <div class="article-thumb">
                  <img :src="article.cover" :alt="article.title" />
                </div>
                <div class="article-info">
                  <h4>{{ article.title }}</h4>
                  <span>{{ article.views }} 阅读</span>
                </div>
              </a>
            </div>
          </div>

          <!-- 标签云 -->
          <div class="tag-cloud">
            <h3>标签云</h3>
            <div class="tag-list">
              <a v-for="tag in tags" :key="tag.name" href="#">
                {{ tag.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 页脚 -->
    <footer class="footer">
      <div class="footer-content">
        <div class="copyright">
          © 2025 Sweek的个人博客. All rights reserved.
        </div>
      </div>
    </footer>
    <!-- Back to Top -->
    <button v-if="showBackToTopButton" class="button" @click="scrollToTop">
      <i class="bi bi-arrow-bar-up"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showBackToTopButton: false,
      headerBgUrl: 'https://ai-public.mastergo.com/ai/img_res/0b5a4fcfbf6fe9a3e8ba76e518477e27.jpg',
      avatarUrl: 'https://www.sweek.top/api/preview/avatar.jpg',
      socials: [
        { name: 'GitHub', icon: 'bi bi-github', link: '#' },
        { name: 'QQ', icon: 'bi bi-tencent-qq', link: '#' },
        // { name: 'LinkedIn', icon: 'fab fa-linkedin', link: '#' },
        // { name: 'Weibo', icon: 'fab fa-weibo', link: '#' }
      ],
      posts: [
        {
          id: 1,
          title: '深入理解 Vue3 Composition API 的响应式原理',
          excerpt: '本文将详细探讨 Vue3 中 Composition API 的响应式系统实现原理，以及如何更好地运用它来优化我们的应用性能。',
          cover: 'https://ai-public.mastergo.com/ai/img_res/7257b2c53058047d550318b74cb88298.jpg',
          date: '2024-01-15',
          readTime: '15 分钟阅读',
          tags: ['Vue.js', '前端开发', '技术原理']
        },
        {
          id: 2,
          title: 'Web3.0 区块链应用开发实战指南',
          excerpt: '从零开始学习区块链应用开发，掌握智能合约编写、DApp开发等核心技能，成为区块链全栈开发者。',
          cover: 'https://ai-public.mastergo.com/ai/img_res/3478e2ad4964c7d407f97078db08691c.jpg',
          date: '2024-01-12',
          readTime: '20 分钟阅读',
          tags: ['区块链', 'Web3.0', '智能合约']
        },
        {
          id: 3,
          title: '人工智能在前端开发中的实践应用',
          excerpt: '探索如何将人工智能技术融入前端开发流程，提高开发效率和用户体验，打造智能化的Web应用。',
          cover: 'https://ai-public.mastergo.com/ai/img_res/101fbb46c7e40d1193756e7fe4d52e6d.jpg',
          date: '2024-01-08',
          readTime: '18 分钟阅读',
          tags: ['人工智能', '前端开发', '技术创新']
        }
      ],
      categories: [
        { name: '前端开发', count: 28 },
        { name: '区块链', count: 15 },
        { name: '人工智能', count: 12 },
        { name: '技术架构', count: 9 },
        { name: '开发工具', count: 6 }
      ],
      hotArticles: [
        {
          id: 1,
          title: 'TypeScript 高级特性详解',
          cover: 'https://ai-public.mastergo.com/ai/img_res/99f2d1c98250bbef0316c65d5f6d0645.jpg',
          views: '2,345'
        },
        {
          id: 2,
          title: '微前端架构实践指南',
          cover: 'https://ai-public.mastergo.com/ai/img_res/52fdf161c6d8cc2451b8d9fbee0a072d.jpg',
          views: '1,892'
        },
        {
          id: 3,
          title: 'Docker 容器化部署实战',
          cover: 'https://ai-public.mastergo.com/ai/img_res/6376cf6e20c1f22be151baa54409365b.jpg',
          views: '1,567'
        }
      ],
      tags: [
        { name: 'JavaScript' },
        { name: 'Vue.js' },
        { name: 'React' },
        { name: 'Node.js' },
        { name: 'TypeScript' },
        { name: '微服务' },
        { name: 'Docker' },
        { name: '区块链' },
        { name: '人工智能' },
        { name: '云计算' }
      ]
    };
  },
  computed: {
    ifPC() {
      return this.$store.state.ifPC
    },
    userInfo() {
      return this.$store.state.userInfo || null
    }
  },
  mounted() {
    this.$refs.aboutView.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.aboutView.removeEventListener('scroll', this.handleScroll);
  },
  activated () {
    this.$refs.aboutView.scrollTop = this.$route.meta.scrollTop
  },
  beforeRouteLeave (to, from, next) { // 离开组件的时候触发
    from.meta.scrollTop = this.$refs.aboutView.scrollTop
    next()
  },
  methods: {
    scrollToTop() {
      this.$refs.aboutView.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      this.showBackToTopButton = this.$refs.aboutView.scrollTop > 100; // 当滚动距离超过 100px 时显示按钮
    }
  }
};
</script>

<style lang="scss" scoped>
.about-view {
  background-color: var(--bg-primary);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.header-info {
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .header-content {
    position: relative;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 2rem;

    .header-details {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 3rem;

      .avatar {
        height: 12rem;
        width: 12rem;
        overflow: hidden;
        border-radius: 50%;
        border: 4px solid var(--card-bg);
        box-shadow: 0 0 10px var(--shadow-color);

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .user-info {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h1 {
          font-size: 2.5rem;
          font-weight: bold;
          color: #fff;
        }

        p {
          max-width: 40rem;
          font-size: 1.125rem;
          color: rgba(255, 255, 255, 0.9);
        }

        .social-links {
          margin-top: 1rem;
          display: flex;
          gap: 1.5rem;

          a {
            color: #fff;
            transition: color 0.3s ease;

            &:hover {
              color: #e2e8f0;
            }

            i {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

// 添加媒体查询，确保在手机端头像依然是圆形
@media (max-width: 768px) {
  .header-info .header-content .header-details .avatar {
    height: 8rem!important; // 可以根据需要调整手机端头像的大小
    width: 8rem!important;
  }
}

.main-content {
  margin-top: 3rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;

  .content-wrapper {
    display: flex;
    gap: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .article-list {
      flex: 1;

      .article-grid {
        display: grid;
        gap: 2rem;

        article {
          position: relative;
          overflow: hidden;
          border-radius: 0.75rem;
          background-color: var(--card-bg);
          box-shadow: 0 4px 6px -1px var(--shadow-color), 0 2px 4px -1px var(--shadow-color);
          transition: all 0.3s ease;

          &:hover {
            transform: translateY(-0.25rem);
            box-shadow: 0 10px 15px -3px var(--shadow-color), 0 4px 6px -2px var(--shadow-color);
          }

          .article-cover {
            aspect-ratio: 16 / 9;
            overflow: hidden;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              transition: transform 0.3s ease;

              &:hover {
                transform: scale(1.05);
              }
            }
          }

          .article-details {
            padding: 1.5rem;

            .article-tags {
              margin-bottom: 0.75rem;
              display: flex;
              gap: 0.5rem;

              span {
                border-radius: 9999px;
                background-color: var(--hover-bg);
                padding: 0.25rem 0.75rem;
                font-size: 0.875rem;
                color: var(--text-primary);
              }
            }

            h2 {
              margin-bottom: 0.75rem;
              font-size: 1.25rem;
              font-weight: bold;
              color: var(--text-primary);
            }

            p {
              margin-bottom: 1rem;
              color: var(--text-secondary);
            }

            .article-meta {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 0.875rem;
              color: var(--text-secondary);

              i {
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }

    .sidebar {
      width: 20rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @media (max-width: 768px) {
        width: 100%;
      }

      > div {
        border-radius: 0.75rem;
        background-color: var(--card-bg);
        padding: 1.5rem;
        box-shadow: 0 4px 6px -1px var(--shadow-color), 0 2px 4px -1px var(--shadow-color);

        h3 {
          margin-bottom: 1rem;
          font-size: 1.125rem;
          font-weight: bold;
          color: var(--text-primary);
        }
      }

      .category-stats {
        .category-list {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;

          div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              color: var(--text-secondary);
            }

            span:last-child {
              border-radius: 9999px;
              background-color: var(--hover-bg);
              padding: 0.25rem 0.5rem;
              font-size: 0.875rem;
            }
          }
        }
      }

      .hot-articles {
        .hot-article-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          a {
            display: flex;
            gap: 0.75rem;

            .article-thumb {
              height: 4rem;
              width: 4rem;
              overflow: hidden;
              border-radius: 0.5rem;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: transform 0.3s ease;

                &:hover {
                  transform: scale(1.1);
                }
              }
            }

            .article-info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              h4 {
                font-weight: 500;
                transition: color 0.3s ease;
                color: var(--text-primary);

                &:hover {
                  color: var(--active-text);
                }
              }

              span {
                font-size: 0.875rem;
                color: var(--text-secondary);
              }
            }
          }
        }
      }

      .tag-cloud {
        .tag-list {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;

          a {
            border-radius: 9999px;
            background-color: var(--hover-bg);
            padding: 0.25rem 0.75rem;
            font-size: 0.875rem;
            color: var(--text-secondary);
            transition: all 0.3s ease;

            &:hover {
              background-color: var(--active-gradient);
              color: var(--active-text);
            }
          }
        }
      }
    }
  }
}

.footer {
  margin-top: 5rem;
  border-top: 1px solid var(--border-color);
  background-color: var(--bg-primary);

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copyright {
      color: var(--text-secondary);
      font-size: 12px;
    }
  }
}

// Back to Top
.button {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 9999px;
  background-color: var(--active-gradient);
  color: #666;
  border: none;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px var(--shadow-color), 0 4px 6px -2px var(--shadow-color);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--active-gradient);
    transform: scale(1.1);
  }

  i {
    font-size: 1.25rem;
  }
}
</style>