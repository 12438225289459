// 引入 request 文件
import request from '@/utils/request'
 
// 获取app信息
export const getFirstAppInfo = (params) => {
  return request({
    url: `/getFirstAppInfo`,
    method: 'get',
    params
  });
};
 
// 获取题库题目归属统计数据
export const getBelongAndRecordCount = (params) => {
  return request({
    url: `/getBelongAndRecordCount`,
    method: 'get',
    params
  });
};
 
// 获取题库题目归属
export const getBelong = (params) => {
  return request({
    url: `/getBelong`,
    method: 'get',
    params
  });
};

// 获取题库题目
export const getQuestion = (params) => {
  return request({
    url: `/getQuestion`,
    method: 'get',
    params
  });
};

// 收藏题库
export const insertBelong = (data) => {
  return request({
    url: `/insertBelong`,
    method: 'post',
    data
  });
};

// 获取收藏题库根据email
export const getBelongByEmail = (params) => {
  return request({
    url: `/getBelongByEmail`,
    method: 'get',
    params
  });
};

// 取消收藏题库根据email
export const deleteBelong = (params) => {
  return request({
    url: `/deleteBelong`,
    method: 'get',
    params
  });
};

// 获取收藏题库
export const getQuestionTypeByBelong = (data) => {
  return request({
    url: `/getQuestionTypeByBelong`,
    method: 'post',
    data
  });
};

// 收藏题目
export const insertStarQuestion = (data) => {
  return request({
    url: `/insertStarQuestion`,
    method: 'post',
    data
  });
};

// 获取收藏题目根据email
export const getStarQuestionByEmail = (params) => {
  return request({
    url: `/getStarQuestionByEmail`,
    method: 'get',
    params
  });
};

// 取消收藏题目根据email
export const deleteStarQuestion = (params) => {
  return request({
    url: `/deleteStarQuestion`,
    method: 'get',
    params
  });
};

// 获取收藏题目
export const getStarQuestionByQuestionId = (data) => {
  return request({
    url: `/getStarQuestionByQuestionId`,
    method: 'post',
    data
  });
};