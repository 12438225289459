import request from '@/utils/request'

// 模型文字回复
export function postModel (data) {
  return request({
    url: '/model',
    method: 'post',
    data
  })
}

// 模型文字回复文字保存
export function saveModelText (data) {
  return request({
    url: '/saveModelText',
    method: 'post',
    data
  })
}

// 根据邮箱修改当前登录人信息
export function getModelList (query) {
  return request({
    url: '/getModelList',
    method: 'get',
    query
  })
}
