<template>
  <div ref="courseDetailView" class="min-h-screen bg-gray-50">
    <!-- 主内容区 -->
    <div class="main-content">
      <!-- 课程头部信息 -->
      <div class="course-header">
        <div class="header-content">
          <div class="course-title">
            <span>{{ course.course_name }}</span>
          </div>
          <div class="progress-info">
            <div class="progress-bar">
              <div class="progress" :style="{ width: course.percentage + '%', backgroundColor: getProgressColor(course.percentage) }"></div>
            </div>
            <span class="progress-percentage">{{ course.percentage }}%</span>
          </div>
        </div>
      </div>

      <!-- 进度信息 -->
      <div class="progress-details">
        <div class="details-content">
          <div class="detail-item">
            <span>更新时间</span>
            <div class="update-info">
              <span>{{ updatedTime }}</span>
              <button @click="updateCourses" style="cursor: pointer;">更新</button>
            </div>
          </div>
          <div class="detail-item">
            <div v-html="course.process" class="process-content"></div>
          </div>
        </div>
      </div>

      <!-- 课程大纲 -->
      <div class="course-outline">
        <div class="outline-content">
          <!-- 模块一 -->
          <div v-for="(item, index) in chapter" :key="index" :ref="el => item.active && (activeChapterRefs[index] = el)" class="module">
            <div class="project">
              <div class="project-item" :class="{'active-item': item.active}" :style="{marginLeft: item.ifTitle ? '0px' : '1rem'}">
                <span :style="{fontSize: item.ifTitle ? '16px' : '14px'}" style="flex: 1;">{{ item.title }}</span>
                <span v-if="!item.ifTitle">
                  <span v-if="item.status" class="completed">已完成</span>
                  <span v-else class="task-number">{{ item.num }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 固定按钮 -->
    <div class="fixed-buttons">
      <div @click="refreshPage" class="fixed-button">
        <i class="bi bi-arrow-clockwise"></i>
      </div>
      <div @click="positionPage" class="fixed-button">
        <i class="bi bi-crosshair"></i>
      </div>
      <div @click="scrollToTop" class="fixed-button">
        <i class="bi bi-arrow-bar-up"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getCoursesByEmail } from '@/api/course'
export default {
  name: 'App',
  data() {
    return {
      buttonClickable: true,
      updatedTime: '****-**-** **:**:**',
      course: {},
      chapter: [],
      activeChapterRefs: []
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo || null;
    },
    nickName() {
      const nickName = this.userInfo?.nickname || '';
      return nickName.length > 6 ? nickName.substring(0, 6) + '...' : nickName;
    },
    ifPC() {
      return this.$store.state.ifPC;
    }
  },
  created() {
    this.getCourses()
  },
  methods: {
    positionPage() {
      const activeChapter = this.activeChapterRefs.find(ref => ref);
      if (activeChapter) {
        this.$refs.courseDetailView.scrollTo({
          top: activeChapter.offsetTop,
          behavior: 'smooth'
        });
      }
    },
    scrollToTop() {
      this.$refs.courseDetailView.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    refreshPage() {
      this.query = this.$options.data().query
      this.getCourses()
    },
    getProgressColor(progress) {
      if (progress < 30) return 'red';
      if (progress < 70) return 'orange';
      return 'green';
    },
    // 转换时间格式将秒转换为年、天、小时、分钟、秒
    formatTime (seconds) {
      const years = Math.floor(seconds / (365 * 24 * 3600)) // 计算年数
      seconds = seconds % (365 * 24 * 3600) // 剩余的秒数
      const days = Math.floor(seconds / (24 * 3600)) // 计算天数
      seconds = seconds % (24 * 3600) // 剩余的秒数
      const hours = Math.floor(seconds / 3600) // 计算小时数
      seconds = seconds % 3600 // 剩余的秒数
      const minutes = Math.floor(seconds / 60) // 计算分钟数
      seconds = seconds % 60 // 剩余的秒数

      let result = ''

      if (years > 0) {
        result += `${years}年 `
      }
      if (days > 0) {
        result += `${days}天 `
      }
      if (hours > 0) {
        result += `${hours}小时 `
      }
      if (minutes > 0) {
        result += `${minutes}分钟 `
      }
      if (seconds > 0 || result === '') { // 如果秒数大于 0 或其他部分为空，显示秒数
        result += `${seconds}秒`
      }

      return result.trim() // 去掉末尾多余的空格
    },
    // 更新课程数据
    updateCourses () {
      // 如果按钮不可点击，则直接返回
      if (!this.buttonClickable) {
        return this.$message('课程数据更新中，更新间隔为10s,请稍后重试！')
      }
      // 处理点击事件的逻辑
      this.getCourses()
      // 设置按钮不可点击状态
      this.buttonClickable = false
      // 10秒后恢复按钮可点击状态
      setTimeout(() => {
        this.buttonClickable = true
      }, 10000) // 10秒
    },
    // 计算两个时间的间隔
    calculateTimeDifference (startTime, endTime) {
      const start = new Date(startTime.replace(/-/g, '/')) // 将横杠替换为斜杠以兼容 Safari 浏览器
      const end = new Date(endTime.replace(/-/g, '/')) // 同样处理结束时间
      const difference = (end.getTime() - start.getTime()) / 1000 // 计算时间间隔（秒）
      return difference // 返回时间间隔（秒）
    },
    // 从字符串中取出时间日期
    extractDateTime (str) {
      const regex = /\[(.*?)\]/ // 匹配 [ ] 中的内容
      const match = regex.exec(str) // 执行正则匹配
      if (match && match.length > 1) {
        return match[1] // 返回匹配到的第一个结果
      } else {
        return null // 如果没有匹配到，则返回null
      }
    },
    // 给数组去重
    uniqueArrayByID (array) {
    // 创建一个空对象来存储已经出现过的ID
      const seen = {}

      // 使用filter方法遍历数组，只保留第一次出现的每个ID对应的对象
      return array.filter(item => {
      // 如果对象的ID已经在seen对象中存在，则过滤掉
        if (item.id in seen) {
          return false
        } else {
        // 否则将对象的ID添加到seen对象中，并返回true，保留该对象
          seen[item.id] = true
          return true
        }
      })
    },
    // 获取当前时间
    getCurrentDateTime () {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const seconds = String(currentDate.getSeconds()).padStart(2, '0')

      const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      return currentDateTime
    },
    // 检验字符串
    isAllDigits (inputString) {
      return /^\d+$/.test(inputString)
    },
    formatDate (dateString) {
      const date = new Date(dateString) // 将ISO字符串转换为 Date 对象

      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份是从0开始的，需要加1
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      // 返回格式化后的日期字符串
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    // 获取课程信息
    getCourses () {
      const params = {
        email: this.userInfo.role == 'admin' ? null : this.userInfo.email,
        course_id: this.$route.query.course_id
      }
      const isDarkMode = document.documentElement.classList.contains('dark-mode')
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: isDarkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(255, 255, 255, 0.7)'
      })
      getCoursesByEmail(params).then(res => {
        this.$message('获取课程信息成功')
        // console.log(res)
        this.updatedTime = this.getCurrentDateTime()
        this.course = res.data.courses[0] || {}
        const chapter = JSON.parse(this.course.chapter).filter(item => item.ifTitle !== 1)
        const statusOneCount = chapter.filter(item => item.status === 1).length
        const percentage = (statusOneCount / chapter.length * 100).toFixed(2)
        this.course.percentage = percentage
        // 根据时间判断课程是否发生异常 - 间隔时间超过10分钟
        const chapterTime = this.extractDateTime(this.course.process)
        // console.log('chapterTime：：：+ ', chapterTime)
        const interval = this.calculateTimeDifference(chapterTime, this.updatedTime)
        // console.log('interval：：：+ ', interval)
        if (interval >= 600) {
          this.$message({
            message: '课程数据已超过10分钟未进行更新，课程脚本可能发生异常，请前往解决！' + '当前已超时' + this.formatTime(interval),
            type: 'warning'
          })
        }
        // 课程进度反显
        // const process = document.querySelector('#process')
        // process.innerHTML = this.course.process
        // 章节反显
        this.chapter = this.uniqueArrayByID(JSON.parse(this.course.chapter))
        // 章节数据处理
        this.chapter.forEach(item => {
          const arr = item.title.split('\n')
          // console.log('arr：：：+ ', arr)
          const ifAllNum = this.isAllDigits(arr[arr.length - 1])
          if (ifAllNum && item.status === 0 && item.ifTitle === 0) {
            item.num = arr[arr.length - 1]
            delete arr[arr.length - 1]
            item.title = arr.join('')
          }
        })
        console.log('this.chapter：：：+ ', this.chapter)
      }).finally(() => {
        loading.close()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.min-h-screen {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .dark-mode & {
    background-color: var(--bg-secondary);
  }
}

.bg-gray-50 {
  background-color: #f9fafb;

  .dark-mode & {
    background-color: var(--bg-secondary);
  }
}

.mobile-nav {
  display: none;
  @media (max-width: 1023px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    z-index: 50;

    .dark-mode & {
      background-color: var(--bg-primary);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3.5rem;
  }
  .nav-title {
    font-size: 1rem;
    font-weight: 500;

    .dark-mode & {
      color: var(--text-primary);
    }
  }
}

.main-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  padding-top: 0.5rem;
  @media (min-width: 1024px) {
    padding-top: 2rem;
  }
  padding-bottom: 2rem;
}

.course-header {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1rem;
  margin-bottom: 0.8rem;

  .dark-mode & {
    background-color: var(--card-bg);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  .header-content {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
    }
    justify-content: space-between;
  }
  .course-title {
    margin-bottom: 1rem;
    font-weight: bolder;
    font-size: 18px;
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }

    .dark-mode & {
      color: var(--text-primary);
    }

    h1 {
      font-size: 1.25rem;
      @media (min-width: 1024px) {
        font-size: 1.5rem;
      }
      font-weight: 500;
      color: #111827;

      .dark-mode & {
        color: var(--text-primary);
      }
    }
  }
  .progress-info {
    display: flex;
    align-items: center;
    .progress-bar {
      width: 12rem;
      background-color: #e5e7eb;
      border-radius: 9999px;
      height: 0.5rem;

      .dark-mode & {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .progress {
        background-color: #22c55e;
        height: 0.5rem;
        border-radius: 9999px;
      }
    }
    .progress-percentage {
      margin-left: 0.75rem;
      font-size: 0.875rem;
      color: #6b7280;

      .dark-mode & {
        color: var(--text-secondary);
      }
    }
  }
}

.progress-details {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1rem;
  margin-bottom: 0.8rem;

  .dark-mode & {
    background-color: var(--card-bg);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  .details-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .detail-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .process-content {
      width: 100%;
      line-height: 24px;
      font-size: 13px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px;
      border-radius: 4px;

      .dark-mode & {
        border: 1px solid var(--border-color);
        background-color: var(--bg-primary);
      }
    }
    span {
      color: #6b7280;

      .dark-mode & {
        color: var(--text-secondary);
      }
    }
  }
  .update-info {
    display: flex;
    align-items: center;
    span {
      color: #111827;

      .dark-mode & {
        color: var(--text-primary);
      }
    }
    button {
      margin-left: 0.75rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 0.875rem;
      color: #ffffff;
      border: 1px solid #16a34a;
      border-radius: 0.25rem;
      background-color: #589170;
      &:hover {
        background-color: #f0fdf4;
        color: #000000;
      }

      .dark-mode & {
        background-color: #2c6e49;
        border-color: #2c6e49;

        &:hover {
          background-color: #265c3d;
          color: #ffffff;
        }
      }
    }
  }
}

.course-outline {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1rem;

  .dark-mode & {
    background-color: var(--card-bg);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  .outline-content {
    display: flex;
    flex-direction: column;
    // gap: 0.6rem;
  }
  .module {
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    h2 {
      font-size: 1.125rem;
      font-weight: 500;

      .dark-mode & {
        color: var(--text-primary);
      }
    }
    .project {
      display: flex;
      flex-direction: column;
      .project-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 3px;
        background-color: #fff;

        &.active-item {
          background-color: #ddd;
        }

        .dark-mode & {
          background-color: var(--card-bg) !important;

          &.active-item {
            background-color: var(--hover-bg) !important;
          }
        }

        span {
          color: #555;

          &:first-child {
            font-weight: normal;
          }

          .dark-mode & {
            color: var(--text-primary) !important;
          }
        }

        // 添加标题样式
        &[style*="margin-left: 0px"] {
          span:first-child {
            color: #000 !important;
            font-weight: bold;

            .dark-mode & {
              color: var(--text-primary) !important;
            }
          }
        }

        .completed {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 0.75rem;
          background-color: #dcfce7;
          color: #166534;
          border-radius: 0.25rem;

          .dark-mode & {
            background-color: rgba(22, 101, 52, 0.3);
            color: #4ade80;
          }
        }
        .task-number {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
          font-size: 0.75rem;
          color: white;
          background-color: #3b82f6;
          border-radius: 9999px;
        }
      }
      .task-group {
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .task-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .completed {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            font-size: 0.75rem;
            background-color: #dcfce7;
            color: #166534;
            border-radius: 0.25rem;

            .dark-mode & {
              background-color: rgba(22, 101, 52, 0.3);
              color: #4ade80;
            }
          }
          .task-number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: 1.5rem;
            font-size: 0.75rem;
            color: white;
            background-color: #3b82f6;
            border-radius: 9999px;
          }
        }
      }
    }
  }
}

.fixed-buttons {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.fixed-button {
  width: 3rem;
  height: 3rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffffcc;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b5563;
  transition: all 0.3s ease;
  overflow: hidden;

  .dark-mode & {
    background-color: rgba(36, 36, 36, 0.8);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3);
    color: var(--text-secondary);
  }

  &:hover {
    background-color: #f3f4f6;
    color: #3b82f6;

    .dark-mode & {
      background-color: var(--hover-bg);
      color: #60a5fa;
    }
  }
}
</style>