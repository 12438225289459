<template>
  <div class="user-management">
    <!-- <h2>课程信息</h2> -->
    <div class="search-add">
      <div class="search">
        <input type="text" v-model="queryParams.keyword" placeholder="请输入关键词...">
        <i class="fas fa-search"></i>
      </div>
      <div>
        <button @click="handleSearch">
          <i class="fas fa-search"></i>查询
        </button>
        <!-- <button @click="handleReset" style="margin-left: 10px;background-color: #fff;color: black;border: 1px solid gray;">
          <i class="fas fa-refresh"></i>重置
        </button> -->
        <!-- <button>
          <i class="fas fa-plus"></i>添加用户
        </button> -->
      </div>
    </div>
    <div class="table-container">
      <!-- 使用 ElementUI 的表格组件 -->
      <el-table
        :data="tableData"
        max-height="650"
      >
        <el-table-column prop="name" label="课程名称" min-width="250" show-overflow-tooltip>
          <template #default="{ row }">
            <div class="user-info">
              <img :src="row.course_img ? row.course_img : 'https://www.sweek.top/api/preview/personlog-avatar.jpg'" class="avatar" alt="用户头像">
              <span>{{ row.course_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="created_at" label="新增时间" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updated_at" label="同步时间" min-width="180" show-overflow-tooltip></el-table-column>
        <!-- 新增进度条列 -->
        <el-table-column label="课程进度" min-width="150" show-overflow-tooltip>
          <template #default="{ row }">
            <el-progress :percentage="parseFloat(row.percentage)" :stroke-width="15"></el-progress>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template #default="{ row }">
            <button @click="handleDetail(row)">
              <i class="fa-solid fa-grip"></i>
            </button>
            <!-- <button>
              <i class="fas fa-trash"></i>
            </button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[5, 10, 20, 30]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.js'
import { getCoursesByEmail } from '@/api/course.js'

export default {
  data() {
    return {
      total: 0,
      queryParams: {
        pageSize: 5,
        pageNum: 1,
        keyword: ''
      },
      tableData: []
    };
  },
  computed: {
    ifPC() {
      return this.$store.state.ifPC;
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleReset() {
      this.queryParams = this.$options.data().queryParams
      this.getList()
    },
    handleSearch() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    getList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: this.$store.state.isDark ? 'rgba(30, 30, 30, 0.7)' : 'rgba(255, 255, 255, 0.7)'
      })
      getCoursesByEmail(this.queryParams).then(res => {
        res.data.courses.forEach(item => {
          const chapter = JSON.parse(item.chapter).filter(item => item.ifTitle !== 1)
          const statusOneCount = chapter.filter(item => item.status === 1).length
          const percentage = (statusOneCount / chapter.length * 100).toFixed(2)
          item.percentage = percentage
          item.current_chaptername = item.current_chapter.split(',')[1]
          item.created_at = formatDate(item.created_at)
          item.updated_at = formatDate(item.updated_at)
        })
        this.tableData = res.data.courses;
        console.log('this.tableData：：：+ ', this.tableData)
        this.total = res.data.total;
      }).finally(() => {
        loading.close()
      })
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNum = newPage;
      this.getList()
    },
    handleDetail(item) {
      this.$router.push({
        path: '/course-detail',
        query: {
          course_id: item.course_id,
          current_chapter: item.current_chaptername,
          percentage: item.percentage
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
/* 整体容器样式 */
.user-management {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-secondary);
    box-shadow: 0 0 10px var(--shadow-color);
  }

  ::v-deep {
    .el-table th.el-table__cell {
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      background-color: #f2F2F2;
      color: #000000;

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-secondary);
        color: var(--text-primary);
      }
    }

    /* 适配黑夜模式 - 表格样式 */
    .dark-mode & {
      .el-table {
        --el-table-bg-color: var(--bg-primary) !important;
        --el-table-tr-bg-color: var(--bg-primary) !important;
        --el-table-border-color: var(--border-color) !important;
        --el-table-text-color: var(--text-primary) !important;
        --el-table-header-bg-color: var(--bg-secondary) !important;
        --el-table-header-text-color: var(--text-primary) !important;

        .el-table__body-wrapper, .el-table__header-wrapper {
          background-color: var(--bg-primary);
        }

        .el-table__body tr:hover > td.el-table__cell {
          background-color: var(--hover-bg) !important;
        }
      }
    }
  }
}

/* 标题样式 */
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* 搜索和添加按钮区域样式 */
.search-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* 允许换行 */

  .search {
    position: relative;
    width: 60%;
    min-width: 200px; /* 设置最小宽度 */
    margin-bottom: 10px;

    input {
      width: 100%;
      padding: 10px 30px 10px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-primary);
        border-color: var(--border-color);
        color: var(--text-primary);

        &:focus {
          border-color: var(--accent-color);
          box-shadow: 0 0 0 2px var(--accent-shadow);
        }
      }
    }

    i {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: #999;
    }
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }

    i {
      margin-right: 5px;
    }

    /* 适配黑夜模式 */
    .dark-mode & {
      background-color: var(--accent-color);

      &:hover {
        background-color: var(--accent-hover);
      }
    }
  }
}

/* 表格容器样式 */
.table-container {
  width: 100%;
  /* 允许水平滚动 */
  overflow-x: auto;
  /* 移除不必要的高度限制 */
  height: auto;

  /* 调整 ElementUI 表格样式，使其与原样式一致 */
  .el-table {
    /* 让表格宽度由内容决定 */
    width: 100%;
    border-collapse: collapse;
    /* 移除不必要的滚动设置 */
    overflow-y: auto;
    overflow-x: auto;

    .el-table__header th,
    .el-table__body td {
      padding: 12px;
      border-bottom: 1px solid #ddd;
      text-align: left;
    }

    .el-table__header th {
      background-color: #ffffff;
      font-weight: 600;
      color: #000000;
    }

    .el-table__body tr:hover {
      background-color: #f5f5f5;
    }

    button {
      padding: 6px 12px;
      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;

      /* 适配黑夜模式 */
      .dark-mode & {
        border-color: var(--border-color);
        color: var(--text-primary);
      }

      &:hover {
        background-color: #f2f2f2;

        /* 适配黑夜模式 */
        .dark-mode & {
          background-color: var(--hover-bg);
        }
      }

      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  /* 分页组件样式 */
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    /* 添加小屏幕下的样式调整 */
    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  /* 添加黑夜模式下的分页组件样式 */
  .dark-mode & {
    .el-pagination {
      button {
        background-color: var(--bg-secondary);
        color: var(--text-primary);

        &:disabled {
          color: var(--text-disabled);
        }
      }

      .el-pager li {
        background-color: var(--bg-secondary);
        color: var(--text-primary);

        &.active {
          color: var(--accent-color);
        }

        &:hover {
          color: var(--accent-hover);
        }
      }

      .el-pagination__total,
      .el-pagination__jump,
      .el-pagination__sizes {
        color: var(--text-primary);
      }

      .el-select .el-input .el-input__inner {
        color: var(--text-primary);
      }
    }
  }
}

/* 用户信息样式 */
.user-info {
  display: flex;
  align-items: center;

  img {
    width: 60px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
  }
}

/* 编辑弹窗样式 */
.el-dialog .el-form {
  display: flex;
  flex-wrap: wrap;
  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
    .el-form-item {
      width: 48%;
    }
  }
}
</style>
