<template>
  <div class="head-tab">
    <!-- PC 端菜单 -->
    <el-menu v-if="ifPC" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item :index="item.path" v-for="(item, index) in currRouters" :key="index">
        <svg-icon :color="activeIndex === item.path ? '#fff' : '#3e3e3e'" width="20px" height="20px"
          :icon-class="activeIndex === item.path ? item.act_icon : item.icon" />
        <span style="margin-left: 8px;">{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
    <!-- 移动端菜单 -->
    <div v-else class="tab-bar">
      <!-- 主题切换按钮放在菜单最前面 -->
      <div class="theme-toggle-wrapper">
        <theme-toggle></theme-toggle>
      </div>
      <!-- 菜单项容器 -->
      <div class="tab-bar-inner">
        <div class="tab-bar-item" v-for="(item, index) in currRouters" :key="index"
          :class="{ 'active': activeIndex === item.path }" @click="handleSelect(item.path)">
          <svg-icon :color="activeIndex === item.path ? '#fff' : '#3e3e3e'" width="18px" height="18px"
            :icon-class="activeIndex === item.path ? item.act_icon : item.icon" />
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="logo" v-if="ifPC">
      <img src="https://www.sweek.top/api/preview/logo.png" alt="">
      <span>SweekWebsite</span>
    </div>
    <!-- 用户头像和登录按钮 - PC端 -->
    <div class="avatar" v-if="ifPC">
      <el-dropdown v-if="nickName" @command="handleCommand">
        <span style="display: flex; align-items: center; justify-content: space-between;">
          <el-avatar shape="circle" fit="cover"
            :src="userInfo.avatar ? 'https://www.sweek.top/api/preview/' + userInfo?.avatar : 'https://www.sweek.top/api/preview/personlog-avatar.jpg'"></el-avatar>
          <div style="margin-left: 12px;">
            <span>{{ nickName }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="userInfo">个人中心</el-dropdown-item>
          <el-dropdown-item command="editPassword">修改密码</el-dropdown-item>
          <el-dropdown-item command="exitLogin">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-else @click="toLogin" class="login-btn">
        登录/注册
      </div>
      <!-- 主题切换按钮 -->
      <theme-toggle class="theme-toggle-btn"></theme-toggle>
    </div>
  </div>
</template>

<script>
import ThemeToggle from '@/components/ThemeToggle/index.vue';

export default {
  name: 'HeadTab',
  components: {
    ThemeToggle
  },
  data() {
    return {
      routers: [
        { title: '首页', name: 'HomeView', path: '/', role: '*', icon: 'home-two', act_icon: 'home-two-filled' },
        { title: '课程信息', name: 'CourseView', path: '/course', role: '*', icon: 'book-one', act_icon: 'book-one-filled' },
        { title: '使用教程', name: 'UsingTutorialsView', path: '/usingtutorials', role: '*', icon: 'file-editing-one-filled', act_icon: 'file-editing-filled' },
        { title: 'AIChat', name: 'AiChatView', path: '/aichat', role: '*', icon: 'brain', act_icon: 'brain-filled' },
        // { title: '留言信箱', name: 'VoicemailView', path: '/voicemail', role: '*', icon: 'mail', act_icon: 'mail-filled' },
        // { title: '工具', name: 'ToolsView', path: '/tools', role: '*', icon: 'toolkit', act_icon: 'toolkit-filled' },
        // { title: '项目', name: 'ProjectView', path: '/project', role: '*', icon: 'projector-two', act_icon: 'projector-two-filled' },
        { title: '个人中心', name: 'UserCenterView', path: '/usercenter', role: '*', icon: 'personal-privacy', act_icon: 'personal-privacy-filled' },
        { title: '关于作者', name: 'AboutView', path: '/about', role: '*', icon: 'attention', act_icon: 'attention-filled' },
        { title: '系统管理', name: 'ManageView', path: '/manage', role: 'admin', icon: 'application-one-filled', act_icon: 'coordinate-system-filled' }
      ],
      activeIndex: this.$route.path // 初始化 activeIndex 为当前路由路径
    };
  },
  watch: {
    // 监听路由对象的变化
    '$route': {
      immediate: true, // 组件创建时立即执行一次回调
      handler(to) {
        this.activeIndex = to.path;
      }
    }
  },
  computed: {
    currRouters() {
      const routers = this.userInfo.role === 'admin' ? this.routers : this.routers.filter(item => item.role !== 'admin');
      return routers;
    },
    userInfo() {
      return this.$store.state.userInfo || null;
    },
    nickName() {
      const nickName = this.userInfo?.nickname || '';
      return nickName.length > 6 ? nickName.substring(0, 6) + '...' : nickName;
    },
    ifPC() {
      return this.$store.state.ifPC;
    }
  },
  created() { },
  methods: {
    toLogin() {
      this.$store.commit('SET_SHOW_LOGIN', true);
    },
    handleCommand(command) {
      switch (command) {
        case 'userInfo':
          this.$router.push({
            path: '/usercenter'
          });
          break;
        case 'editPassword':
          this.$message('开发中...');
          break;
        case 'exitLogin':
          localStorage.removeItem('personlog_token');
          localStorage.removeItem('userInfo');
          this.$store.commit('SET_USER_INFO', null);
          this.$store.commit('SET_SHOW_LOGIN', true);
          this.$message.success('退出登录成功！');
          break;
        default:
          break;
      }
    },
    handleSelect(key) {
      if (key !== this.activeIndex) {
        this.$router.push({
          path: key
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.head-tab {
  width: 100%;
  height: 50px; /* 固定高度确保一致性 */
  max-height: 50px; /* 最大高度限制 */
  background: var(--bg-primary);
  box-shadow: 0 4px 12px var(--shadow-color);
  position: relative;
  overflow: hidden;
  box-sizing: border-box; /* 确保内边距不会增加元素尺寸 */
  display: flex; /* 使用flex布局确保内部元素不会溢出 */
  align-items: center; /* 垂直居中 */
  //justify-content: center;

  .tab-bar {
    display: flex;
    align-items: center;
    height: 50px;
    max-height: 50px !important;
    width: 100%;
    overflow-x: auto !important; /* 允许水平滚动 */
    overflow-y: hidden !important; /* 禁止垂直滚动 */
    transition: all 0.4s ease;
    position: relative;
    padding-left: 50px;
    padding-right: 5px;
    box-sizing: border-box; /* 确保不会因为padding导致溢出 */
    background-color: var(--bg-primary);
    border: none !important; /* 移除可能导致尺寸变化的边框 */
    
    /* 隐藏滚动条但保留功能 */
    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    
    /* 确保夜间模式下不会出现滚动条 */
    &.dark-mode {
      max-height: 50px;
      border: none;
    }
    
    /* 菜单项容器 */
    .tab-bar-inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 100%;
      width: max-content;
      height: 100%;
      max-height: 50px !important;
      overflow-x: visible !important; /* 允许内容超出 */
      overflow-y: hidden !important; /* 禁止垂直滚动 */
      padding: 0 5px;
      box-sizing: border-box; /* 确保不会因为padding导致溢出 */
    }

    /* 主题切换按钮容器 */
    .theme-toggle-wrapper {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      background-color: var(--bg-primary);
      border-radius: 50%;
      box-shadow: 0 1px 3px var(--shadow-color);
      overflow: hidden;
    }

    /* 菜单项 */
    .tab-bar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 45px; /* 稍微减小高度，确保不会溢出 */
      min-width: 80px;
      width: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      cursor: pointer;
      position: relative;
      overflow: hidden;
      padding: 0 8px;
      margin: 0 3px; /* 移除上下外边距，只保留左右外边距 */
      border-radius: 4px;
      white-space: nowrap;
      flex-shrink: 0;

      /* 图标样式 */
      svg {
        flex-shrink: 0;
        margin-bottom: 2px; /* 图标和文字之间的间距 */
      }

      /* 文字样式 */
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px; /* 进一步减小字体大小 */
        text-align: center; /* 文字居中 */
        line-height: 1.2; /* 控制行高 */
        margin-left: 0 !important; /* 移除左边距 */
      }

      &:hover {
        background-color: var(--hover-bg);
        transform: translateY(-1px);
      }

      &.active {
        background: var(--active-gradient);
        color: #fff;
        box-shadow: 0 4px 10px var(--shadow-color);
        transform: translateY(-1px);
        
        /* 激活状态下的图标稍微放大 */
        svg {
          transform: scale(1.05); /* 减小放大比例 */
        }
        
        /* 激活状态下的文字加粗 */
        span {
          color: white !important;
          font-weight: 600;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,0 L100,0 L100,100 L0,100 Z' fill='none' stroke='%23ffffff' stroke-width='1' opacity='0.3'/%3E%3C/svg%3E");
          opacity: 0.2; /* 减小不透明度 */
          animation: inkSpread 3s infinite alternate;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 25%;
          width: 50%;
          height: 2px;
          background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
          animation: inkFlow 2s infinite;
        }
      }
    }
  }

  .el-menu-demo {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    max-height: 50px !important; /* 强制限制最大高度 */
    border: none;
    background: var(--bg-primary);
    transition: all 0.4s ease;
    box-sizing: border-box; /* 确保内边距不会增加元素尺寸 */
    padding-left: 140px; /* 为左侧logo预留空间 */

    ::v-deep {
      .el-menu-item {
        height: 48px;
        max-height: 48px !important; /* 强制限制菜单项最大高度 */
        line-height: 48px;
        border-radius: 4px;
        margin: 0 2px;
        border: none;
        color: var(--text-secondary);
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        box-sizing: border-box; /* 确保内边距不会增加元素尺寸 */

        &:hover {
          background-color: var(--hover-bg);
          transform: translateY(-2px);
        }

        &.is-active {
          background: var(--active-gradient);
          color: var(--active-text);
          box-shadow: 0 4px 15px var(--shadow-color);
          transform: translateY(-2px);
          animation: activeBackground 3s infinite alternate; // 添加动画

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,0 L100,0 L100,100 L0,100 Z' fill='none' stroke='%23ffffff' stroke-width='1' opacity='0.3'/%3E%3C/svg%3E");
            opacity: 0.3;
            animation: inkSpread 3s infinite alternate;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent);
            animation: inkFlow 2s infinite;
          }
        }
      }
    }
  }

  .avatar {
    position: absolute;
    right: 20px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    .login-btn {
      height: 32px;
      width: 100px;
      line-height: 32px;
      background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
      border-radius: 16px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      transition: all 0.3s ease;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

      &:hover {
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        transform: translateY(-2px);
      }
    }
    
    .theme-toggle-btn {
      margin-left: 15px;
    }
  }

  .logo {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    z-index: 10; /* 确保logo在菜单之上 */

    &:hover {
      transform: translateY(-50%) scale(1.05);
    }

    img {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }
    }

    span {
      margin-left: 10px;
      font-size: 18px;
      font-weight: bold;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      background: linear-gradient(135deg, #5b7999, #3a5980);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@keyframes activeBackground {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@keyframes inkSpread {
  0% {
    transform: scale(0.98);
    opacity: 0.1;
  }

  100% {
    transform: scale(1.02);
    opacity: 0.3;
  }
}

@keyframes inkFlow {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}
</style>
