import Vue from 'vue'
import SvgIcon from '../components/SvgIcon/index'// svg封装地址
 
 
Vue.component('svg-icon', SvgIcon)
 
 // icons，放置svg 
// eslint-disable-next-line no-undef
const req = require.context('./icons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)