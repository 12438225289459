<template>
  <div class="theme-toggle" @click="toggleTheme">
    <transition name="theme-toggle" mode="out-in">
      <div v-if="isDarkMode" class="toggle-icon moon" key="sun">
        <i class="el-icon-moon"></i>
      </div>
      <div v-else class="toggle-icon sun" key="moon">
        <i class="el-icon-sunny"></i>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ThemeToggle',
  computed: {
    ...mapGetters(['isDarkMode'])
  },
  methods: {
    ...mapActions(['toggleTheme'])
  }
}
</script>

<style lang="scss" scoped>
.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--hover-bg);
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 3px var(--shadow-color);
  overflow: hidden;
  box-sizing: border-box;
  border: none;

  &:hover, &:active {
    background-color: var(--hover-bg);
    transform: none;
    opacity: 0.9;
    box-shadow: 0 2px 5px var(--shadow-color);
  }

  .toggle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    i {
      font-size: 20px;
      transition: all 0.3s ease;
      line-height: 1;
      transform: none;
    }

    &.sun i {
      color: #f39c12;
      font-size: 22px;
    }

    &.moon i {
      color: var(--text-secondary);
    }
  }
}

.theme-toggle-enter-active, .theme-toggle-leave-active {
  transition: all 0.3s ease;
}

.theme-toggle-enter, .theme-toggle-leave-to {
  opacity: 0;
  transform: rotate(180deg) scale(0.5);
}
</style>
