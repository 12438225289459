<template>
  <div ref="homeView" class="home-view">
    <!-- Hero Section -->
    <section class="hero-section">
      <div class="hero-bg">
        <img
          src="https://ai-public.mastergo.com/ai/img_res/4ca0c1ca710382c9dc90f8ab52d8469d.jpg"
          alt="Hero Background"
        />
        <div class="hero-gradient"></div>
      </div>
      <div class="hero-radial-gradient"></div>
      <div class="hero-content">
        <div class="hero-text">
          <div class="hero-welcome">
            <span>Welcome to My Tech World</span>
          </div>
          <h1>
            探索 <span>创新</span><br />
            分享 <span>技术</span>
          </h1>
          <p>
            在这里，我们将探讨前沿技术，分享专业见解，共同打造一个充满创造力和激情的技术社区。
          </p>
        </div>
      </div>
    </section>
    <!-- About Section -->
    <section class="about-section">
      <div class="about-bg"></div>
      <div class="about-content">
        <h2>关于我</h2>
        <div class="about-info">
          <div class="about-image">
            <img
              src="https://www.sweek.top/api/preview/avatar.jpg"
              alt="Profile"
            />
          </div>
          <div class="about-text">
            <div class="about-intro">
              <h3>Hi, 我是 Sweek</h3>
              <p>前端开发者 / UI设计师 / 技术博主</p>
            </div>
            <p>
              作为一名充满激情的软件工程师，我始终坚持技术创新与用户体验的完美结合。在 8 年的开发生涯中，我参与并主导了多个大型前端项目的开发，同时也在不断探索新的技术边界。
            </p>
            <p>
              我深信技术分享能够推动整个开发社区的进步，因此创建了这个博客平台，希望能与更多志同道合的开发者交流学习。
            </p>
            <div class="about-stats">
              <div>
                <div>50+</div>
                <div>技术文章</div>
              </div>
              <div>
                <div>15k+</div>
                <div>月访问量</div>
              </div>
              <div>
                <div>3k+</div>
                <div>社区粉丝</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Latest Articles -->
    <section class="latest-articles-section">
      <div class="articles-content">
        <div class="articles-header">
          <h2>最新文章</h2>
          <p>探索前沿技术，分享开发心得</p>
        </div>
        <div class="articles-grid">
          <article>
            <div class="article-image">
              <img
                src="https://ai-public.mastergo.com/ai/img_res/8f14d555d5c7f5907cd5b6f7d40d76ac.jpg"
                alt="Article 1"
              />
            </div>
            <div class="article-text">
              <h3>深入理解 Vue3 响应式原理</h3>
              <p>
                探索 Vue3 的响应式系统实现原理，从源码角度深入分析其工作机制。
              </p>
              <div class="article-footer">
                <span>2024-01-15</span>
                <div @click="readFullArticle">阅读全文</div>
              </div>
            </div>
          </article>
          <article>
            <div class="article-image">
              <img
                src="https://ai-public.mastergo.com/ai/img_res/d5e7192db2c720f4a134af6f77e92800.jpg"
                alt="Article 2"
              />
            </div>
            <div class="article-text">
              <h3>现代前端工程化实践</h3>
              <p>
                分享当下流行的前端工程化解决方案，助你打造高效的开发流程。
              </p>
              <div class="article-footer">
                <span>2024-01-10</span>
                <div @click="readFullArticle">阅读全文</div>
              </div>
            </div>
          </article>
          <article>
            <div class="article-image">
              <img
                src="https://ai-public.mastergo.com/ai/img_res/beef07515daeda0fbeadf301c73f8ffc.jpg"
                alt="Article 3"
              />
            </div>
            <div class="article-text">
              <h3>UI 设计与用户体验优化</h3>
              <p>
                探讨如何通过优秀的设计提升产品体验，实现更好的用户转化。
              </p>
              <div class="article-footer">
                <span>2024-01-05</span>
                <div @click="readFullArticle">阅读全文</div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
    <!-- Popular Tags -->
    <section class="popular-tags-section">
      <div class="tags-bg"></div>
      <div class="tags-content">
        <div class="tags-header">
          <h2>热门标签</h2>
          <p>发现你感兴趣的技术领域</p>
        </div>
        <div class="tags-list">
          <span @click="selectTag">前端开发</span>
          <span @click="selectTag">用户体验</span>
          <span @click="selectTag">JavaScript</span>
          <span @click="selectTag">Vue.js</span>
          <span @click="selectTag">设计系统</span>
        </div>
      </div>
    </section>
    <!-- Contact -->
    <section class="contact-section">
      <div class="contact-bg"></div>
      <div class="contact-content">
        <div class="contact-header">
          <h2>联系我</h2>
          <p>我们一起交流技术，分享见解</p>
        </div>
        <div class="contact-info">
          <p>
            如果你有任何问题或建议，欢迎通过以下方式联系我：
          </p>
          <div class="contact-icons">
            <a href="#">
              <i class="bi bi-github"></i>
            </a>
            <a href="#">
              <i class="bi bi-twitter"></i>
            </a>
            <a href="#">
              <i class="bi bi-linkedin"></i>
            </a>
          </div>
          <div class="contact-details">
            <div>
              <i class="fas fa-envelope"></i>
              <h3>邮箱联系</h3>
              <p>1877288470@qq.com</p>
            </div>
            <div>
              <i class="fas fa-map-marker-alt"></i>
              <h3>工作地址</h3>
              <p>合肥市高新区创新大道</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Back to Top -->
    <button v-if="showBackToTop" class="button" @click="scrollToTop">
      <i class="bi bi-arrow-bar-up"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      showBackToTop: false
    };
  },
  mounted() {
    // console.log('this.$refs.homeView:', this.$refs.homeView);
    if (this.$refs.homeView) {
      this.$refs.homeView.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy() {
    if (this.$refs.homeView) {
      this.$refs.homeView.removeEventListener('scroll', this.handleScroll);
    }
  },
  activated () {
    this.$refs.homeView.scrollTop = this.$route.meta.scrollTop
  },
  beforeRouteLeave (to, from, next) { // 离开组件的时候触发
    from.meta.scrollTop = this.$refs.homeView.scrollTop
    next()
  },
  methods: {
    startExploring() {
      // Handle start exploring button click event
    },
    learnMore() {
      // Handle learn more button click event
    },
    readFullArticle() {
      // Handle read full article button click event
    },
    selectTag() {
      // Handle tag selection event
    },
    scrollToTop() {
      if (this.$refs.homeView) {
        this.$refs.homeView.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    handleScroll() {
      if (this.$refs.homeView) {
        // Use scrollTop instead of scrollY
        // console.log('scrollTop:', this.$refs.homeView.scrollTop);
        this.showBackToTop = this.$refs.homeView.scrollTop > 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home-view {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--bg-secondary, #f9fafb);

  // Hero Section
  .hero-section {
    position: relative;
    height: 700px;
    overflow: hidden;
    background: linear-gradient(to bottom right, #1f2937, #1e40af, #7e22ce);

    .hero-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.4;
      }

      .hero-gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(30, 64, 175, 0.8), transparent);
      }
    }

    .hero-radial-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle at 30% 50%, rgba(59, 130, 246, 0.1), transparent 70%);
    }

    .hero-content {
      position: relative;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 1.5rem;
      height: 100%;
      display: flex;
      align-items: center;

      .hero-text {
        max-width: 512px;

        .hero-welcome {
          display: inline-block;
          margin-bottom: 1rem;
          padding: 0.5rem 1.5rem;
          border-radius: 9999px;
          background-color: rgba(59, 130, 246, 0.1);
          backdrop-filter: blur(4px);
          border: 1px solid rgba(59, 130, 246, 0.2);

          span {
            color: #93c5fd;
          }
        }

        h1 {
          font-size: 4.5rem;
          font-weight: 700;
          margin-bottom: 2rem;
          color: #fff;
          line-height: 1;
          letter-spacing: -0.025em;

          span {
            background: linear-gradient(to right, #60a5fa, #22d3ee);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }

        p {
          font-size: 1.25rem;
          margin-bottom: 3rem;
          color: #d1d5db;
          max-width: 48rem;
          line-height: 1.625;
          font-weight: 300;
        }

        .hero-buttons {
          display: flex;
          gap: 1.5rem;

          button {
            padding: 1rem 3rem;
            border-radius: 9999px;
            white-space: nowrap;
            transition: all 0.3s;
            font-size: 1.125rem;
            box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.25);

            &:first-child {
              background: linear-gradient(to right, #2563eb, #3b82f6);
              color: #fff;

              &:hover {
                background: linear-gradient(to right, #1d4ed8, #2563eb);
                box-shadow: 0 10px 15px -3px rgba(37, 99, 235, 0.3);
              }
            }

            &:last-child {
              background-color: rgba(255, 255, 255, 0.1);
              backdrop-filter: blur(4px);
              color: #fff;
              border: 1px solid rgba(255, 255, 255, 0.2);

              &:hover {
                background-color: #fff;
                color: #1e40af;
                border-color: #fff;
              }
            }
          }
        }
      }
    }

    .hero-bottom-gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6rem;
      background: linear-gradient(to top, #f9fafb, transparent);
    }
  }

  // About Section
  .about-section {
    padding: 6rem 0;
    background-color: var(--card-bg, #fff);
    position: relative;
    overflow: hidden;

    .about-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(191, 219, 254, 0.5);
    }

    .about-content {
      position: relative;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 1.5rem;

      h2 {
        font-size: 2.25rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 4rem;
        color: var(--text-primary, inherit);
      }

      .about-info {
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
          flex-direction: row;
        }
        align-items: center;
        justify-content: space-between;
        gap: 4rem;

        .about-image {
          width: 18rem;
          height: 18rem;
          border-radius: 1rem;
          overflow: hidden;
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
          transition: transform 0.5s;

          &:hover {
            transform: scale(1.05);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .about-text {
          max-width: 512px;

          .about-intro {
            margin-bottom: 2rem;

            h3 {
              font-size: 1.5rem;
              font-weight: 600;
              color: var(--text-primary, #1e40af);
              margin-bottom: 0.5rem;
            }

            p {
              color: var(--text-secondary, #2563eb);
            }
          }

          p {
            font-size: 1.125rem;
            line-height: 1.625;
            color: var(--text-secondary, #4b5563);
            margin-bottom: 2rem;
          }

          .about-stats {
            display: flex;
            gap: 1.5rem;

            div {
              text-align: center;

              div:first-child {
                font-size: 2.25rem;
                font-weight: 700;
                color: var(--text-primary, #2563eb);
                margin-bottom: 0.5rem;
              }

              div:last-child {
                color: var(--text-secondary, #6b7280);
              }
            }
          }
        }
      }
    }
  }

  // Latest Articles
  .latest-articles-section {
    padding: 6rem 0;
    background-color: var(--bg-secondary, #f9fafb);

    .articles-content {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 1.5rem;

      .articles-header {
        text-align: center;
        margin-bottom: 4rem;

        h2 {
          font-size: 2.25rem;
          font-weight: 700;
          margin-bottom: 1rem;
          color: var(--text-primary, inherit);
        }

        p {
          font-size: 1.25rem;
          color: var(--text-secondary, #6b7280);
        }
      }

      .articles-grid {
        display: grid;
        grid-template-columns: 1fr;
        @media (min-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 1024px) {
          grid-template-columns: repeat(3, 1fr);
        }
        gap: 2.5rem;

        article {
          background-color: var(--card-bg, #fff);
          border-radius: 0.75rem;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
          overflow: hidden;
          transition: all 0.5s;

          &:hover {
            transform: translateY(-0.5rem);
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
          }

          .article-image {
            height: 12rem;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .article-text {
            padding: 1.5rem;

            h3 {
              font-size: 1.25rem;
              font-weight: 600;
              margin-bottom: 0.75rem;
              color: var(--text-primary, inherit);
            }

            p {
              color: var(--text-secondary, #6b7280);
              margin-bottom: 1rem;
            }

            .article-footer {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span {
                font-size: 0.875rem;
                color: var(--text-secondary, #9ca3af);
              }

              div {
                color: var(--text-primary, #2563ebcc);
                transition: color 0.3s;
                cursor: pointer;

                &:hover {
                  color: var(--text-primary, #1d4ed8);
                }
              }
            }
          }
        }
      }
    }
  }

  // Popular Tags
  .popular-tags-section {
    padding: 6rem 0;
    background-color: var(--card-bg, #fff);
    position: relative;
    overflow: hidden;

    .tags-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(191, 219, 254, 0.5), transparent);
    }

    .tags-content {
      position: relative;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 1.5rem;

      .tags-header {
        text-align: center;
        margin-bottom: 4rem;

        h2 {
          font-size: 2.25rem;
          font-weight: 700;
          margin-bottom: 1rem;
          color: var(--text-primary, inherit);
        }

        p {
          font-size: 1.25rem;
          color: var(--text-secondary, #6b7280);
        }
      }

      .tags-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        max-width: 1024px;
        margin: 0 auto;

        span {
          padding: 0.75rem 2rem;
          background-color: var(--hover-bg, #eff6ff);
          color: var(--text-primary, #2563eb);
          border-radius: 9999px;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            background-color: var(--hover-bg, #dbeafe);
            transform: scale(1.05);
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          }
        }
      }
    }
  }

  // Contact
  .contact-section {
    padding: 6rem 0;
    background-color: var(--bg-secondary, #f9fafb);
    position: relative;

    .contact-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(191, 219, 254, 0.5), transparent);
    }

    .contact-content {
      position: relative;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 1.5rem;

      .contact-header {
        text-align: center;
        margin-bottom: 4rem;

        h2 {
          font-size: 2.25rem;
          font-weight: 700;
          margin-bottom: 1rem;
          color: var(--text-primary, inherit);
        }

        p {
          font-size: 1.25rem;
          color: var(--text-secondary, #6b7280);
        }
      }

      .contact-info {
        max-width: 768px;
        margin: 0 auto;
        text-align: center;

        p {
          font-size: 1.125rem;
          color: var(--text-secondary, #6b7280);
          margin-bottom: 2rem;
        }

        .contact-icons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2.5rem;
          margin-bottom: 3rem;

          a {
            width: 3.5rem;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 9999px;
            background-color: var(--hover-bg, #f3f4f6);
            color: var(--text-secondary, #6b7280);
            transition: all 0.3s;

            &:hover {
              background-color: #2563eb;
              color: #fff;
              transform: scale(1.1);
            }

            i {
              font-size: 1.5rem;
            }
          }
        }

        .contact-details {
          background-color: var(--card-bg, #fff);
          padding: 2.5rem;
          border-radius: 0.75rem;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
          backdrop-filter: blur(8px);
          background-color: var(--card-bg, rgba(255, 255, 255, 0.8));
          display: grid;
          grid-template-columns: 1fr;
          @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }
          gap: 2rem;

          div {
            text-align: center;

            i {
              font-size: 2.25rem;
              color: var(--text-primary, #2563eb);
              margin-bottom: 1rem;
            }

            h3 {
              font-size: 1.125rem;
              font-weight: 600;
              margin-bottom: 0.5rem;
              color: var(--text-primary, inherit);
            }

            p {
              color: var(--text-secondary, #6b7280);
            }
          }
        }
      }
    }
  }

  // Back to Top
  .button {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 9999px;
    background-color: var(--text-primary, #2563eb);
    color: var(--bg-primary, #fff);
    border: none;
    cursor: pointer;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--text-primary, #1d4ed8);
      transform: scale(1.1);
    }

    i {
      font-size: 1.25rem;
    }
  }
}
</style>