<template>
  <div class="main-container">
    <!-- 顶部搜索和筛选区域 -->
    <div class="search-filter-area">
      <div class="search-filter-content">
        <div class="search-input-container">
          <input
            type="text"
            v-model="searchForm.keyword"
            placeholder="搜索教程..."
          />
          <i class="fas fa-search"></i>
        </div>
        <div class="tag-buttons">
          <button
            v-for="tag, index in tags"
            :key="index"
            @click="toggleTag(tag.id)"
            :class="{ 'active': tag.id === selectedTag }"
          >
            <i :class="tag.icon"></i>
            {{ tag.name }}
          </button>
        </div>
      </div>
    </div>

    <!-- 视频卡片网格 -->
    <div class="video-card-grid">
      <div
        v-for="tutorial in tutorials"
        :key="tutorial.id"
        @click="playVideo(tutorial)"
      >
        <div class="video-thumbnail">
          <el-image
            :src="tutorial.thumbnail"
          />
          <span>{{ tutorial.duration }}</span>
        </div>
        <div class="video-info">
          <h3>{{ tutorial.title }}</h3>
          <p>{{ tutorial.description }}</p>
          <div class="video-meta">
            <div>
              <i class="fas fa-play-circle"></i>
              <span>{{ tutorial.views }} 次观看</span>
            </div>
            <div>
              <i class="fas fa-clock"></i>
              <span>{{ tutorial.date }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 视频播放弹窗 -->
    <div v-if="selectedVideo" class="video-popup">
      <div class="popup-content">
        <div class="popup-header">
          <h2>{{ selectedVideo.title }}</h2>
          <button @click="selectedVideo = null">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <!-- 替换为视频播放器 -->
        <video controls width="100%">
          <source :src="selectedVideo.videoUrl" type="video/mp4">
          您的浏览器不支持视频播放。
        </video>
        <p>{{ selectedVideo.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.js'
import { getAllTutorials, incrementViews } from "@/api/tutorials.js"
export default {
  data() {
    return {
      searchForm: {
        pageSize: 100,
        pageNum: 1,
        keyword: '',
        tag: ''
      },
      selectedTag: '',
      selectedVideo: null,
      tags: [
        { id: '', name: '全部教程', icon: 'fas fa-laptop-code' },
        { id: '扩展推荐', name: '扩展推荐', icon: 'fas fa-graduation-cap' },
        { id: '脚本使用', name: '脚本使用', icon: 'fas fa-chart-line' },
        { id: '最新更新', name: '最新更新', icon: 'fas fa-clock' }
      ],
      tutorials: []
    };
  },
  computed: {
    ifPC() {
      return this.$store.state.ifPC
    },
    userInfo() {
      return this.$store.state.userInfo || null
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: this.isDarkMode ? 'rgba(30, 30, 30, 0.7)' : 'rgba(255, 255, 255, 0.7)'
      })
      this.searchForm.tag = this.selectedTag
      getAllTutorials(this.searchForm).then(res => {
        res.list.forEach(item => {
          item.date = formatDate(item.created_at)
        })
        this.tutorials = res.list
      }).finally(() => {
        loading.close()
      })
    },
    toggleTag(tagName) {
      this.selectedTag = tagName
      this.getList()
    },
    playVideo(tutorial) {
      // 如果不存在 token，直接显示登录
      if (!localStorage.getItem('personlog_token')) {
        this.$message.error('请先登录！');
        this.$store.commit('SET_SHOW_LOGIN', true);
        return
      }
      this.selectedVideo = tutorial;
      incrementViews({ id: tutorial.id, email: this.userInfo.email })
    }
  }
};
</script>

<style scoped lang="scss">
.main-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: var(--bg-secondary);
  padding: 10px;
}

.search-filter-area {
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  @media (min-width: 640px) {
    padding: 2rem 1.5rem;
  }
  @media (min-width: 1024px) {
    padding: 2rem 2rem;
  }
}

.search-filter-content {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  gap: 1rem;
  margin-bottom: 2rem;
}

.search-input-container {
  position: relative;
  width: 100%;
  @media (min-width: 768px) {
    width: 24rem;
  }
  input {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    border: none;
    background-color: var(--card-bg);
    color: var(--text-primary);
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 var(--shadow-color);
    outline: none;
    &:focus {
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
    }
    font-size: 0.875rem;
  }
  i {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
}

.tag-buttons {
  display: flex;
  gap: 0.75rem;
  button {
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 var(--shadow-color);
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      box-shadow: 0 4px 6px -1px var(--shadow-color);
    }
    i {
      margin-right: 0.5rem;
    }
    &:not(.selected) {
      background-color: var(--card-bg);
      color: var(--text-primary);
    }
    &.selected {
      background-color: #3b82f6;
      color: #fff;
    }
    &.active {
      background-color: #007bff;
      color: #fff;
    }
  }
}

.video-card-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  gap: 1.5rem;
}

.video-card-grid > div {
  background-color: var(--card-bg);
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px 0 var(--shadow-color);
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 10px 15px -3px var(--shadow-color);
    transform: translateY(-0.25rem);
  }
  overflow: hidden;
}

.video-thumbnail {
  position: relative;
  img {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: top;
  }
  span {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
}

.video-info {
  padding: 1rem;
  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
  }
  p {
    color: var(--text-secondary);
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .video-meta {
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      i {
        margin-right: 0.5rem;
      }
      span {
        color: var(--text-secondary);
        font-size: 0.875rem;
      }
    }
  }
}

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.popup-content {
  background-color: var(--card-bg);
  border-radius: 0.75rem;
  width: 100%;
  max-width: 56rem;
  padding: 1rem;
  @media (min-width: 640px) {
    padding: 1.5rem;
  }
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--text-primary);
  }
  button {
    color: var(--text-secondary);
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    border: none;
    &:hover {
      color: var(--text-primary);
    }
  }
}

video {
  border-radius: 0.75rem;
  margin-bottom: 1rem;
}

.popup-content p {
  color: var(--text-secondary);
}
</style>