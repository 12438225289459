<template>
  <div class="user-management">
    <!-- <h2>用户管理</h2> -->
    <div class="search-add">
      <div class="search">
        <input type="text" v-model="queryParams.keyword" placeholder="请输入关键词...">
        <i class="fas fa-search"></i>
      </div>
      <div>
        <button @click="handleSearch">
          <i class="fas fa-search"></i>查询
        </button>
        <!-- <button @click="handleReset" style="margin-left: 10px;background-color: #fff;color: black;border: 1px solid gray;">
          <i class="fas fa-refresh"></i>重置
        </button> -->
        <!-- <button>
          <i class="fas fa-plus"></i>添加用户
        </button> -->
      </div>
    </div>
    <div class="table-container">
      <!-- 使用 ElementUI 的表格组件 -->
      <el-table :data="tableData" max-height="650">
        <el-table-column prop="name" label="用户名" min-width="180" show-overflow-tooltip>
          <template #default="{ row }">
            <div class="user-info">
              <img
                :src="row.avatar ? ('https://www.sweek.top/api/preview/' + row.avatar) : 'https://www.sweek.top/api/preview/personlog-avatar.jpg'"
                class="avatar" alt="用户头像">
              <span>{{ row.nickname }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" min-width="240" show-overflow-tooltip></el-table-column>
        <el-table-column prop="code" label="验证码" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="role" label="角色" min-width="120" show-overflow-tooltip>
          <template #default="{ row }">
            <el-tag size="mini" :type="row.role === 'admin' ? 'success' : 'info'" style="border-radius: 2px;">
              {{ row.role || '-' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="注册时间" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updated_at" label="更新时间" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template #default="{ row }">
            <button @click="handleEdit(row)">
              <i class="fas fa-edit"></i>
            </button>
            <!-- <button>
              <i class="fas fa-trash"></i>
            </button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum" :page-sizes="[5, 10, 20, 30]" :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 编辑用户信息弹窗 -->
    <el-dialog :visible.sync="editDialogVisible" title="编辑用户信息" :width="ifPC ? '60%' : '100%'"
      @close="editDialogVisible = false" :append-to-body="true" :modal-append-to-body="true">
      <el-form :model="editForm" label-width="100px" label-position="top">
        <div class="form-row">
          <el-form-item label="邮箱" size="mini">
            <el-input v-model="editForm.email"></el-input>
          </el-form-item>
          <el-form-item label="角色" size="mini">
            <el-select v-model="editForm.role" placeholder="请选择角色" style="width: 100%">
              <el-option label="user" value="user"></el-option>
              <el-option label="admin" value="admin"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="用户名" size="mini">
            <el-input v-model="editForm.username" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="密码" size="mini">
            <el-input v-model="editForm.password" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="昵称" size="mini">
            <el-input v-model="editForm.nickname" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="地址" size="mini">
            <el-input v-model="editForm.address" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="详细地址" size="mini">
            <el-input v-model="editForm.addressdetail" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="电话" size="mini">
            <el-input v-model="editForm.phone" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="性别" size="mini">
            <el-select v-model="editForm.sex" placeholder="请选择性别" style="width: 100%">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="头像" size="mini">
            <el-input v-model="editForm.avatar" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="签名" size="mini">
            <el-input v-model="editForm.signature" type="textarea" style="width: 100%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="editDialogVisible = false">取消</el-button>
          <el-button size="mini" type="primary" @click="handleUpdate">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.js'
import { getUsersList, updateUserInfo } from '@/api/user.js'

export default {
  data() {
    return {
      total: 0,
      queryParams: {
        pageSize: 5,
        pageNum: 1,
        keyword: '',
        email: ''
      },
      tableData: [],
      editDialogVisible: false,
      editForm: {
        email: '',
        role: null,
        username: '',
        password: '',
        nickname: '',
        address: null,
        phone: null,
        signature: null,
        sex: null,
        avatar: null,
        addressdetail: null
      }
    };
  },
  computed: {
    ifPC() {
      return this.$store.state.ifPC;
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleReset() {
      this.queryParams = this.$options.data().queryParams
      this.getList()
    },
    handleSearch() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    getList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: this.$store.state.isDark ? 'rgba(30, 30, 30, 0.7)' : 'rgba(255, 255, 255, 0.7)'
      })
      getUsersList(this.queryParams).then(res => {
        res.result.forEach(item => {
          item.created_at = formatDate(item.created_at)
          item.updated_at = formatDate(item.updated_at)
        })
        this.tableData = res.result;
        this.total = res.total;
      }).finally(() => {
        loading.close()
      })
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNum = newPage;
      this.getList()
    },
    handleEdit(row) {
      this.editForm = { ...row };
      this.editDialogVisible = true;
    },
    handleUpdate() {
      updateUserInfo(this.editForm).then(() => {
        this.editDialogVisible = false;
        this.getList(); // 更新数据列表
      }).catch(error => {
        console.error('更新用户信息失败', error);
      });
    }
  }
};
</script>

<style scoped lang="scss">
/* 整体容器样式 */
.user-management {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-secondary);
    box-shadow: 0 0 10px var(--shadow-color);
  }

  ::v-deep {

    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin-bottom: 2px;
    }

    .el-form-item {
      margin-bottom: 2px;
    }

    .el-form--label-top .el-form-item__label {
      float: none;
      display: inline-block;
      text-align: left;
      padding: 0 0 0px;

      /* 适配黑夜模式 */
      .dark-mode & {
        color: var(--text-primary);
      }
    }

    .el-dialog__body {
      padding: 0.14286rem 1.42857rem;
      color: #606266;
      font-size: 1rem;
      word-break: break-all;

      /* 适配黑夜模式 */
      .dark-mode & {
        color: var(--text-primary);
        background-color: var(--bg-primary);
      }
    }

    /* 适配黑夜模式 - 对话框样式 */
    .dark-mode & {
      .el-dialog {
        background-color: var(--bg-primary);

        .el-dialog__header {
          background-color: var(--bg-secondary);
          color: var(--text-primary);

          .el-dialog__title {
            color: var(--text-primary);
          }

          .el-dialog__headerbtn .el-dialog__close {
            color: var(--text-secondary);
          }
        }

        .el-dialog__footer {
          background-color: var(--bg-secondary);
        }
      }

      .el-button {
        background-color: var(--bg-secondary);
        border-color: var(--border-color);
        color: var(--text-primary);

        &:hover {
          border-color: var(--accent-color);
          color: var(--accent-color);
        }

        &--primary {
          background-color: var(--accent-color);
          border-color: var(--accent-color);
          color: #ffffff;

          &:hover {
            background-color: var(--accent-hover);
            border-color: var(--accent-hover);
          }
        }
      }

      .el-input__inner, .el-textarea__inner {
        background-color: var(--bg-secondary);
        border-color: var(--border-color);
        color: var(--text-primary);
      }

      .el-select-dropdown {
        background-color: var(--bg-primary);
        border-color: var(--border-color);

        .el-select-dropdown__item {
          color: var(--text-primary);

          &.hover, &:hover {
            background-color: var(--hover-bg);
          }

          &.selected {
            color: var(--accent-color);
            font-weight: bold;
          }
        }
      }

      .el-tag {
        &.el-tag--info {
          background-color: var(--bg-secondary);
          border-color: var(--border-color);
          color: var(--text-primary);
        }

        &.el-tag--success {
          background-color: rgba(var(--success-color-rgb), 0.1);
          border-color: rgba(var(--success-color-rgb), 0.2);
          color: var(--success-color);
        }
      }
    }

    .el-table th.el-table__cell {
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      background-color: #f2F2F2;
      color: #000000;

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-secondary);
        color: var(--text-primary);
      }
    }

    /* 适配黑夜模式 - 分页样式 */
    .dark-mode & {
      .el-pagination {
        button {
          background-color: var(--bg-secondary);
          color: var(--text-primary);

          &:disabled {
            color: var(--text-disabled);
          }
        }

        .el-pager li {
          background-color: var(--bg-secondary);
          color: var(--text-primary);

          &.active {
            color: var(--accent-color);
          }

          &:hover {
            color: var(--accent-hover);
          }
        }

        .el-pagination__total,
        .el-pagination__jump,
        .el-pagination__sizes {
          color: var(--text-primary);
        }

        .el-select .el-input .el-input__inner {
          color: var(--text-primary);
        }
      }
    }
  }
}

/* 标题样式 */
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* 搜索和添加按钮区域样式 */
.search-add {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .search {
    position: relative;
    width: 300px;

    @media (max-width: 768px) {
      width: 100%;
    }

    input {
      width: 100%;
      padding: 8px 12px 8px 32px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      outline: none;

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-primary);
        border-color: var(--border-color);
        color: var(--text-primary);
      }
    }

    i {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #606266;

      /* 适配黑夜模式 */
      .dark-mode & {
        color: var(--text-secondary);
      }
    }
  }

  button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #409eff;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;

    i {
      margin-right: 5px;
    }

    &:hover {
      background-color: #66b1ff;
    }

    /* 适配黑夜模式 */
    .dark-mode & {
      background-color: var(--accent-color);

      &:hover {
        background-color: var(--accent-hover);
      }
    }
  }
}

/* 表格容器样式 */
.table-container {
  width: 100%;
  /* 允许水平滚动 */
  overflow-x: auto;
  /* 移除不必要的高度限制 */
  height: auto;

  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-primary);
    box-shadow: 0 2px 12px 0 var(--shadow-color);
  }

  /* 调整 ElementUI 表格样式，使其与原样式一致 */
  .el-table {
    margin-bottom: 20px;

    /* 适配黑夜模式 */
    .dark-mode & {
      --el-table-bg-color: var(--bg-primary) !important;
      --el-table-tr-bg-color: var(--bg-primary) !important;
      --el-table-border-color: var(--border-color) !important;
      --el-table-text-color: var(--text-primary) !important;
      --el-table-header-bg-color: var(--bg-secondary) !important;
      --el-table-header-text-color: var(--text-primary) !important;

      .el-table__body-wrapper, .el-table__header-wrapper {
        background-color: var(--bg-primary);
      }
    }

    /* 让表格宽度由内容决定 */
    width: 100%;
    border-collapse: collapse;
    /* 移除不必要的滚动设置 */
    overflow-y: auto;
    overflow-x: auto;

    .el-table__header th,
    .el-table__body td {
      padding: 12px;
      border-bottom: 1px solid #ddd;
      text-align: left;
    }

    .el-table__header th {
      background-color: #ffffff;
      font-weight: 600;
      color: #000000;

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-secondary);
        color: var(--text-primary);
      }
    }

    .el-table__body tr:hover {
      background-color: #f5f5f5;

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--hover-bg) !important;
      }
    }

    button {
      padding: 6px 12px;
      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;

      /* 适配黑夜模式 */
      .dark-mode & {
        border-color: var(--border-color);
        color: var(--text-primary);
      }

      &:hover {
        background-color: #f2f2f2;

        /* 适配黑夜模式 */
        .dark-mode & {
          background-color: var(--hover-bg);
        }
      }

      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  /* 分页组件样式 */
  .el-pagination {
    display: flex;
    justify-content: flex-end;

    /* 添加小屏幕下的样式调整 */
    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

/* 用户信息样式 */
.user-info {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }
}

/* 编辑弹窗样式 */
.el-dialog .el-form {
  display: flex;
  flex-wrap: wrap;

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .el-form-item {
      width: 48%;
    }
  }
}
</style>
