<template>
  <div class="user-management">
    <!-- <h2>使用教程</h2> -->
    <div class="search-add">
      <div class="search">
        <input type="text" v-model="queryParams.keyword" placeholder="请输入关键词...">
        <i class="fas fa-search"></i>
      </div>
      <div>
        <button @click="handleSearch" style="margin-right: 5px">
          <i class="fas fa-search"></i>查询
        </button>
        <!-- <button @click="handleReset" style="margin-left: 10px;background-color: #fff;color: black;border: 1px solid gray;">
          <i class="fas fa-refresh"></i>重置
        </button> -->
        <button @click="addDialogVisible = true">
          <i class="fas fa-plus"></i>添加教程
        </button>
      </div>
    </div>
    <div class="table-container">
      <!-- 使用 ElementUI 的表格组件 -->
      <el-table
        :data="tableData"
        max-height="650"
      >
        <el-table-column prop="title" label="标题" min-width="280" show-overflow-tooltip>
          <template #default="{ row }">
            <div class="user-info">
              <img :src="row.thumbnail" class="avatar" alt="">
              <span>{{ row.title }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="views" label="浏览量" min-width="80" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="description" label="描述" min-width="240" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="tag" label="标签" min-width="220" show-overflow-tooltip>
          <template #default="{ row }">
            <el-tag v-for="item in row.tag.split('/')" :key="item" size="mini" style="border-radius: 2px;margin-right: 2px;">
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updated_at" label="更新时间" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template #default="{ row }">
            <button @click="handleEdit(row)">
              <i class="fas fa-edit"></i>
            </button>
            <button @click="handleDelete(row.id)">
              <i class="fas fa-trash"></i>
            </button>
            <button style="margin-left: 5px;" @click="handleViews(row)">
              <i class="fa-solid fa-grip"></i>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[5, 10, 20, 30]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog
      :visible.sync="editDialogVisible"
      title="编辑教程信息"
      :width="ifPC ? '60%' : '100%'"
      @close="editDialogVisible = false"
      :append-to-body="true" :modal-append-to-body="true"
    >
      <el-form :model="editForm" label-width="100px" label-position="top">
        <div class="form-row">
          <el-form-item label="标题" size="mini">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="标签" size="mini">
            <el-input v-model="editForm.tag" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="封面图片" size="mini">
            <el-input v-model="editForm.thumbnail" type="textarea" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="视频链接" size="mini">
            <el-input v-model="editForm.videoUrl" type="textarea" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="时长" size="mini">
            <el-input v-model="editForm.duration" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="浏览量" size="mini">
            <el-input v-model="editForm.views" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="描述" size="mini">
            <el-input v-model="editForm.description" type="textarea" style="width: 100%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="editDialogVisible = false">取消</el-button>
          <el-button size="mini" type="primary" @click="handleUpdate">保存</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新增弹窗 -->
    <el-dialog
      :visible.sync="addDialogVisible"
      title="新增教程信息"
      :width="ifPC ? '60%' : '100%'"
      @close="addDialogVisible = false"
    >
      <el-form :model="addForm" label-width="100px" label-position="top">
        <div class="form-row">
          <el-form-item label="标题" size="mini">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="标签" size="mini">
            <el-input v-model="addForm.tag" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="封面图片" size="mini">
            <el-input v-model="addForm.thumbnail" type="textarea" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="视频链接" size="mini">
            <el-input v-model="addForm.videoUrl" type="textarea" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="时长" size="mini">
            <el-input v-model="addForm.duration" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="浏览量" size="mini">
            <el-input v-model="addForm.views" type="number" style="width: 100%"></el-input>
          </el-form-item>
        </div>
        <div class="form-row">
          <el-form-item label="描述" size="mini">
            <el-input v-model="addForm.description" type="textarea" style="width: 100%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="addDialogVisible = false">取消</el-button>
          <el-button size="mini" type="primary" @click="handleAdd">保存</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新增查看教程浏览记录弹窗 -->
    <!-- 新增查看教程浏览记录弹窗 -->
    <el-dialog
      :visible.sync="viewsDialogVisible"
      title="教程浏览记录"
      :width="ifPC ? '80%' : '100%'"
      @close="viewsDialogVisible = false"
      :append-to-body="true" :modal-append-to-body="true"
    >
      <div class="search">
        <input type="text" v-model="viewsQueryParams.keyword" style="width: 250px;" placeholder="请输入关键词...">
        <button @click="handleViewsSearch">查询</button>
      </div>
      <el-table
        :data="viewsTableData"
        max-height="450"
      >
        <!-- <el-table-column prop="id" label="ID" min-width="50" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="email" label="邮箱" min-width="180" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="tutorial_id" label="教程ID" min-width="80" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="created_at" label="浏览时间" min-width="180" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleViewsSizeChange"
        @current-change="handleViewsCurrentChange"
        :current-page="viewsQueryParams.pageNum"
        :page-sizes="[5, 10, 20, 30]"
        :page-size="viewsQueryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="viewsTotal"
        class="views-pagination"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.js'
import { getAllTutorials, addTutorial, updateTutorialById, deleteTutorialById, getAllTutorialViews } from '@/api/tutorials.js'

export default {
  data() {
    return {
      total: 0,
      queryParams: {
        pageSize: 5,
        pageNum: 1,
        keyword: ''
      },
      tableData: [],
      editDialogVisible: false,
      editForm: {
        title: '',
        thumbnail: null,
        tag: '',
        duration: '',
        description: '',
        videoUrl: null,
        views: null
      },
      addDialogVisible: false,
      addForm: {
        title: '',
        thumbnail: null,
        tag: '',
        duration: '',
        description: '',
        videoUrl: null,
        views: null
      },
      viewsDialogVisible: false,
      viewsQueryParams: {
        pageSize: 10,
        pageNum: 1,
        keyword: '',
        tutorial_id: ''
      },
      viewsTableData: [],
      viewsTotal: 0
    };
  },
  computed: {
    ifPC() {
      return this.$store.state.ifPC;
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleReset() {
      this.queryParams = this.$options.data().queryParams
      this.getList()
    },
    handleSearch() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    getList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: this.$store.state.isDark ? 'rgba(30, 30, 30, 0.7)' : 'rgba(255, 255, 255, 0.7)'
      })
      getAllTutorials(this.queryParams).then(res => {
        res.list.forEach(item => {
          item.created_at = formatDate(item.created_at)
          item.updated_at = formatDate(item.updated_at)
        })
        this.tableData = res.list;
        this.total = res.total;
      }).finally(() => {
        loading.close()
      })
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getList()
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNum = newPage;
      this.getList()
    },
    handleEdit(row) {
      this.editForm = { ...row };
      this.editDialogVisible = true;
    },
    handleUpdate() {
      updateTutorialById(this.editForm).then(() => {
        this.editDialogVisible = false;
        this.getList(); // 更新数据列表
      }).catch(error => {
        console.error('更新信息失败', error);
      });
    },
    handleAdd() {
      addTutorial(this.addForm).then(() => {
        this.addDialogVisible = false;
        this.getList(); // 更新数据列表
      }).catch(error => {
        console.error('新增信息失败', error);
      });
    },
    handleDelete(id) {
      this.$confirm('确定要删除该教程吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteTutorialById({ id }).then(() => {
          this.getList(); // 更新数据列表
        }).catch(error => {
          console.error('删除信息失败', error);
        });
      }).catch(() => {
        // 用户取消操作
        this.$message.info('已取消删除');
      });
    },
    handleViews(row) {
      this.viewsQueryParams.tutorial_id = row.id;
      this.viewsQueryParams.pageNum = 1;
      this.viewsQueryParams.keyword = '';
      this.getViewsList();
      this.viewsDialogVisible = true;
    },
    handleViewsSearch() {
      this.viewsQueryParams.pageNum = 1;
      this.getViewsList();
    },
    getViewsList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: this.$store.state.isDark ? 'rgba(30, 30, 30, 0.7)' : 'rgba(255, 255, 255, 0.7)'
      });
      getAllTutorialViews(this.viewsQueryParams).then(res => {
        res.list.forEach(item => {
          item.created_at = formatDate(item.created_at);
        });
        this.viewsTableData = res.list;
        this.viewsTotal = res.total;
      }).finally(() => {
        loading.close();
      });
    },
    handleViewsSizeChange(newSize) {
      this.viewsQueryParams.pageSize = newSize;
      this.getViewsList();
    },
    handleViewsCurrentChange(newPage) {
      this.viewsQueryParams.pageNum = newPage;
      this.getViewsList();
    }
  }
};
</script>

<style scoped lang="scss">
/* 整体容器样式 */
.user-management {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-secondary);
    box-shadow: 0 0 10px var(--shadow-color);
  }

  ::v-deep {
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
      margin-bottom: 2px;
    }
    .el-form-item {
      margin-bottom: 2px;
    }
    .el-form--label-top .el-form-item__label {
      float: none;
      display: inline-block;
      text-align: left;
      padding: 0 0 0px;

      /* 适配黑夜模式 */
      .dark-mode & {
        color: var(--text-primary);
      }
    }
    .el-dialog__body {
      padding: 0.14286rem 1.42857rem;
      color: #606266;
      font-size: 1rem;
      word-break: break-all;

      /* 适配黑夜模式 */
      .dark-mode & {
        color: var(--text-primary);
        background-color: var(--bg-primary);
      }
    }

    /* 黑夜模式下对话框样式 */
    .dark-mode & {
      .el-dialog {
        background-color: var(--bg-primary);

        .el-dialog__header {
          background-color: var(--bg-secondary);
          color: var(--text-primary);

          .el-dialog__title {
            color: var(--text-primary);
          }

          .el-dialog__headerbtn .el-dialog__close {
            color: var(--text-secondary);
          }
        }

        .el-dialog__footer {
          background-color: var(--bg-secondary);
        }
      }

      .el-button {
        background-color: var(--bg-secondary);
        border-color: var(--border-color);
        color: var(--text-primary);

        &:hover {
          border-color: var(--accent-color);
          color: var(--accent-color);
        }

        &--primary {
          background-color: var(--accent-color);
          border-color: var(--accent-color);
          color: #ffffff;

          &:hover {
            background-color: var(--accent-hover);
            border-color: var(--accent-hover);
          }
        }
      }

      .el-input__inner, .el-textarea__inner {
        background-color: var(--bg-secondary);
        border-color: var(--border-color);
        color: var(--text-primary);
      }
    }

    .el-table th.el-table__cell {
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      background-color: #f2F2F2;
      color: #000000;

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-secondary);
        color: var(--text-primary);
      }
    }

    /* 适配黑夜模式 - 表格样式 */
    .dark-mode & {
      .el-table {
        --el-table-bg-color: var(--bg-primary) !important;
        --el-table-tr-bg-color: var(--bg-primary) !important;
        --el-table-border-color: var(--border-color) !important;
        --el-table-text-color: var(--text-primary) !important;
        --el-table-header-bg-color: var(--bg-secondary) !important;
        --el-table-header-text-color: var(--text-primary) !important;

        .el-table__body-wrapper, .el-table__header-wrapper {
          background-color: var(--bg-primary);
        }

        .el-table__body tr:hover > td.el-table__cell {
          background-color: var(--hover-bg) !important;
        }
      }
    }
  }
}

/* 标题样式 */
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* 搜索和添加按钮区域样式 */
.search-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* 允许换行 */

  .search {
    position: relative;
    width: 60%;
    min-width: 200px; /* 设置最小宽度 */

    input {
      width: 100%;
      padding: 10px 30px 10px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-primary);
        border-color: var(--border-color);
        color: var(--text-primary);

        &:focus {
          border-color: var(--accent-color);
          box-shadow: 0 0 0 2px var(--accent-shadow);
        }
      }
    }

    i {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: #999;

      /* 适配黑夜模式 */
      .dark-mode & {
        color: var(--text-secondary);
      }
    }
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }

    i {
      margin-right: 5px;
    }

    /* 适配黑夜模式 */
    .dark-mode & {
      background-color: var(--accent-color);

      &:hover {
        background-color: var(--accent-hover);
      }
    }
  }
}

/* 表格容器样式 */
.table-container {
  width: 100%;
  /* 允许水平滚动 */
  overflow-x: auto;
  /* 移除不必要的高度限制 */
  height: auto;

  /* 调整 ElementUI 表格样式，使其与原样式一致 */
  .el-table {
    /* 让表格宽度由内容决定 */
    width: 100%;
    border-collapse: collapse;
    /* 移除不必要的滚动设置 */
    overflow-y: auto;
    overflow-x: auto;

    .el-table__header th,
    .el-table__body td {
      padding: 12px;
      border-bottom: 1px solid #ddd;
      text-align: left;
    }

    .el-table__header th {
      background-color: #ffffff;
      font-weight: 600;
      color: #000000;
    }

    .el-table__body tr:hover {
      background-color: #f5f5f5;
    }

    button {
      padding: 6px 12px;
      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }

      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  /* 分页组件样式 */
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    /* 添加小屏幕下的样式调整 */
    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

/* 用户信息样式 */
.user-info {
  display: flex;
  align-items: center;

  img {
    width: 60px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
  }
}

/* 编辑弹窗样式 */
.el-dialog .el-form {
  display: flex;
  flex-wrap: wrap;
  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .el-form-item {
      width: 48%;
    }
  }
}
.search {
  margin-bottom: 10px;
  input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;

    /* 适配黑夜模式 */
    .dark-mode & {
      background-color: var(--bg-primary);
      border-color: var(--border-color);
      color: var(--text-primary);
    }
  }
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }

    /* 适配黑夜模式 */
    .dark-mode & {
      background-color: var(--accent-color);

      &:hover {
        background-color: var(--accent-hover);
      }
    }
  }
}


/* 弹窗内分页组件样式 */
.views-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;

  /* 手机端样式适配 */
  @media (max-width: 768px) {
    .el-pagination__total {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }
    .el-pagination__sizes {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }
    .el-pagination__jump {
      width: 100%;
      text-align: center;
      margin-top: 5px;
    }
    .el-pagination__pager {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
