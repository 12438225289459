<template>
  <div class="email-input">
    <el-input size="mini" v-model="value1"  @input="emailChange"/>
    <el-select size="mini" v-model="value2" placeholder="请选择" @change="emailChange">
      <el-option
        v-for="item in emailOptions"
        :key="item.value"
        :label="item.value"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'EmailInput',
  data() {
    return {
      value1: '',
      value2: '@qq.com',
      emailOptions: [
        // 国内主流
        { value: '@qq.com'},
        { value: '@163.com'},
        { value: '@126.com'},
        { value: '@sina.com'},
        { value: '@sohu.com'},
        { value: '@139.com'},
        // 国际常用
        { value: '@gmail.com'},
        { value: '@outlook.com'},
        { value: '@hotmail.com'},
        { value: '@yahoo.com'},
        // 企业邮箱
        { value: '@aliyun.com'},
        { value: '@foxmail.com'},
        // 特殊需求
        { value: '@icloud.com'},
        { value: '@protonmail.com'},
        { value: '@mail.ru'},
        { value: '@web.de'}
      ]
    }
  },
  watch: {
    value1() {
      this.emailChange()
    },
    value2() {
      this.emailChange()
    }
  },
  created() {

  },
  methods: {
    resetValue() {
      this.value1 = ''
    },
    emailChange() {
      // 添加 trim 处理空格
      const formattedEmail = `${this.value1.trim()}${this.value2}`
      this.$emit('change', formattedEmail)
    }
  }
}
</script>

<style lang="scss" scoped>
.email-input {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 3fr 2fr;
}
</style>