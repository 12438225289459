import request from '@/utils/request'

// 获取所有教程
export function getAllTutorials (params) {
  return request({
    url: '/getAllTutorials',
    method: 'get',
    params
  })
}

// 新增教程
export function addTutorial (data) {
  return request({
    url: '/addTutorial',
    method: 'post',
    data
  })
}

// 修改教程
export function updateTutorialById (data) {
  return request({
    url: '/updateTutorialById',
    method: 'put',
    data
  })
}

// 删除教程
export function deleteTutorialById (params) {
  return request({
    url: '/deleteTutorialById',
    method: 'delete',
    params
  })
}

// 浏览量+1
export function incrementViews (data) {
  return request({
    url: '/incrementViews',
    method: 'post',
    data
  })
}


// 获取教程浏览记录
export function getAllTutorialViews (params) {
  return request({
    url: '/getAllTutorialViews',
    method: 'get',
    params
  })
}