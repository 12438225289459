<template>
  <div class="project">
    project
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ProjectView',
  components: {
  }
}
</script>
<style lang="scss" scoped>
.project {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 5px;
}
</style>

