import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ifPC: JSON.parse(localStorage.getItem('ifPC')) || false,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    showLogin: false,
    theme: localStorage.getItem('theme') || 'light',
    userThemePreference: JSON.parse(localStorage.getItem('userThemePreference')) || false,
    autoTheme: JSON.parse(localStorage.getItem('autoTheme')) || true
  },
  getters: {
    isDarkMode: state => state.theme === 'dark'
  },
  mutations: {
    SET_IF_PC(state, value) {
      state.ifPC = value;
      localStorage.setItem('ifPC', JSON.stringify(value));
    },
    SET_USER_INFO(state, value) {
      state.userInfo = value;
      localStorage.setItem('userInfo', JSON.stringify(value));
    },
    SET_SHOW_LOGIN(state, value) {
      state.showLogin = value;
    },
    SET_THEME(state, value) {
      state.theme = value;
      localStorage.setItem('theme', value);
      // 设置用户主题偏好为真
      state.userThemePreference = true;
      localStorage.setItem('userThemePreference', JSON.stringify(true));

      // 应用主题
      this.commit('APPLY_THEME', value);
    },
    SET_THEME_WITHOUT_PREFERENCE(state, value) {
      state.theme = value;
      localStorage.setItem('theme', value);

      // 应用主题
      this.commit('APPLY_THEME', value);
    },
    SET_AUTO_THEME(state, value) {
      state.autoTheme = value;
      localStorage.setItem('autoTheme', JSON.stringify(value));

      if (value) {
        // 启用自动主题时，立即根据当前时间设置主题
        this.commit('UPDATE_THEME_BY_TIME');
      }
    },
    UPDATE_THEME_BY_TIME(state) {
      if (!state.autoTheme) return;

      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // 计算当前时间的分钟数（从0点开始）
      const totalMinutes = hours * 60 + minutes;

      // 早上6:30到晚上18:30是白天模式
      const morningTime = 6 * 60 + 30; // 6:30
      const eveningTime = 18 * 60 + 30; // 18:30

      const newTheme = (totalMinutes >= morningTime && totalMinutes < eveningTime) ? 'light' : 'dark';

      // 只在主题需要变更时才应用新主题
      if (state.theme !== newTheme) {
        // 使用自定义事件触发主题变化
        document.dispatchEvent(new CustomEvent('theme-change', {
          detail: { theme: newTheme }
        }));

        // 设置主题状态，但不触发APPLY_THEME（避免重复动画）
        state.theme = newTheme;
        localStorage.setItem('theme', newTheme);

        // 延迟应用主题样式，等待动画完成
        setTimeout(() => {
          // 添加/移除 dark 类到 html 元素
          if (newTheme === 'dark') {
            document.documentElement.classList.add('dark-mode');
          } else {
            document.documentElement.classList.remove('dark-mode');
          }

          // 强制重绘body元素以触发CSS更新
          document.body.style.display = 'none';
          void document.body.offsetHeight; // 强制浏览器重绘
          document.body.style.display = '';

          // 应用布局修复
          setTimeout(() => {
            this.commit('FIX_LAYOUT_AFTER_THEME_CHANGE');
          }, 0);

          setTimeout(() => {
            this.commit('FIX_LAYOUT_AFTER_THEME_CHANGE');
          }, 50);

          setTimeout(() => {
            this.commit('FIX_LAYOUT_AFTER_THEME_CHANGE');
          }, 200);
        }, 3100); // 延迟与动画时长一致
      }
    },
    RESET_USER_THEME_PREFERENCE(state) {
      state.userThemePreference = false;
      localStorage.removeItem('userThemePreference');
    },
    APPLY_THEME(state, value) {
      // 在切换主题前清除可能存在的样式
      this.commit('CLEANUP_BEFORE_THEME_CHANGE');

      // 先触发主题变化事件，启动动画
      document.dispatchEvent(new CustomEvent('theme-change', {
        detail: { theme: value }
      }));

      // 延迟应用主题变化，等待动画完成
      setTimeout(() => {
        // 添加/移除 dark 类到 html 元素
        if (value === 'dark') {
          document.documentElement.classList.add('dark-mode');
        } else {
          document.documentElement.classList.remove('dark-mode');
        }

        // 强制重绘body元素以触发CSS更新
        document.body.style.display = 'none';
        void document.body.offsetHeight; // 强制浏览器重绘
        document.body.style.display = '';

        // 在较短的延迟后应用强制修复
        setTimeout(() => {
          this.commit('FIX_LAYOUT_AFTER_THEME_CHANGE');
        }, 0);

        // 再次延迟修复，确保在DOM完全更新后执行
        setTimeout(() => {
          this.commit('FIX_LAYOUT_AFTER_THEME_CHANGE');
        }, 50);

        // 第三次延迟修复，捕获可能的异步更新
        setTimeout(() => {
          this.commit('FIX_LAYOUT_AFTER_THEME_CHANGE');
        }, 200);
      }, 3100); // 延长等待时间，匹配更慢的太阳/月亮动画时长
    },
    // 清理主题切换前的样式
    CLEANUP_BEFORE_THEME_CHANGE() {
      // 清除可能导致高度问题的样式
      const elements = [
        '.head-tab',
        '.tab-bar',
        '.tab-bar-inner',
        '.el-menu--horizontal',
        '.el-menu-item'
      ];

      elements.forEach(selector => {
        const el = document.querySelector(selector);
        if (el) {
          // 移除内联样式中可能影响高度的属性
          el.style.removeProperty('border-bottom');
          el.style.removeProperty('border-top');
          el.style.removeProperty('margin-top');
          el.style.removeProperty('margin-bottom');
          el.style.removeProperty('padding-top');
          el.style.removeProperty('padding-bottom');
        }
      });
    },
    // 修复主题切换后的布局
    FIX_LAYOUT_AFTER_THEME_CHANGE() {
      // 检查是否为全屏模式
      const isScreenMode = document.querySelector('.router-content.screen-content') !== null;

      // 应用强制高度到关键元素
      const fixes = [
        { selector: 'body', style: { overflowY: 'hidden' } },
        { selector: 'html', style: { overflowY: 'hidden' } },
        { selector: '.head-tab', style: { height: '50px', maxHeight: '50px', border: 'none', padding: '0' } },
        { selector: '.tab-bar', style: { height: '50px', maxHeight: '50px', overflowX: 'auto', overflowY: 'hidden', border: 'none' } },
        { selector: '.tab-bar-inner', style: { maxHeight: '50px', overflowX: 'visible', overflowY: 'hidden' } },
        { selector: '.el-menu--horizontal', style: { height: '50px', maxHeight: '50px', border: 'none', paddingLeft: '140px' } },
        // 内容区域特别处理
        {
          selector: '.content',
          style: {
            height: isScreenMode ? '100vh' : 'calc(100vh - 50px)',
            maxHeight: isScreenMode ? '100vh' : 'calc(100vh - 50px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            padding: '0',
            margin: '0',
            boxSizing: 'border-box',
            position: isScreenMode ? 'absolute' : '',
            left: isScreenMode ? '0' : '',
            right: isScreenMode ? '0' : '',
            top: isScreenMode ? '0' : '',
            bottom: isScreenMode ? '0' : ''
          }
        }
      ];

      fixes.forEach(({ selector, style }) => {
        const el = document.querySelector(selector);
        if (el) {
          Object.entries(style).forEach(([prop, value]) => {
            if (value !== '') {
              el.style[prop] = value;
            }
          });
        }
      });

      // 路由内容区域特别处理 - 需要检查是否为全屏模式
      const routerContent = document.querySelector('.router-content');
      if (routerContent) {
        const isScreenContent = routerContent.classList.contains('screen-content');

        // 在全屏模式下应用额外样式
        if (isScreenContent) {
          Object.assign(routerContent.style, {
            boxSizing: 'border-box',
            maxWidth: '100%',
            width: '100vw',
            height: '100vh',
            minHeight: '100vh',
            maxHeight: '100vh',
            margin: '0',
            padding: '0',
            border: 'none',
            borderRadius: '0',
            overflowY: 'auto',
            overflowX: 'hidden',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            display: 'block'
          });
        } else {
          Object.assign(routerContent.style, {
            boxSizing: 'border-box',
            maxWidth: 'calc(100% - 20px)',
            margin: '10px 0',
            border: 'none',
            borderRadius: '5px',
            overflowY: 'auto',
            overflowX: 'hidden'
          });
        }
      }

      // 特别处理Element UI菜单项
      const menuItems = document.querySelectorAll('.el-menu-item');
      menuItems.forEach(item => {
        Object.assign(item.style, {
          height: '48px',
          maxHeight: '48px',
          lineHeight: '48px',
          boxSizing: 'border-box',
          border: 'none'
        });
      });

      // 确保移动端菜单项样式正确
      const tabBarItems = document.querySelectorAll('.tab-bar-item');
      tabBarItems.forEach(item => {
        Object.assign(item.style, {
          height: '45px',
          maxHeight: '45px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 8px',
          margin: '0 3px',
          flexShrink: '0'
        });
      });

      // 确保logo样式正确
      const logo = document.querySelector('.logo');
      if (logo) {
        Object.assign(logo.style, {
          position: 'absolute',
          left: '20px',
          zIndex: '10'
        });
      }

      // 添加自定义滚动条样式
      this.commit('ADD_CUSTOM_SCROLLBAR_STYLE');

      // 全屏模式下添加额外样式
      if (isScreenMode) {
        this.commit('ADD_SCREEN_MODE_STYLE');
      }
    },
    // 添加自定义滚动条样式
    ADD_CUSTOM_SCROLLBAR_STYLE() {
      const isDarkMode = document.documentElement.classList.contains('dark-mode');

      let styleEl = document.getElementById('custom-scrollbar-style');
      if (!styleEl) {
        styleEl = document.createElement('style');
        styleEl.id = 'custom-scrollbar-style';
        document.head.appendChild(styleEl);
      }

      if (isDarkMode) {
        styleEl.textContent = `
          /* 隐藏所有滚动条 */
          ::-webkit-scrollbar {
            display: none !important;
            width: 0 !important;
            height: 0 !important;
          }
          * {
            scrollbar-width: none !important;
            -ms-overflow-style: none !important;
          }
          .content, .router-content, body, html {
            scrollbar-width: none !important;
            -ms-overflow-style: none !important;
          }
          .content::-webkit-scrollbar,
          .router-content::-webkit-scrollbar,
          body::-webkit-scrollbar,
          html::-webkit-scrollbar {
            display: none !important;
            width: 0 !important;
            height: 0 !important;
          }
          .router-content {
            border: none !important;
            box-shadow: 0 1px 8px var(--shadow-color) !important;
            box-sizing: border-box !important;
            margin: 10px 0 !important;
            max-width: calc(100% - 20px) !important;
          }
        `;
      } else {
        // 恢复滚动条正常显示
        styleEl.textContent = '';
      }
    },
    // 添加全屏模式样式
    ADD_SCREEN_MODE_STYLE() {
      let styleEl = document.getElementById('screen-mode-style');
      if (!styleEl) {
        styleEl = document.createElement('style');
        styleEl.id = 'screen-mode-style';
        document.head.appendChild(styleEl);
      }

      styleEl.textContent = `
        .screen-content {
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          width: 100vw !important;
          height: 100vh !important;
          max-height: 100vh !important;
          min-height: 100vh !important;
          margin: 0 !important;
          padding: 0 !important;
          box-sizing: border-box !important;
          display: block !important;
        }

        .router-content.screen-content {
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          width: 100vw !important;
          height: 100vh !important;
          max-height: 100vh !important;
          min-height: 100vh !important;
          max-width: 100% !important;
          margin: 0 !important;
          padding: 0 !important;
          border-radius: 0 !important;
          overflow-y: auto !important;
          overflow-x: hidden !important;
          display: block !important;
        }
      `;

      // 强制重新计算布局
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 50);
    },
    SET_THEME_WITH_ANIMATION(state, value) {
      state.theme = value;
      localStorage.setItem('theme', value);

      // 不改变用户主题偏好设置

      // 应用主题（带动画）
      this.commit('APPLY_THEME', value);
    }
  },
  actions: {
    updateIfPC({ commit }, value) {
      commit('SET_IF_PC', value);
    },
    updateUserInfo({ commit }, value) {
      commit('SET_USER_INFO', value);
    },
    updateShowLogin({ commit }, value) {
      commit('SET_SHOW_LOGIN', value);
    },
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      commit('SET_THEME', newTheme);
    },
    resetThemePreference({ commit }) {
      commit('RESET_USER_THEME_PREFERENCE');
      // 重置后跟随系统主题
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const theme = prefersDark ? 'dark' : 'light';
      commit('SET_THEME_WITHOUT_PREFERENCE', theme);
    },
    toggleAutoTheme({ commit, state }) {
      commit('SET_AUTO_THEME', !state.autoTheme);
    },
    initAutoThemeTimer({ commit }) {
      // 设置每分钟检查一次时间
      setInterval(() => {
        commit('UPDATE_THEME_BY_TIME');
      }, 60000); // 每分钟检查一次
    }
  },
  modules: {
  }
})
