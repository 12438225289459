<template>
  <div class="course-info">
    <!-- <h2>题库信息</h2> -->
    <div class="search-add">
      <div class="search">
        <input type="text" v-model="queryParams.belong" placeholder="请输入题库名称...">
        <i class="fas fa-search"></i>
      </div>
      <div>
        <button @click="handleSearch">
          <i class="fas fa-search"></i>查询
        </button>
      </div>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        max-height="450"
      >
        <el-table-column prop="belong" label="名称" min-width="350" show-overflow-tooltip>
          <template #default="scope">
            <div class="user-info">
              <button v-if="!(scope.row.expand_id || scope.row.expand_id === 0)" @click="handleExpand(scope.row, scope.$index)">
                <i v-if="scope.row.expand" class="fa-solid fa-angle-up"></i>
                <i v-else class="fa-solid fa-angle-down"></i>
              </button>
              <img v-if="!(scope.row.expand_id || scope.row.expand_id === 0)" :src="'https://www.sweek.top/api/preview/logo-question.png'" class="avatar" alt="用户头像">
              <i v-if="scope.row.expand_id || scope.row.expand_id === 0" style="margin-left: 45px;" class="bi bi-file-earmark-code"></i>
              <span>{{ scope.row.belong || '-' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数量" min-width="240">
          <template #default="scope">
            <span @click="handleExpand(scope.row, scope.$index)" v-if="!(scope.row.expand_id || scope.row.expand_id === 0)" style="color: dodgerblue;cursor: pointer;">{{ scope.row.total_belong_nums || '-' }}</span>
            <span v-else>{{ scope.row.total_belong_nums || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <button v-if="scope.row.expand_id || scope.row.expand_id === 0" @click="handleDetail(scope.row)">
              <i class="fa-solid fa-grip"></i>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[5, 10, 20, 30]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getBelong } from '@/api/bank.js';

export default {
  data() {
    return {
      total: 0,
      queryParams: {
        pageSize: 5,
        pageNum: 1,
        belong: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleDetail(row) {
      console.log('row：：：+ ', row)
    },
    handleExpand(row, index) {
      row.expand = !row.expand;
      if (row.expand) {
        // 展开，插入子项
        const expandList = row.question_types.map(item => {
          return {
            belong: item.question_type,
            total_belong_nums: item.nums,
            expand_id: index,
            expand: row.expand
          };
        });
        this.tableData.splice(index + 1, 0, ...expandList);
      } else {
        // 收起，移除子项
        this.tableData = this.tableData.filter(item => item.expand_id !== index);
      }
      // console.log('tableData：：：+ ', this.tableData)
    },
    handleSearch() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    getList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: this.$store.state.isDark ? 'rgba(30, 30, 30, 0.7)' : 'rgba(255, 255, 255, 0.7)'
      });
      getBelong(this.queryParams).then(res => {
        res.data.results.forEach(item => {
          item.expand = false
        });
        this.tableData = res.data.results
        this.total = res.data.total;
      }).finally(() => {
        loading.close();
      });
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNum = newPage;
      this.getList();
    }
  }
};
</script>

<style scoped lang="scss">
/* 整体容器样式 */
.course-info {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-secondary);
    box-shadow: 0 0 10px var(--shadow-color);
  }

  ::v-deep {
    .el-table th.el-table__cell {
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      background-color: #f2F2F2;
      color: #000000;

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-secondary);
        color: var(--text-primary);
      }
    }

    /* 适配黑夜模式 - 表格样式 */
    .dark-mode & {
      .el-table {
        --el-table-bg-color: var(--bg-primary) !important;
        --el-table-tr-bg-color: var(--bg-primary) !important;
        --el-table-border-color: var(--border-color) !important;
        --el-table-text-color: var(--text-primary) !important;
        --el-table-header-bg-color: var(--bg-secondary) !important;
        --el-table-header-text-color: var(--text-primary) !important;

        .el-table__body-wrapper, .el-table__header-wrapper {
          background-color: var(--bg-primary);
        }

        .el-table__body tr:hover > td.el-table__cell {
          background-color: var(--hover-bg) !important;
        }
      }
    }
  }
}

/* 标题样式 */
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* 搜索和添加按钮区域样式 */
.search-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* 允许换行 */

  .search {
    position: relative;
    width: 60%;
    min-width: 200px; /* 设置最小宽度 */
    margin-bottom: 10px;

    input {
      width: 100%;
      padding: 10px 30px 10px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }

      /* 适配黑夜模式 */
      .dark-mode & {
        background-color: var(--bg-primary);
        border-color: var(--border-color);
        color: var(--text-primary);

        &:focus {
          border-color: var(--accent-color);
          box-shadow: 0 0 0 2px var(--accent-shadow);
        }
      }
    }

    i {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: #999;

      /* 适配黑夜模式 */
      .dark-mode & {
        color: var(--text-secondary);
      }
    }
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }

    i {
      margin-right: 5px;
    }

    /* 适配黑夜模式 */
    .dark-mode & {
      background-color: var(--accent-color);

      &:hover {
        background-color: var(--accent-hover);
      }
    }
  }
}

/* 表格容器样式 */
.table-container {
  width: 100%;
  /* 允许水平滚动 */
  overflow-x: auto;
  /* 移除不必要的高度限制 */
  height: auto;

  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-top: 20px;

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-primary);
    box-shadow: 0 2px 4px var(--shadow-color);
  }

  /* 调整 ElementUI 表格样式，使其与原样式一致 */
  .el-table {
    /* 让表格宽度由内容决定 */
    width: 100%;
    border-collapse: collapse;
    /* 移除不必要的滚动设置 */
    overflow-y: auto;
    overflow-x: auto;

    .el-table__header th,
    .el-table__body td {
      padding: 12px;
      border-bottom: 1px solid #ddd;
      text-align: left;
    }

    .el-table__header th {
      background-color: #ffffff;
      font-weight: 600;
      color: #000000;
    }

    .el-table__body tr:hover {
      background-color: #f5f5f5;
    }

    button {
      padding: 6px 12px;
      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }

      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  /* 分页组件样式 */
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    /* 添加小屏幕下的样式调整 */
    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  /* 添加黑夜模式下的分页组件样式 */
  .dark-mode & {
    .el-pagination {
      button {
        background-color: var(--bg-secondary);
        color: var(--text-primary);

        &:disabled {
          color: var(--text-disabled);
        }
      }

      .el-pager li {
        background-color: var(--bg-secondary);
        color: var(--text-primary);

        &.active {
          color: var(--accent-color);
        }

        &:hover {
          color: var(--accent-hover);
        }
      }

      .el-pagination__total,
      .el-pagination__jump,
      .el-pagination__sizes {
        color: var(--text-primary);
      }

      .el-select .el-input .el-input__inner {
        color: var(--text-primary);
      }
    }
  }
}

/* 用户信息样式 */
.user-info {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
  }
}

/* 编辑弹窗样式 */
.el-dialog .el-form {
  display: flex;
  flex-wrap: wrap;
  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
    .el-form-item {
      width: 48%;
    }
  }
}
</style>
