import request from '@/utils/request'

// 获取课程信息根据邮箱
export function getCoursesByEmail (params) {
  return request({
    url: '/getCoursesByNameAndEmail',
    method: 'get',
    params
  })
}
