<template>
  <div class="aichat" :style="{ 'background-color' : ifPC ? (isDarkMode ? 'var(--bg-secondary)' : '#f9f9fa') : (isDarkMode ? 'var(--bg-secondary)' : '#f5f5f5')}">
    <div class="chat-container">
      <!-- 左侧会话列表 -->
      <div v-if="ifPC" class="left-content">
        <div class="left-header">
          <div class="add" @click="addItem()">
            <i class="el-icon-plus"></i>
            <span>新增会话</span>
          </div>
        </div>
        <div class="conversations-list">
          <div
            v-for="(item, index) in itemList"
            :key="index"
            class="item"
            :class="{'item-active': item.value == selectedItem}"
            @click="selectItem(item)"
          >
            <i class="el-icon-chat-dot-square"></i>
            <span class="item-label">{{ item.label }}</span>
            <span @click.stop="handleDelete(item)" class="item-delete">
              <i class="el-icon-delete"></i>
            </span>
          </div>
        </div>
      </div>

      <!-- 右侧聊天区域 -->
      <div class="right-content">
        <!-- 聊天标题 -->
        <div class="right-content-title" :class="{'dark-title': isDarkMode}">
          <span v-if="!ifPC" @click="handleShowHh" class="menu-icon"><i class="el-icon-s-fold"></i></span>
          <span class="chat-title">{{ selectedTitle }}</span>
        </div>

        <!-- 聊天消息区域 -->
        <div ref="messageContainer" class="right-content-content" :class="{'dark-content': isDarkMode}">
          <div v-for="(message, index) in messages" :key="index" class="message-wrapper" :class="{'fade-in': index === messages.length - 1}">
            <!-- 时间模块 -->
            <div class="message-top" :style="{ 'justify-content': message.isMe ? 'flex-end' : 'flex-start' }">
              <span>{{ message.date }}</span>
            </div>

            <!-- 提问模块 -->
            <div v-if="message.isMe" class="user-message">
              <div class="user-message-content">{{ message.text }}</div>
              <div class="user-avatar">
                <i class="el-icon-user-solid"></i>
              </div>
            </div>

            <!-- 回答模块 -->
            <div v-else class="assistant-message-wrapper">
              <div class="ai-avatar">
                <i class="el-icon-s-opportunity"></i>
              </div>
              <div class="assistant-message" :class="{'error-message': message.isError}">
                <vue-markdown style="overflow-wrap: break-word;width: 100%;" :source="message.text || ' '" v-highlight v-copy>
                </vue-markdown>
                <div v-if="message.text === '' && !message.isError" class="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div v-if="message.text && !message.isError" class="message-bottom" :style="{ 'justify-content': message.isMe ? 'flex-end' : 'flex-start' }">
                  <div class="message-btn" @click="copyToClipboard(message.text)">
                    <i class="el-icon-document-copy"></i>
                    <span>复制</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <el-empty v-if="selectedItem=='000000'" class="empty-state" description="暂无会话">
            <div class="empty-description">点击下方按钮开始一段新的对话</div>
            <div class="add-btn" @click="addItem()">新增会话</div>
          </el-empty>
        </div>

        <!-- 底部提问模块 -->
        <div class="bottom">
          <div class="input-container">
            <div class="input-mode-buttons">
              <div 
                class="mode-button" 
                :class="{'active': inputMode === 'text'}"
                @click="setInputMode('text')"
                :disabled="selectedItem=='000000' || loading"
              >
                <i class="el-icon-chat-line-square"></i>
                <span>文本模式</span>
              </div>
              <div 
                class="mode-button" 
                :class="{'active': inputMode === 'genImage'}"
                @click="setInputMode('genImage')"
                :disabled="selectedItem=='000000' || loading"
              >
                <i class="el-icon-picture-outline"></i>
                <span>图片生成</span>
              </div>
              <div 
                class="mode-button" 
                :class="{'active': inputMode === 'recImage'}"
                @click="setInputMode('recImage')"
                :disabled="selectedItem=='000000' || loading"
              >
                <i class="el-icon-camera"></i>
                <span>图片识别</span>
              </div>
            </div>
            <div v-if="inputMode === 'recImage'" class="image-upload-container">
              <div class="image-wrapper">
                <el-upload
                  class="image-uploader"
                  action="#"
                  :show-file-list="false"
                  :before-upload="beforeImageUpload"
                  :disabled="selectedItem=='000000' || loading"
                >
                  <div v-if="!imageUrl" class="upload-placeholder">
                      <i class="el-icon-plus icon-center"></i>
                  </div>
                  <div v-else class="image-preview-wrapper">
                    <img :src="imageUrl" class="preview-image">
                    <div class="image-delete-icon" @click.stop="removeImage">
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </el-upload>
              </div>
            </div>
            <el-input
              v-model="text"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4 }"
              resize="none"
              :disabled="selectedItem=='000000' || loading"
              :placeholder="getPlaceholderText()"
              @keyup.enter.native="sendMessage"
            />
            <div class="button-group">
              <el-button :disabled="selectedItem=='000000' || (!text.trim() && (inputMode === 'recImage' ? !imageFile : true))" v-if="!loading" class="send-button" @click="sendMessage">
                <i :class="getSendButtonIcon()"></i>
              </el-button>
              <el-button v-else class="stop-button" @click="stopMessage">
                <i class="el-icon-video-pause"></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 会话抽屉 -->
    <el-drawer
      title="会话列表"
      size="90%"
      custom-class="chat-drawer"
      :visible.sync="hhVisible"
      :modal="false"
      :append-to-body="true"
      direction="ltr">
      <div class="hh-content">
        <div
          class="add"
          @click="addItemFromDrawer"
        >
          <i class="el-icon-plus"></i>
          <span>新增会话</span>
        </div>
        <div
          v-for="(item, index) in itemList"
          :key="index"
          class="item"
          :class="{'item-active': item.value == selectedItem}"
          @click="selectItemFromDrawer(item)"
        >
          <i class="el-icon-chat-dot-square"></i>
          <span class="item-label">{{ item.label }}</span>
          <span @click.stop="handleDelete(item)" class="item-delete">
            <i class="el-icon-delete"></i>
          </span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { saveModelText } from '@/api/model.js'
import Clipboard from "clipboard";
export default {
  name: 'AiChat',
  components: {
  },
  data() {
    return {
      loading: false,
      abort: new AbortController(),
      hhVisible: false,
      text: '',
      selectedTitle: '',
      selectedItem: '000000',
      buttonDisable: false,
      messages: [],
      allMessages: [],
      itemList: [],
      imageMode: false,
      imageUrl: '',
      imageFile: null,
      inputMode: 'text'
    }
  },
  directives: {
    copy: {
      inserted(el) {
        // console.log('触发：：：+ ', el)
        function getLanguage(className) {
          // 从 className 中提取语言名称
          return className.replace(/language-|hljs/g, '') || 'unknown';
        }
        // 选择所有 <pre> 元素
        let blocks = el.querySelectorAll("pre");
        blocks.forEach(block => {
          let code = block.querySelector("code");
          if (code) {
            let div = document.createElement("div");
            let span = document.createElement("span");
            span.innerText = getLanguage(code.className); // 使用 innerText 性能更佳
            div.appendChild(span);
            div.classList.add("btn-copy");

            // 设置 div 的样式
            div.style.backgroundColor = "#2b2b2b";
            div.style.color = "white";
            // div.style.borderBottom = "1px solid #cfcfcf";
            div.style.padding = "5px 5px 5px 16px";

            let button = document.createElement("button");
            button.setAttribute("data-clipboard-text", code.innerText);
            button.innerText = "复制";
            div.appendChild(button);

            // 设置按钮的样式
            button.style.backgroundColor = "#333";
            button.style.color = "white";
            button.style.border = "none";
            button.style.padding = "5px 10px";
            button.style.cursor = "pointer";
            button.style.float = "right";

            // 点击事件处理程序
            button.addEventListener("click", () => {
              // 复制文本到剪贴板
              navigator.clipboard.writeText(button.getAttribute("data-clipboard-text")).then(() => {
                // 更改按钮文本为 "复制成功"
                button.innerText = "复制成功";

                // 3秒后恢复按钮文本为 "复制"
                setTimeout(() => {
                  button.innerText = "复制";
                }, 1500);
              }).catch(err => {
                console.error('复制失败:', err);
              });
            });
            block.parentNode.insertBefore(div, block);
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['ifPC']),
    userInfo() {
      return this.$store.state.userInfo || null
    },
    isDarkMode() {
      return document.documentElement.classList.contains('dark-mode')
    }
  },
  created() {
    // 在 created 钩子函数中定义全局变量 clipboard
    let clipboard = new Clipboard(".copy-btn");
    clipboard.on("success", e => {
      // this.$message.success("复制成功")
      e.clearSelection();
    });
    clipboard.on("error", () => {
      // this.$message.error("复制失败")
    });
    this.setLocalStorage()
  },
  mounted() {
    this.toBottom()
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.toBottom()
      })
    }
  },
  methods: {
    handleShowHh() {
      this.hhVisible = true
    },
    setLocalStorage() {
      const selectedItem = localStorage.getItem('selected-item')
      const modelTitle = JSON.parse(localStorage.getItem('model-title'))
      const model = JSON.parse(localStorage.getItem('model'))
      if (selectedItem) {
        this.selectedItem = selectedItem
        const text = model.find(item => item.titleId == selectedItem)?.text
        if (text.length > 10) {
          this.selectedTitle = text.slice(0, 10) + '...'
        } else {
          this.selectedTitle = text
        }
      }
      if (modelTitle) {
        this.itemList = modelTitle
      }
      if (model) {
        this.allMessages = model
        this.messages = model.filter(item => item.titleId === this.selectedItem)
      }
    },
    // 随机生成12位字符串
    generateRandomString () {
      var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
      var randomString = ''
      for (var i = 0; i < 12; i++) {
        var randomIndex = Math.floor(Math.random() * chars.length)
        randomString += chars[randomIndex]
      }
      return randomString
    },
    // 滚动到底部
    toBottom() {
      const messageContainer = this.$refs.messageContainer
      if (!messageContainer) {
        console.error('messageContainer not found')
      } else {
        messageContainer.scrollTo({
          top: messageContainer.scrollHeight,
          behavior: 'smooth'
        })
      }
    },
    // 将代理对象转化为普通对象
    convertProxyToPlainObject(proxy) {
      // 检查参数是否为对象
      if (typeof proxy !== 'object' || proxy === null) {
        throw new Error('Invalid input. Only objects can be converted.');
      }
      // 创建一个新的普通对象
      const plainObject = {};
      // 复制代理对象的可枚举属性到新对象中
      Object.keys(proxy).forEach(key => {
        plainObject[key] = proxy[key];
      });
      // 返回转换后的普通对象
      return plainObject;
    },
    // 获取当前时间
    getCurrentTime() {
      return new Date().toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '')
    },
    // 发送按钮点击
    sendMessage() {
      if (this.text !== '' || (this.inputMode === 'recImage' && this.imageFile)) {
        this.buttonDisable = true
        this.loading = true
        this.abort = new AbortController()
        
        // 添加用户消息
        let userMessage = ''
        if (this.inputMode === 'recImage' && this.imageFile) {
          userMessage = this.text ? `[图片] ${this.text}` : '[图片]'
        } else {
          userMessage = this.text
        }
        
        this.messages.push({ 
          text: userMessage, 
          isMe: true, 
          date: this.getCurrentTime(), 
          titleId: this.selectedItem 
        })
        this.allMessages.push({ 
          text: userMessage, 
          isMe: true, 
          date: this.getCurrentTime(), 
          titleId: this.selectedItem 
        })
        
        setTimeout(() => {
          this.toBottom()
        }, 1000)
        
        // 根据不同的输入模式处理
        if (this.inputMode === 'recImage' && this.imageFile) {
          this.identifyImage()
        } else if (this.inputMode === 'genImage') {
          this.generateImageDirect()
        } else {
          // 原有的文本聊天逻辑
          const url = 'https://www.sweek.top/model/sse/data?text=' + this.text
          fetch(url, {
              signal: this.abort.signal // 将AbortController的信号传递给fetch
            })
            .then((response) => {
              this.messages.push({ text: '', isMe: false, date: this.getCurrentTime(), titleId: this.selectedItem })
              this.allMessages.push({ text: '', isMe: false, date: this.getCurrentTime(), titleId: this.selectedItem })
              const reader = response.body.getReader()
              const decoder = new TextDecoder('utf-8')
              const lastIndex = this.messages.length - 1
              const allLastIndex = this.allMessages.length - 1
              const readChunk = () => {
                reader.read().then(({ done, value }) => {
                  if (done) {
                    // After readChunk() finishes, execute the localStorage.setItem
                    localStorage.setItem('model', JSON.stringify(this.allMessages))
                    const text = this.messages[lastIndex - 1].text
                    const restext = this.messages[lastIndex].text
                    saveModelText({
                      email: this.userInfo.email,
                      text,
                      restext,
                      titleId: this.selectedItem
                    }).then(() => {
                      if (this.selectedTitle === '新会话') {
                        if (text.length > 10) {
                          this.selectedTitle = text.slice(0, 10) + '...'
                        } else {
                          this.selectedTitle = text
                        }
                        this.itemList.forEach(item => {
                          if (item.value === this.selectedItem) {
                            item.label = this.selectedTitle
                          }
                        })
                        localStorage.setItem('model-title', JSON.stringify(this.itemList))
                      }
                    }).finally(() => {
                      this.buttonDisable = false
                      this.loading = false
                    })
                    return
                  }
                  const decodedValue = decoder.decode(value, { stream: true })
                  this.messages[lastIndex].text += decodedValue
                  this.allMessages[allLastIndex].text += decodedValue
                  this.toBottom()
                  readChunk()
                })
              }
              readChunk()
              this.text = ''
              // 滚动到底部
              this.$nextTick(() => {
                // 在 DOM 更新循环结束后执行回调函数
                // 执行您的操作
                const messageContainer = this.$refs.messageContainer
                messageContainer.scrollTo({
                  top: messageContainer.scrollHeight,
                  behavior: 'smooth'
                })
              })
            })
            .catch((error) => {
              console.error('发生错误:', error)
              this.buttonDisable = false
              this.messages.push({ text: '出问题啦，请重新再尝试一下', isMe: false, date: this.getCurrentTime() })
            })
            .finally(() => {
              // loading = false
            })
        }
      } else {
        if (this.inputMode === 'recImage') {
          this.$message.warning('请上传图片')
        } else {
          this.$message.warning('请输入内容')
        }
      }
    },
    // 终止会话
    stopMessage() {
      this.abort.abort()
      this.messages.push({ text: '已终止请求，请重新发送问题', isMe: false, date: this.getCurrentTime(), isError: true })
      this.loading = false
    },
    copyToClipboard(text) {
      // 复制到剪贴板的逻辑
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$message.success('文本已成功复制到剪贴板')
    },
    // 抽屉中的新增会话
    addItemFromDrawer() {
      this.addItem()
      this.hhVisible = false
    },
    // 抽屉中选择会话
    selectItemFromDrawer(item) {
      this.selectItem(item)
      this.hhVisible = false
    },
    // 新增对话弹出框选中
    selectItem (item) {
      localStorage.setItem('selected-item', item.value)
      this.selectedItem = item.value
      this.selectedTitle = item.label
      const list = JSON.parse(localStorage.getItem('model'))
      if (list) {
        this.messages = list.filter(item => item.titleId === this.selectedItem)
        // console.log(' this.messages：：：+ ', this.messages)
      }
      this.$nextTick(() => {
        this.toBottom()
      })
    },
    addItem() {
      // 新增会话的逻辑
      this.itemList.unshift({
        value: this.generateRandomString(),
        label: '新会话'
      })
      localStorage.setItem('model-title', JSON.stringify(this.itemList))
      this.selectedItem = this.itemList[0].value
      this.selectedTitle = this.itemList[0].label
      const list = JSON.parse(localStorage.getItem('model'))
      if (list) {
        this.messages = list.filter(item => item.titleId === this.selectedItem)
        // console.log(' this.messages：：：+ ', this.messages)
      }
    },
    // 确认会话删除
    handleDelete (item) {
      this.$confirm('你确定要删除该会话么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(async() => {
        this.itemList = this.itemList.filter(citem => citem.value !== item.value)
        this.allMessages = this.allMessages.filter(citem => citem.titleId !== item.value)
        localStorage.setItem('model-title', JSON.stringify(this.itemList))
        localStorage.setItem('model', JSON.stringify(this.allMessages))
        if (this.itemList.length > 0) {
          localStorage.setItem('selected-item', this.itemList[0].value)
        } else {
          localStorage.removeItem('selected-item')
          this.selectedItem = '000000'
          this.selectedTitle = ''
        }
        this.setLocalStorage()
        this.$message.success('删除成功')
      }).catch(() => {
      })
    },
    // 直接生成图片
    generateImageDirect() {
      // 显示正在生成的消息
      this.messages.push({ 
        text: '正在生成图片，请稍候...', 
        isMe: false, 
        date: this.getCurrentTime(), 
        titleId: this.selectedItem 
      })
      this.allMessages.push({ 
        text: '正在生成图片，请稍候...', 
        isMe: false, 
        date: this.getCurrentTime(), 
        titleId: this.selectedItem 
      })
      
      // 调用图片生成API
      fetch('https://www.sweek.top/api/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          prompt: this.text
        })
      })
      .then(response => response.json())
      .then(data => {
        // 替换正在生成的消息为生成的图片
        const imgMarkdown = `![生成的图片](${data.image_url})\n\n*基于提示词: "${this.text}"*`
        const lastIndex = this.messages.length - 1
        const allLastIndex = this.allMessages.length - 1
        
        this.messages[lastIndex].text = imgMarkdown
        this.allMessages[allLastIndex].text = imgMarkdown
        
        // 保存到localStorage
        localStorage.setItem('model', JSON.stringify(this.allMessages))
        
        // 如果是新会话，设置标题
        if (this.selectedTitle === '新会话') {
          this.selectedTitle = this.text.length > 10 ? 
            this.text.slice(0, 10) + '...' : 
            this.text
          
          this.itemList.forEach(item => {
            if (item.value === this.selectedItem) {
              item.label = this.selectedTitle
            }
          })
          localStorage.setItem('model-title', JSON.stringify(this.itemList))
        }
      })
      .catch(error => {
        console.error('图片生成失败:', error)
        // 替换正在生成的消息为错误消息
        const lastIndex = this.messages.length - 1
        const allLastIndex = this.allMessages.length - 1
        
        this.messages[lastIndex].text = '图片生成失败，请重试'
        this.messages[lastIndex].isError = true
        this.allMessages[allLastIndex].text = '图片生成失败，请重试'
        this.allMessages[allLastIndex].isError = true
      })
      .finally(() => {
        this.buttonDisable = false
        this.loading = false
        this.text = ''
        this.toBottom()
      })
    },
    beforeImageUpload(file) {
      const isImage = file.type.startsWith('image/')
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('只能上传图片文件!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
        return false
      }

      // 创建预览URL
      this.imageUrl = URL.createObjectURL(file)
      this.imageFile = file
      return false // 阻止自动上传
    },
    // 添加删除图片方法
    removeImage(e) {
      e.preventDefault()
      this.imageUrl = ''
      this.imageFile = null
    },
    // 添加图片识别方法
    identifyImage() {
      // 添加正在识别的消息
      this.messages.push({ 
        text: '正在识别图片，请稍候...', 
        isMe: false, 
        date: this.getCurrentTime(), 
        titleId: this.selectedItem 
      })
      this.allMessages.push({ 
        text: '正在识别图片，请稍候...', 
        isMe: false, 
        date: this.getCurrentTime(), 
        titleId: this.selectedItem 
      })

      // 创建FormData对象
      const formData = new FormData()
      formData.append('image', this.imageFile)
      formData.append('text', this.text || '')
      
      // 发送请求
      fetch('https://www.sweek.top/api-python/identify-image', {
        method: 'POST',
        body: formData,
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        redirect: 'follow'
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`网络请求失败: ${response.status} ${response.statusText}`)
        }
        return response.json()
      })
      .then(data => {
        console.log('API 返回数据:', data) // 调试用
        
        // 更新识别结果
        const lastIndex = this.messages.length - 1
        const allLastIndex = this.allMessages.length - 1
        
        this.messages[lastIndex].text = data.description
        this.allMessages[allLastIndex].text = data.description
        
        // 保存到localStorage
        localStorage.setItem('model', JSON.stringify(this.allMessages))
        
        // 如果是新会话，设置标题
        if (this.selectedTitle === '新会话') {
          this.selectedTitle = this.text.length > 10 ? 
            this.text.slice(0, 10) + '...' : 
            this.text || '图片识别'
          
          this.itemList.forEach(item => {
            if (item.value === this.selectedItem) {
              item.label = this.selectedTitle
            }
          })
          localStorage.setItem('model-title', JSON.stringify(this.itemList))
        }
      })
      .catch(error => {
        console.error('图片识别失败:', error)
        const lastIndex = this.messages.length - 1
        const allLastIndex = this.allMessages.length - 1
        
        this.messages[lastIndex].text = '图片识别失败：' + error.message
        this.messages[lastIndex].isError = true
        this.allMessages[allLastIndex].text = '图片识别失败：' + error.message
        this.allMessages[allLastIndex].isError = true
      })
      .finally(() => {
        this.buttonDisable = false
        this.loading = false
        this.text = ''
        this.imageUrl = ''
        this.imageFile = null
        this.toBottom()
      })
    },
    setInputMode(mode) {
      this.inputMode = mode
    },
    getPlaceholderText() {
      if (this.inputMode === 'text') {
        return '请输入你的问题，按回车发送'
      } else if (this.inputMode === 'genImage') {
        return '请输入图片描述'
      } else if (this.inputMode === 'recImage') {
        return '请输入图片描述'
      }
      return '请输入你的问题，按回车发送'
    },
    getSendButtonIcon() {
      // if (this.inputMode === 'text') {
      //   return 'el-icon-s-promotion'
      // } else if (this.inputMode === 'genImage') {
      //   return 'el-icon-picture-outline'
      // } else if (this.inputMode === 'recImage') {
      //   return 'el-icon-camera'
      // }
      return 'el-icon-s-promotion'
    }
  }
}
</script>
<style lang="scss" scoped>
.aichat {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  transition: all 0.3s ease;
}

.chat-container {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.left-content {
  width: 260px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .dark-mode & {
    background-color: var(--card-bg);
    box-shadow: 0 2px 12px var(--shadow-color);
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }

  .left-header {
    padding: 10px;
  }

  .conversations-list {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 10px;
  }

  .add {
    width: 100%;
    border: 1px dashed #4e7af0;
    border-radius: 8px;
    height: 42px;
    line-height: 42px;
    color: #4e7af0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
    margin-bottom: 10px;

    i {
      margin-right: 6px;
    }

    &:hover {
      background-color: rgba(78, 122, 240, 0.08);
    }
  }

  .item {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #eaecf3;
    border-radius: 8px;
    height: 42px;
    line-height: 42px;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    padding: 0 12px;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;

    .dark-mode & {
      border-color: var(--border-color);
      color: var(--text-primary);
      box-shadow: 0 1px 3px var(--shadow-color);
    }

    &-label {
      flex: 1;
      margin-left: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      background-color: rgba(78, 122, 240, 0.05);
      border-color: #4e7af0;
    }

    &-active {
      background-color: rgba(78, 122, 240, 0.08) !important;
      border-color: #4e7af0;
      box-shadow: 0 2px 8px rgba(78, 122, 240, 0.15);
    }

    &-delete {
      color: #909399;
      opacity: 0;
      transition: opacity 0.2s ease;
      padding: 0 6px;

      &:hover {
        color: #f56c6c;
      }
    }

    &:hover .item-delete {
      opacity: 1;
    }
  }
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0; // 防止flex子项溢出

  &-title {
    height: 45px;
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-weight: 600;
    font-size: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;
    position: relative;

    &.dark-title {
      background-color: var(--card-bg);
      color: var(--text-primary);
      box-shadow: 0 2px 8px var(--shadow-color);
    }

    .menu-icon {
      position: absolute;
      left: 16px;
      cursor: pointer;
      padding: 5px;

      i {
        font-size: 18px;
      }
    }

    .chat-title {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-content {
    flex: 1;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;

    &.dark-content {
      background-color: var(--card-bg);
      color: var(--text-primary);
      box-shadow: 0 2px 12px var(--shadow-color);
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d1d1d1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #b1b1b1;
    }
  }
}

.message-wrapper {
  width: 100%;
  margin-bottom: 20px;
  transition: opacity 0.5s ease;
}

.fade-in {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.bottom {
  width: 100%;
  box-sizing: border-box;
  padding: 0;

  .input-container {
    position: relative;
    display: flex;
    align-items: flex-end;

    .input-mode-buttons {
      position: absolute;
      top: 8px;
      left: 16px;
      z-index: 10;
      display: flex;
      align-items: center;
      
      .mode-button {
        margin-right: 8px;
        padding: 4px 12px;
        border-radius: 16px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #e0e0e0;
        background-color: #fff;
        color: #666;
        font-size: 13px;
        display: flex;
        align-items: center;

        &.active {
          background-color: #4e7af0;
          color: #fff;
          border-color: #4e7af0;
          box-shadow: 0 2px 6px rgba(78, 122, 240, 0.3);
        }

        &:hover:not(:disabled):not(.active) {
          border-color: #4e7af0;
          color: #4e7af0;
        }

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
          border-color: #e0e0e0;
        }

        i {
          margin-right: 4px;
          font-size: 14px;
        }

        .dark-mode & {
          background-color: var(--bg-primary);
          border-color: var(--border-color);
          color: var(--text-secondary);
          
          &.active {
            background-color: #4e7af0;
            color: #fff;
            border-color: #4e7af0;
          }
          
          &:hover:not(:disabled):not(.active) {
            border-color: #90cdf4;
            color: #90cdf4;
          }
        }
      }
    }

    .image-upload-container {
      position: absolute;
      right: 60px;
      top: 8px;
      z-index: 10;
    }

    .image-wrapper {
      width: 110px;
      height: 60px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #f5f7fa;
      border: 1px dashed #d9d9d9;
      
      .dark-mode & {
        background-color: var(--bg-primary);
        border-color: var(--border-color);
      }
    }

    .image-uploader {
      width: 100%;
      height: 100%;
      cursor: pointer;
      
      &:hover .upload-placeholder {
        color: #4e7af0;
        border-color: #4e7af0;
      }
    }
    
    ::v-deep .el-upload {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .upload-placeholder {
      width: 100%;
      height: 100%;
      color: #8c939d;
      text-align: center;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      background-color: #f5f7fa;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      
      .dark-mode & {
        background-color: var(--bg-primary);
      }

      i.icon-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        color: #8c939d;
      }
      
      span {
        font-size: 12px;
        color: #8c939d;
        display: block;
      }
    }
    
    .image-preview-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      
      .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .image-delete-icon {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
        i {
          color: white;
          font-size: 12px;
        }
        
        &:hover {
          background-color: rgba(245, 108, 108, 0.7);
        }
      }
    }

    ::v-deep {
      .el-textarea {
        width: 100%;

        .el-textarea__inner {
          caret-color: #4e7af0 !important;
          border-radius: 20px;
          padding: 12px 60px 12px 20px;
          padding-top: 38px; /* 为按钮留出更多空间 */
          font-size: 15px;
          line-height: 1.5;
          min-height: 50px;
          max-height: 120px;
          border: 1px solid #e0e0e0;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
          transition: all 0.3s ease;
          overflow-y: auto;

          .dark-mode & {
            background-color: var(--bg-primary);
            border-color: var(--border-color);
            color: var(--text-primary);
            box-shadow: 0 2px 8px var(--shadow-color);
          }

          &:focus {
            border-color: #4e7af0;
            box-shadow: 0 2px 12px rgba(78, 122, 240, 0.15);
          }

          &:disabled {
            background-color: #f5f5f5;

            .dark-mode & {
              background-color: rgba(30, 30, 30, 0.7);
            }
          }
        }
      }
    }

    .button-group {
      position: absolute;
      right: 5px;
      bottom: 5px;
      display: flex;
      gap: 8px;

      .image-button, .send-button, .stop-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        border: none;

        i {
          font-size: 18px;
        }
      }

      .image-button {
        background-color: #4e7af0;
        color: #fff;
        box-shadow: 0 2px 8px rgba(78, 122, 240, 0.25);

        &:hover {
          background-color: #3c68e0;
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(78, 122, 240, 0.3);
        }

        &:active {
          transform: translateY(0);
        }

        &:disabled {
          background-color: #a0a0a0;
          box-shadow: none;
        }
      }

      .send-button {
        background-color: #4e7af0;
        color: #fff;
        box-shadow: 0 2px 8px rgba(78, 122, 240, 0.25);

        &:hover {
          background-color: #3c68e0;
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(78, 122, 240, 0.3);
        }

        &:active {
          transform: translateY(0);
        }

        &:disabled {
          background-color: #a0a0a0;
          box-shadow: none;
        }
      }

      .stop-button {
        background-color: #f56c6c;
        color: #fff;
        box-shadow: 0 2px 8px rgba(245, 108, 108, 0.25);

        &:hover {
          background-color: #e45656;
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(245, 108, 108, 0.3);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }
  }
}

.user-message {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;

  &-content {
    background-color: #4e7af0;
    padding: 12px 16px;
    border-radius: 18px 18px 4px 18px;
    color: #ffffff;
    font-size: 15px;
    line-height: 1.5;
    max-width: 75%;
    box-shadow: 0 2px 6px rgba(78, 122, 240, 0.2);
    word-break: break-word;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #4e7af0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    box-shadow: 0 2px 8px rgba(78, 122, 240, 0.3);
    flex-shrink: 0;

    i {
      color: white;
      font-size: 20px;
    }
  }
}

.assistant-message-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  .ai-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #2cc46e;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    box-shadow: 0 2px 8px rgba(44, 196, 110, 0.3);
    flex-shrink: 0;

    i {
      color: white;
      font-size: 20px;
    }
  }
}

.assistant-message {
  width: fit-content;
  max-width: calc(100% - 52px);
  align-self: flex-start;
  background-color: #ffffff;
  padding: 14px 18px;
  border-radius: 4px 18px 18px 18px;
  font-size: 15px;
  line-height: 1.5;
  overflow: auto;
  color: #333;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;

  .dark-mode & {
    background-color: var(--card-bg);
    color: var(--text-primary);
    box-shadow: 0 2px 6px var(--shadow-color);
  }

  &.error-message {
    background-color: #fff2f0;
    color: #f56c6c;
    border-left: 4px solid #f56c6c;

    .dark-mode & {
      background-color: rgba(245, 108, 108, 0.1);
    }
  }

  .typing-indicator {
    display: flex;
    align-items: center;
    margin: 8px 0;

    span {
      height: 8px;
      width: 8px;
      float: left;
      margin: 0 2px;
      background-color: #4e7af0;
      border-radius: 50%;
      animation: wave 1s infinite ease-in-out;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.15s;
      }

      &:nth-child(3) {
        animation-delay: 0.3s;
      }
    }
  }

  @keyframes wave {
    0%, 60%, 100% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-6px);
    }
  }

  ::v-deep {
    pre {
      background-color: #f5f7fa;
      border-radius: 8px;
      margin: 12px 0;
      padding: 12px;
      position: relative;
      overflow-x: auto;

      .dark-mode & {
        background-color: #1e2030;
      }
    }

    .btn-copy {
      .dark-mode & {
        background-color: #1a1d2d !important;
        color: #e2e8f0 !important;
      }

      button {
        .dark-mode & {
          background-color: #2d3748 !important;
          color: #e2e8f0 !important;
        }
      }
    }

    code {
      font-family: 'Fira Code', 'Consolas', monospace;
      font-size: 14px;

      .dark-mode & {
        color: #e2e8f0;
      }
    }

    p {
      margin: 8px 0;
    }

    h1, h2, h3, h4 {
      margin-top: 16px;
      margin-bottom: 8px;
      font-weight: 600;

      .dark-mode & {
        color: var(--text-primary);
      }
    }

    ul, ol {
      padding-left: 24px;
      margin: 8px 0;

      .dark-mode & {
        color: var(--text-primary);
      }
    }

    blockquote {
      border-left: 4px solid #4e7af0;
      padding-left: 16px;
      color: #555;
      margin: 12px 0;
      background-color: #f9f9fc;
      padding: 10px 16px;
      border-radius: 0 8px 8px 0;

      .dark-mode & {
        background-color: rgba(78, 122, 240, 0.1);
        color: var(--text-secondary);
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin: 16px 0;

      th, td {
        border: 1px solid #eaecf3;
        padding: 8px 12px;

        .dark-mode & {
          border-color: var(--border-color);
        }
      }

      th {
        background-color: #f5f7fa;

        .dark-mode & {
          background-color: rgba(30, 30, 30, 0.4);
        }
      }
    }

    img {
      max-width: 100%;
      border-radius: 8px;
      margin: 10px 0;
    }

    a {
      color: #4e7af0;
      text-decoration: none;
      border-bottom: 1px dashed #4e7af0;
      transition: all 0.2s ease;

      .dark-mode & {
        color: #90cdf4;
        border-bottom: 1px dashed #90cdf4;
      }

      &:hover {
        color: #3c68e0;
        border-bottom: 1px solid #3c68e0;

        .dark-mode & {
          color: #63b3ed;
          border-bottom: 1px solid #63b3ed;
        }
      }
    }
  }
}

.message-top {
  width: 100%;
  display: flex;
  color: #909399;
  font-size: 12px;
  padding-bottom: 6px;
}

.message-bottom {
  width: 100%;
  display: flex;
  margin-top: 8px;

  .message-btn {
    display: flex;
    align-items: center;
    padding: 4px 10px;
    border-radius: 12px;
    background-color: #f0f2f5;
    color: #4e7af0;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;

    .dark-mode & {
      background-color: rgba(78, 122, 240, 0.1);
      color: #90cdf4;
    }

    i {
      margin-right: 4px;
    }

    &:hover {
      background-color: #e6e9f0;
      color: #3c68e0;

      .dark-mode & {
        background-color: rgba(78, 122, 240, 0.2);
      }
    }
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  ::v-deep .el-empty__image {
    width: 120px;
    height: 120px;
  }

  .empty-description {
    margin: 10px 0 20px;
    color: #909399;
    font-size: 14px;

    .dark-mode & {
      color: var(--text-secondary);
    }
  }
}

.add-btn {
  height: 42px;
  line-height: 42px;
  padding: 0 24px;
  border-radius: 21px;
  color: #fff;
  background-color: #4e7af0;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 3px 8px rgba(78, 122, 240, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background-color: #3c68e0;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(78, 122, 240, 0.4);
  }

  &:active {
    transform: translateY(0);
  }
}

::v-deep .chat-drawer {
  border-radius: 0 16px 16px 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 3000 !important;

  .el-drawer__wrapper {
    z-index: 3000 !important;
  }

  .el-drawer__container {
    z-index: 3000 !important;
  }

  .dark-mode & {
    background-color: var(--card-bg);
    box-shadow: 0 0 20px var(--shadow-color);
  }

  .el-drawer__header {
    margin-bottom: 0;
    padding: 16px;
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f2f5;
    font-size: 18px;
    font-weight: 600;
    color: #333;

    .dark-mode & {
      border-bottom-color: var(--border-color);
      color: var(--text-primary);
    }
  }

  .el-drawer__body {
    padding: 0;
    overflow: hidden;
    height: calc(100% - 60px);
  }
}

.hh-content {
  width: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .dark-mode & {
    background-color: var(--card-bg);
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }

  .add {
    width: 100%;
    margin-top: 0;
    margin-bottom: 16px;
    border: 1px dashed #4e7af0;
    border-radius: 8px;
    height: 42px;
    line-height: 42px;
    color: #4e7af0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: rgba(78, 122, 240, 0.08);
    }
  }

  .item {
    width: 100%;
    margin-top: 0;
    margin-bottom: 12px;
    border: 1px solid #eaecf3;
    border-radius: 8px;
    height: 42px;
    line-height: 42px;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    padding: 0 12px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;

    &-label {
      flex: 1;
      margin-left: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      background-color: rgba(78, 122, 240, 0.05);
      border-color: #4e7af0;
    }

    &-active {
      background-color: rgba(78, 122, 240, 0.08) !important;
      border-color: #4e7af0;
    }

    &-delete {
      color: #909399;
      padding: 0 6px;

      &:hover {
        color: #f56c6c;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .aichat {
    padding: 8px;
  }

  .chat-container {
    gap: 0;
  }

  .right-content {
    &-title {
      height: 45px;
      margin-bottom: 10px;

      .menu-icon {
        left: 12px;

        i {
          font-size: 20px;
        }
      }
    }

    &-content {
      padding: 16px 10px !important;
      margin-bottom: 10px;
    }
  }

  .bottom {
    .input-container {
      .input-mode-buttons {
        top: 6px;
        left: 12px;
        right: 12px;
        flex-wrap: wrap;
        justify-content: space-between;
        
        .mode-button {
          font-size: 12px;
          padding: 3px 8px;
          margin-bottom: 4px;
          
          i {
            font-size: 13px;
          }
        }
      }
    
      .image-upload-container {
        right: 48px;
        top: auto;
        bottom: 7px;
        z-index: 5;
      }
      
      .image-wrapper {
        width: 75px;
        height: 36px;
      }

      ::v-deep .el-textarea__inner {
        min-height: 45px;
        padding: 10px 56px 10px 16px;
        padding-top: 50px; /* 调整留出空间 */
        font-size: 14px;
      }

      .button-group {
        right: 3px;
        bottom: 3px;
        gap: 4px;
        z-index: 10;

        .image-button, .send-button, .stop-button {
          width: 36px;
          height: 36px;

          i {
            font-size: 16px;
          }
        }
      }
    }
  }

  .assistant-message, .user-message-content {
    font-size: 14px;
    padding: 10px 14px;
  }

  .ai-avatar, .user-avatar {
    width: 36px;
    height: 36px;

    i {
      font-size: 18px;
    }
  }

  .hh-content {
    padding: 12px;

    .add {
      height: 46px;
      line-height: 46px;
      font-size: 15px;
    }

    .item {
      height: 50px;
      line-height: 50px;
      padding: 0 15px;
      margin-bottom: 10px;
      font-size: 15px;

      i {
        font-size: 18px;
      }

      &-delete {
        padding: 0 10px;
        opacity: 1;

        i {
          font-size: 18px;
        }
      }
    }
  }

  ::v-deep .chat-drawer {
    .el-drawer__header {
      padding: 0 16px;
      font-size: 18px;
    }
  }
}
</style>

