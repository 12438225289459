<template>
  <div class="user-center">
    <div class="user-center-container">
      <!-- 头部区域 -->
      <div class="profile-header">
        <div class="avatar-section">
          <div class="avatar">
            <i class="fas fa-user"></i>
          </div>
          <h1>{{ infoForm.nickname || '个人信息' }}</h1>
        </div>
        <button v-if="!ifEdit" @click="handleEdit" class="edit-btn">
          <i class="fas fa-edit"></i>
          <span>编辑</span>
        </button>
        <button v-else @click="handleSave" class="save-btn">
          <i class="fas fa-save"></i>
          <span>保存</span>
        </button>
      </div>

      <!-- 主要内容区 -->
      <div class="profile-content">
        <!-- 个人信息卡片 -->
        <div class="profile-card">
          <h2 class="card-title">基本信息</h2>
          <div class="info-grid">
            <div v-for="(item, index) in basicInfo" :key="index" class="info-item">
              <div class="info-label">
                <i :class="`fas fa-${item.icon}`"></i>
                <span>{{ item.label }}</span>
              </div>
              <div class="info-value" v-if="!ifEdit">{{ infoForm[item.value] || '未设置' }}</div>
              <el-input 
                v-else 
                :disabled="item.disabled" 
                size="small" 
                :type="item.type == 'area' ? 'textarea' : 'text'"
                v-model="infoForm[item.value]"
                :placeholder="`请输入${item.label}`"
              ></el-input>
            </div>
          </div>
        </div>

        <!-- 联系信息卡片 -->
        <div class="profile-card">
          <h2 class="card-title">联系方式</h2>
          <div class="info-grid">
            <div v-for="(item, index) in contactInfo" :key="index" class="info-item">
              <div class="info-label">
                <i :class="`fas fa-${item.icon}`"></i>
                <span>{{ item.label }}</span>
              </div>
              <div class="info-value" v-if="!ifEdit">{{ infoForm[item.value] || '未设置' }}</div>
              <el-input 
                v-else 
                :disabled="item.disabled" 
                size="small" 
                :type="item.type == 'area' ? 'textarea' : 'text'"
                v-model="infoForm[item.value]"
                :placeholder="`请输入${item.label}`"
              ></el-input>
              <el-button 
                v-if="ifEdit && item.value == 'address'" 
                type="primary" 
                size="small"
                class="location-btn"
                @click="getLocation"
              >
                <i class="fas fa-map-pin"></i> 获取位置
              </el-button>
            </div>
          </div>
        </div>

        <!-- 个性签名卡片 -->
        <div class="profile-card">
          <h2 class="card-title">个性签名</h2>
          <div class="signature-container">
            <div class="info-value signature" v-if="!ifEdit">{{ infoForm.signature || '未设置个性签名' }}</div>
            <el-input 
              v-else 
              type="textarea" 
              :rows="4"
              v-model="infoForm.signature"
              placeholder="写点什么来介绍自己吧..."
            ></el-input>
          </div>
        </div>

        <!-- 账号操作区 -->
        <div class="account-actions">
          <button v-for="item in btnList" :key="item.value" @click="handleClick(item)" class="action-btn">
            <i :class="`fas fa-${item.icon}`"></i>
            <span>{{ item.label }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, updateUserInfo } from '@/api/user'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'UserCenterView',
  data() {
    return {
      ifEdit: false,
      infoForm: {
        nickname: '',
        username: '',
        email: '',
        sex: '',
        phone: '',
        address: '',
        addressdetail: '',
        signature: ''
      },
      infoList: [
        { label: '昵称', value: 'nickname', disabled: false, icon: 'user' },
        { label: '账号', value: 'username', disabled: true, icon: 'id-card' },
        { label: '邮箱', value: 'email', disabled: true, icon: 'envelope' },
        { label: '性别', value: 'sex', disabled: false, icon: 'venus-mars' },
        { label: '联系方式', value: 'phone', disabled: false, icon: 'phone' },
        { label: '地区', value: 'address', disabled: false, icon: 'map-marker-alt' },
        { label: '个性签名', value: 'signature', disabled: false, icon: 'comment', type: 'area' }
      ],
      btnList: [
        { label: '修改密码', value: 'editPassword', icon: 'key' },
        { label: '账号注销', value: 'deleteAccount', icon: 'user-times' },
        { label: '退出登录', value: 'exitLogin', icon: 'sign-out-alt' }
      ]
    }
  },
  computed: {
    ifPC() {
      return this.$store.state.ifPC
    },
    userInfo() {
      return this.$store.state.userInfo || null
    },
    basicInfo() {
      return this.infoList.filter(item => 
        ['nickname', 'username', 'email', 'sex'].includes(item.value)
      )
    },
    contactInfo() {
      return this.infoList.filter(item => 
        ['phone', 'address'].includes(item.value)
      )
    },
    ...mapGetters(['isDarkMode'])
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    handleEdit() {
      this.ifEdit = true
    },
    handleSave() {
      this.ifEdit = false
      updateUserInfo(this.infoForm).then(res => {
        const loading = this.$loading({
          lock: true,
          text: '数据处理中...',
          spinner: 'el-icon-loading'
        })
        setTimeout(() => {
          loading.close()
          this.$message.success('修改成功')
          this.$store.commit('SET_USER_INFO', this.infoForm)
        }, 1000)
      })
    },
    handleClick(item) {
      switch (item.value) {
        case 'editPassword':
          this.$message('开发中...')
          break;
        case 'deleteAccount':
          this.$message('开发中...')
          break;
        case 'exitLogin':
          this.$confirm('你确定退出登录嘛?', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            center: true,
            type: 'warning'
          }).then(async () => {
            localStorage.removeItem('personlog_token')
            localStorage.removeItem('userInfo')
            this.$store.commit('SET_USER_INFO', null)
            this.$store.commit('SET_SHOW_LOGIN', true)
            this.$message.success('退出登录成功！')
          }).catch(() => {
          })
          break;
        default:
          break;
      }
    },
    // 获取个人信息
    getUserInfo() {
      const loading = this.$loading({
        lock: true,
        text: '数据处理中...',
        spinner: 'el-icon-loading'
      })
      getUserInfo({
        email: this.userInfo.email
      }).then(res => {
        this.infoForm = res
      }).finally(() => {
        loading.close()
      })
    },
    // 获取个人位置
    getLocation() {
      const loading = this.$loading({
        lock: true,
        text: '获取位置信息中...',
        spinner: 'el-icon-loading'
      })
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords
          // 使用高德地图API，将经纬度转换为地址
          const key = '0f70658c11b14d764a914500ad74b131'
          const url = `https://restapi.amap.com/v3/geocode/regeo?location=${longitude},${latitude}&key=${key}&radius=100&extensions=all`
          axios.get(url)
            .then(response => {
              const address = response.data.regeocode.formatted_address
              const country = response.data.regeocode.addressComponent.country
              const province = response.data.regeocode.addressComponent.province
              const city = response.data.regeocode.addressComponent.city
              this.infoForm.addressdetail = address
              this.infoForm.address = country + province + city
              this.$message.success('获取位置成功')
              loading.close()
            })
            .catch(error => {
              console.error(error)
              setTimeout(() => {
                this.$message.error('获取位置失败')
                loading.close()
              }, 1500)
            })
        }, error => {
          console.error(error)
          setTimeout(() => {
            this.$message.error('获取位置失败')
            loading.close()
            setTimeout(() => {
              this.$message.error('请手动输入位置信息')
            }, 1500)
          }, 1500)
        })
      } else {
        setTimeout(() => {
          this.$message.error('获取位置失败')
          loading.close()
        }, 1500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-center {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: linear-gradient(135deg, var(--bg-primary) 0%, var(--bg-secondary) 100%);
  padding: 2rem 1rem;
  box-sizing: border-box;
  font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
  color: var(--text-primary);
  
  .user-center-container {
    max-width: 1000px;
    margin: 0 auto;
  }

  // 头部区域样式
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    margin-bottom: 2rem;
    background-color: var(--card-bg);
    border-radius: 12px;
    box-shadow: 0 4px 20px var(--shadow-color);
    
    .avatar-section {
      display: flex;
      align-items: center;
      
      .avatar {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: linear-gradient(45deg, #4a6fa5, #63b3ed);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.2rem;
        box-shadow: 0 4px 10px rgba(74, 111, 165, 0.25);
        
        i {
          font-size: 28px;
          color: white;
        }
      }
      
      h1 {
        font-size: 1.8rem;
        font-weight: 600;
        margin: 0;
        color: var(--text-primary);
      }
    }
    
    button {
      border: none;
      border-radius: 8px;
      padding: 0.75rem 1.5rem;
      cursor: pointer;
      font-weight: 500;
      font-size: 0.95rem;
      display: flex;
      align-items: center;
      transition: all 0.2s ease;
      
      i {
        margin-right: 8px;
        font-size: 1rem;
      }
      
      &.edit-btn {
        background-color: #ebf8ff;
        color: #3182ce;
        
        &:hover {
          background-color: #bee3f8;
          transform: translateY(-2px);
        }
      }
      
      &.save-btn {
        background-color: #3182ce;
        color: white;
        
        &:hover {
          background-color: #2b6cb0;
          transform: translateY(-2px);
        }
      }
    }
  }

  // 内容区域样式
  .profile-content {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr;
  }

  // 卡片通用样式
  .profile-card {
    background-color: var(--card-bg);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 20px var(--shadow-color);
    
    .card-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--text-primary);
      margin-top: 0;
      margin-bottom: 1.5rem;
      position: relative;
      padding-bottom: 0.75rem;
      
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 3px;
        background: linear-gradient(to right, #4a6fa5, #63b3ed);
        border-radius: 3px;
      }
    }
  }

  // 信息网格样式
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
    
    .info-item {
      position: relative;
      
      .info-label {
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;
        font-weight: 500;
        color: var(--text-secondary);
        
        i {
          width: 24px;
          height: 24px;
          background-color: rgba(129, 140, 248, 0.15);
          color: #4a6fa5;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.75rem;
          font-size: 0.875rem;
        }
      }
      
      .info-value {
        padding: 0.75rem;
        background-color: var(--bg-secondary);
        border-radius: 8px;
        color: var(--text-primary);
        font-size: 0.95rem;
        min-height: 20px;
      }
      
      .el-input {
        margin-bottom: 0.5rem;
        
        :deep(.el-input__inner),
        :deep(.el-textarea__inner) {
          border-radius: 8px;
          border: 1px solid var(--border-color);
          padding: 0.75rem;
          font-size: 0.95rem;
          background-color: var(--bg-primary);
          color: var(--text-primary);
          
          &:focus {
            border-color: #3182ce;
            box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.15);
          }
        }
      }
      
      .location-btn {
        margin-top: 0.5rem;
      }
    }
  }

  // 个性签名区域
  .signature-container {
    .signature {
      padding: 1rem;
      background-color: var(--bg-secondary);
      border-radius: 8px;
      font-size: 0.95rem;
      line-height: 1.6;
      min-height: 100px;
      white-space: pre-wrap;
    }
    
    .el-input {
      :deep(.el-textarea__inner) {
        min-height: 120px;
        line-height: 1.6;
        background-color: var(--bg-primary);
        color: var(--text-primary);
      }
    }
  }

  // 底部按钮区域
  .account-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
    
    .action-btn {
      background-color: var(--card-bg);
      border: 1px solid var(--border-color);
      border-radius: 8px;
      padding: 0.75rem 1.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all 0.2s ease;
      color: var(--text-primary);
      font-size: 0.95rem;
      box-shadow: 0 2px 5px var(--shadow-color);
      
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 8px 15px var(--shadow-color);
        border-color: var(--border-color);
      }
      
      i {
        margin-right: 0.75rem;
        color: #3182ce;
      }
      
      &:nth-child(3) {
        color: #e53e3e;
        
        i {
          color: #e53e3e;
        }
      }
    }
  }

  // 响应式布局
  @media (max-width: 768px) {
    padding: 1rem 0.75rem;
    
    .profile-header {
      padding: 1.25rem;
      margin-bottom: 1.5rem;
      
      .avatar-section {
        .avatar {
          width: 50px;
          height: 50px;
          
          i {
            font-size: 22px;
          }
        }
        
        h1 {
          font-size: 1.5rem;
        }
      }
      
      button {
        padding: 0.6rem 1rem;
        font-size: 0.875rem;
      }
    }
    
    .info-grid {
      grid-template-columns: 1fr;
    }
    
    .account-actions {
      flex-direction: column;
      
      .action-btn {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
</style>

<style lang="scss">
/* 添加全局样式，确保元素对话框在暗黑模式下正确显示 */
.dark-mode {
  .el-message-box {
    background-color: var(--card-bg) !important;
    color: var(--text-primary) !important;
    
    .el-message-box__header {
      background-color: var(--card-bg) !important;
      
      .el-message-box__title {
        color: var(--text-primary) !important;
      }
      
      .el-message-box__headerbtn .el-message-box__close {
        color: #90cdf4 !important;
      }
    }
    
    .el-message-box__content {
      background-color: var(--card-bg) !important;
      color: var(--text-primary) !important;
    }
    
    .el-message-box__btns {
      background-color: var(--card-bg) !important;
      
      .el-button {
        background-color: var(--bg-secondary) !important;
        border-color: var(--border-color) !important;
        color: var(--text-primary) !important;
        
        &:hover {
          background-color: var(--hover-bg) !important;
          border-color: var(--border-color) !important;
        }
        
        &.el-button--primary {
          background-color: #4299e1 !important;
          border-color: #4299e1 !important;
          
          &:hover {
            background-color: #3182ce !important;
            border-color: #3182ce !important;
          }
        }
      }
    }
  }
  
  /* 消息通知在暗黑模式下的样式 */
  .el-message {
    &.el-message--success,
    &.el-message--warning,
    &.el-message--error,
    &.el-message--info {
      background-color: var(--card-bg) !important;
      border-color: var(--border-color) !important;
      
      .el-message__content {
        color: var(--text-primary) !important;
      }
    }
  }
  
  /* 加载中样式 */
  .el-loading-mask {
    background-color: rgba(45, 55, 72, 0.9) !important;
    
    .el-loading-spinner {
      .el-loading-text {
        color: var(--text-primary) !important;
      }
    }
  }
}
</style> 