<template>
  <div class="main-container">
    <!-- 左侧导航栏 -->
    <div class="sidebar" :class="{ 'show-sidebar': isSidebarOpen }">
      <div class="sidebar-container">
        <!-- Logo区域 -->
        <div class="logo">
          <i class="fas fa-cube"></i>
          <span>系统管理</span>
        </div>
        <!-- 移动端菜单按钮 -->
        <div class="mobile-menu-button">
          <div class="mobile-logo">
            <i class="fas fa-cube"></i>
            <span>系统管理</span>
          </div>
          <button @click="toggleSidebar">
            <i class="fas fa-bars"></i>
          </button>
        </div>
        <!-- 导航菜单 -->
        <nav>
          <div v-for="(item, index) in menuItems"
               :key="index"
               @click="activeMenu = item.id;"
               :class="{'active': activeMenu === item.id}"
               class="menu-item">
            <i :class="[item.icon]"></i>
            <span>{{ item.name }}</span>
          </div>
        </nav>
      </div>
    </div>
    <!-- 右侧内容区 -->
    <div class="content" :class="{ 'shift-content': isSidebarOpen }">
      <!-- 顶部面包屑 -->
      <div class="breadcrumb">
        <i class="bi bi-house"></i>
        <span>首页</span>
        <i class="bi bi-chevron-right"></i>
        <span>{{ currentMenuName }}</span>
        <div v-if="!ifPC" style="margin-left: 15px;" @click="toggleSidebar">
          <i class="fas fa-bars"></i>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="content-area">
        <UserManagement v-if="activeMenu === 'users'" />
        <CourseInfo v-if="activeMenu === 'courses'" />
        <QuestionInfo v-if="activeMenu === 'questions'" />
        <TutorialsInfo v-if="activeMenu === 'tutorials'" />
      </div>
    </div>
  </div>
</template>

<script>
import UserManagement from './model/UserManagement.vue';
import CourseInfo from './model/CourseInfo.vue';
import QuestionInfo from './model/QuestionInfo.vue';
import TutorialsInfo from './model/TutorialsInfo.vue';

export default {
  components: {
    UserManagement,
    CourseInfo,
    QuestionInfo,
    TutorialsInfo
  },
  data() {
    return {
      isSidebarOpen: false,
      activeMenu: 'users',
      menuItems: [
        { id: 'users', name: '用户管理', icon: 'bi bi-person-gear' },
        { id: 'courses', name: '课程信息', icon: 'bi bi-book' },
        { id: 'questions', name: '题库信息', icon: 'bi bi-journal-medical' },
        { id: 'tutorials', name: '使用教程', icon: 'bi bi-feather' },
      ]
    };
  },
  computed: {
    ifPC() {
      return this.$store.state.ifPC
    },
    userInfo() {
      return this.$store.state.userInfo || null
    },
    currentMenuName() {
      const currentMenu = this.menuItems.find(item => item.id === this.activeMenu);
      return currentMenu ? currentMenu.name : '';
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
  }
};
</script>

<style scoped lang="scss">
.main-container {
  height: 100%; // 修改为视口高度
  width: 100%;
  display: flex;
  overflow: auto;

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-secondary);
  }
}

.sidebar {
  width: 200px;
  flex-shrink: 0;
  background-color: #1f2937;
  transform: translateX(-200px);
  transition: transform 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  &.show-sidebar {
    transform: translateX(0);
  }

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-primary);
    border-right-color: var(--border-color);
  }
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid #374151;
  overflow: hidden;

  /* 适配黑夜模式 */
  .dark-mode & {
    border-right-color: var(--border-color);
  }
}

.logo {
  height: 4rem;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #374151;
  background-color: #111827;
  flex-shrink: 0;
  .fas {
    color: #60a5fa;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  span {
    color: #f3f4f6;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin-right: 1rem;
  }

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-secondary);
    border-bottom-color: var(--border-color);

    .fas {
      color: var(--active-text);
    }

    span {
      color: var(--text-primary);
    }
  }
}

.mobile-menu-button {
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid #1f2937;
  justify-content: space-between;
  flex-shrink: 0;

  .mobile-logo {
    display: flex;
    align-items: center;
    .fas {
      color: #3b82f6;
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
    span {
      color: #fff;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    .fas {
      font-size: 1.25rem;
    }
  }

  /* 适配黑夜模式 */
  .dark-mode & {
    border-bottom-color: var(--border-color);

    .mobile-logo {
      .fas {
        color: var(--active-text);
      }

      span {
        color: var(--text-primary);
      }
    }

    button {
      color: var(--text-primary);
    }
  }
}

nav {
  flex: 1;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  transition: all 0.2s ease;
  position: relative; /* 确保菜单项正确定位 */
  &:hover {
    background-color: #374151;
  }
  &.active {
    background-color: #3b82f6;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    .bi {
      color: #fff;
    }
    span {
      color: #fff;
      font-weight: 500;
    }
  }
  .bi {
    width: 1.5rem;
    color: #9ca3af;
  }
  span {
    margin-left: 0.75rem;
    color: #d1d5db;
  }

  /* 适配黑夜模式 */
  .dark-mode & {
    &:hover {
      background-color: var(--hover-bg);
    }

    &.active {
      background: var(--active-gradient);

      .bi, span {
        color: var(--active-text);
      }
    }

    .bi {
      color: var(--text-secondary);
    }

    span {
      color: var(--text-primary);
    }
  }
}

.content {
  flex: 1;
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: margin-left 0.3s ease;

  &.shift-content {
    margin-left: 200px;
  }

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-secondary);
  }
}

.breadcrumb {
  height: 4rem; // 明确设置高度
  background-color: #fff;
  padding: 0 1rem;
  @media (min-width: 768px) {
    height: 4rem;
    padding: 0 1.5rem;
  }
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e5e7eb;
  .bi {
    color: #9ca3af;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    &:first-child {
      margin-left: 0;
      color: #444;
    }
  }
  span {
    &:first-of-type {
      color: #6b7280;
    }
    &:last-of-type {
      color: #1f2937;
    }
  }
  .fas.fa-chevron-right {
    font-size: 0.75rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  /* 适配黑夜模式 */
  .dark-mode & {
    background-color: var(--bg-primary);
    border-bottom-color: var(--border-color);
    box-shadow: 0 1px 2px 0 var(--shadow-color);

    .bi {
      color: var(--text-secondary);

      &:first-child {
        color: var(--text-primary);
      }
    }

    span {
      &:first-of-type {
        color: var(--text-secondary);
      }

      &:last-of-type {
        color: var(--text-primary);
      }
    }

    .fas {
      color: var(--text-secondary);
    }
  }
}

.content-area {
  padding: 10px 12px 0 12px;
  flex: 1;
  background-color: #f9fafb;

  /* 适配黑夜模式 */
  .dark-mode & {
    color: var(--text-primary);
    background-color: var(--bg-secondary);
  }
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0);
    position: static;
  }
  .mobile-menu-button {
    display: none;
  }
  .content {
    margin-left: 0;
  }
}
</style>