<template>
  <div id="app">
    <!-- 顶部菜单 -->
    <div v-show="!ifScreen" class="head-tab">
      <HeadTab></HeadTab>
    </div>
    <!-- 内容部分 -->
    <div class="content" :class="{'screen-content': ifScreen}">
      <!-- 路由内容模块 -->
      <transition name="screen-transition">
        <div class="router-content" :class="{'screen-content': ifScreen, 'router-content-width-pc': ifPC, 'router-content-width-phone': !ifPC }">
          <div class="nav-btn">
            <span @click="handleAllScreen">
              <i v-if="!ifScreen" class="el-icon-full-screen el-icon"/>
              <i v-else class="el-icon-minus el-icon"/>
            </span>
            <span v-show="this.$route.path !== '/'" @click="handleClosePage"><i class="el-icon-close el-icon"/></span>
          </div>
          <keep-alive>
            <router-view  v-if="$route.meta.keepAlive"/>
          </keep-alive>
          <router-view  v-if="!$route.meta.keepAlive"/>
        </div>
      </transition>
      <!-- 底部模块 -->
      <div v-if="!ifScreen" class="footer">
        <span class="footer-text">皖ICP备2023024536号-3 | © 2023 - 2025 Sweek</span>
      </div>
    </div>
    <!-- 登录模块 -->
    <div v-if="showLogin" class="login-content">
      <div class="logo">
        <img src="https://www.sweek.top/api/preview/logo.png" alt="">
      </div>
      <LoginView></LoginView>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import HeadTab from '@/components/HeadTab/index.vue'
import LoginView from '@/views/login/index.vue'
export default {
  components: {
    HeadTab,
    LoginView
  },
  data() {
    return {
      ifPC: true,
      ifScreen: false
    }
  },
  computed: {
    showLogin() {
      return this.$store.state.showLogin
    }
  },
  mounted() {
    // 在组件挂载后添加 resize 事件监听器
    window.addEventListener('resize', this.handleResize);
    // 初始化检查
    this.handleResize();
    // 添加系统主题偏好监听
    this.setupThemePreferenceListener();
    // 添加滚动条检查
    this.fixScrollbarIssue();
    // 添加主题切换动画
    this.setupThemeTransition();
  },
  beforeDestroy() {
    // 在组件销毁前移除 resize 事件监听器
    window.removeEventListener('resize', this.handleResize);
    // 移除系统主题偏好监听
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.handleSystemThemeChange);
    }
    // 移除主题切换事件监听
    document.removeEventListener('theme-change', this.handleThemeTransition);
  },
  created() {
  },
  methods: {
    ...mapActions(['updateIfPC', 'toggleTheme']),
    handleResize() {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      this.ifPC = newWidth > newHeight;
      this.updateIfPC(this.ifPC)
    },
    handleAllScreen() {
      // 切换全屏状态
      this.ifScreen = !this.ifScreen;

      // 等待DOM更新后应用样式
      this.$nextTick(() => {
        // 应用滚动条修复
        this.applyScrollbarFix();

        // 立即应用一次全屏样式
        this.applyScreenStyles();

        // 获取store
        const store = this.$store;

        // 添加全屏模式样式
        if (this.ifScreen) {
          store.commit('ADD_SCREEN_MODE_STYLE');
        }

        // 延迟再应用一次，确保在过渡动画后生效
        setTimeout(() => {
          this.applyScreenStyles();

          // 强制触发重绘
          const content = document.querySelector('.content');
          if (content) {
            content.style.display = 'none';
            void content.offsetHeight; // 强制浏览器重绘
            content.style.display = this.ifScreen ? 'block' : 'flex';
          }

          const routerContent = document.querySelector('.router-content');
          if (routerContent) {
            routerContent.style.display = 'none';
            void routerContent.offsetHeight; // 强制浏览器重绘
            routerContent.style.display = 'block';
          }

          // 触发调整大小事件
          window.dispatchEvent(new Event('resize'));
        }, 300);

        // 第二次延迟，确保所有CSS计算完成
        setTimeout(() => {
          this.applyScreenStyles();

          // 确保路由内容始终撑满屏幕
          if (this.ifScreen) {
            const routerContent = document.querySelector('.router-content');
            if (routerContent) {
              Object.assign(routerContent.style, {
                minHeight: '100vh',
                height: '100vh'
              });
            }
          }

          // 再次触发调整大小事件
          window.dispatchEvent(new Event('resize'));
        }, 500);
      });
    },
    handleClosePage() {
      this.$router.go(-1)
    },
    setupThemePreferenceListener() {
      // 监听系统主题变化
      this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      this.mediaQuery.addEventListener('change', this.handleSystemThemeChange);
    },
    handleSystemThemeChange(e) {
      // 只有当用户没有明确设置主题时，才跟随系统主题变化
      if (!this.$store.state.userThemePreference) {
        if (e.matches) {
          this.$store.commit('SET_THEME_WITHOUT_PREFERENCE', 'dark');
        } else {
          this.$store.commit('SET_THEME_WITHOUT_PREFERENCE', 'light');
        }
      }
    },
    // 修复滚动条问题
    fixScrollbarIssue() {
      // 初始应用
      this.applyScrollbarFix();

      // 监听主题变化事件
      document.addEventListener('theme-change', () => {
        // 立即应用一次
        this.applyScrollbarFix();

        // 延迟再应用一次，确保在DOM更新后生效
        setTimeout(() => {
          this.applyScrollbarFix();
        }, 100);

        // 再次延迟应用，处理一些延迟渲染的情况
        setTimeout(() => {
          this.applyScrollbarFix();
        }, 300);
      });

      // 监听resize事件，确保在窗口大小变化时也应用修复
      window.addEventListener('resize', () => {
        this.applyScrollbarFix();
      }, { passive: true });
    },
    // 直接应用滚动条修复
    applyScrollbarFix() {
      // 获取头部菜单元素
      const headTab = document.querySelector('.head-tab');
      const tabBar = document.querySelector('.tab-bar');
      const tabBarInner = document.querySelector('.tab-bar-inner');
      const elMenu = document.querySelector('.el-menu--horizontal');

      // 检查是否为黑夜模式和全屏模式
      const isDarkMode = document.documentElement.classList.contains('dark-mode');
      const isScreenMode = document.querySelector('.router-content.screen-content') !== null;

      // 应用强制样式
      if (headTab) {
        Object.assign(headTab.style, {
          height: '50px',
          maxHeight: '50px',
          overflow: 'hidden',
          padding: '0',
          margin: '0',
          border: 'none',
          boxSizing: 'border-box'
        });
      }

      if (tabBar) {
        Object.assign(tabBar.style, {
          height: '50px',
          maxHeight: '50px',
          overflowX: isDarkMode ? 'auto' : 'auto', // 黑夜模式下也保留功能，但CSS会隐藏滚动条
          overflowY: 'hidden',
          border: 'none',
          boxSizing: 'border-box'
        });
      }

      if (tabBarInner) {
        Object.assign(tabBarInner.style, {
          maxHeight: '50px',
          overflowX: 'visible',
          overflowY: 'hidden',
          boxSizing: 'border-box',
          width: 'max-content',
          minWidth: '100%'
        });
      }

      if (elMenu) {
        Object.assign(elMenu.style, {
          height: '50px',
          maxHeight: '50px',
          border: 'none',
          boxSizing: 'border-box',
          paddingLeft: '140px' // 为logo预留空间
        });

        // 处理菜单项
        const menuItems = document.querySelectorAll('.el-menu-item');
        menuItems.forEach(item => {
          Object.assign(item.style, {
            height: '48px',
            maxHeight: '48px',
            lineHeight: '48px',
            border: 'none',
            boxSizing: 'border-box'
          });
        });
      }

      // 确保移动端菜单项样式正确
      const tabBarItems = document.querySelectorAll('.tab-bar-item');
      tabBarItems.forEach(item => {
        Object.assign(item.style, {
          height: '45px',
          maxHeight: '45px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 8px',
          margin: '0 3px',
          flexShrink: '0'
        });
      });

      // 设置内容区域正确样式
      const content = document.querySelector('.content');
      if (content) {
        Object.assign(content.style, {
          height: isScreenMode ? '100vh' : 'calc(100vh - 50px)',
          maxHeight: isScreenMode ? '100vh' : 'calc(100vh - 50px)',
          boxSizing: 'border-box',
          overflowY: 'auto', // 保留滚动功能
          overflowX: 'hidden',
          padding: '0',
          margin: '0',
          width: '100%'
        });

        // 如果是黑夜模式，添加隐藏滚动条的样式
        if (isDarkMode) {
          content.style.msOverflowStyle = 'none'; // IE 和 Edge
          content.style.scrollbarWidth = 'none'; // Firefox
        }
      }

      // 确保路由内容区域样式正确
      const routerContent = document.querySelector('.router-content');
      if (routerContent) {
        // 检查是否为全屏模式
        const isScreenContent = routerContent.classList.contains('screen-content');

        Object.assign(routerContent.style, {
          boxSizing: 'border-box',
          maxWidth: isScreenContent ? '100%' : 'calc(100% - 20px)',
          width: isScreenContent ? '100vw' : '',
          margin: isScreenContent ? '0' : '10px 0',
          border: 'none',
          borderRadius: isScreenContent ? '0' : '5px',
          overflowY: 'auto', // 允许滚动
          overflowX: 'hidden',
          minHeight: isScreenContent ? '100vh' : ''
        });

        // 黑夜模式下隐藏滚动条但保留功能
        if (isDarkMode) {
          routerContent.style.msOverflowStyle = 'none'; // IE 和 Edge
          routerContent.style.scrollbarWidth = 'none'; // Firefox
        }
      }

      // 确保logo样式正确
      const logo = document.querySelector('.logo');
      if (logo) {
        Object.assign(logo.style, {
          position: 'absolute',
          left: '20px',
          zIndex: '10'
        });
      }

      // 应用到html和body
      document.body.style.overflowY = 'hidden';
      document.documentElement.style.overflowY = 'hidden';

      // 黑夜模式下确保所有元素都没有滚动条
      if (isDarkMode) {
        // 为所有可能有滚动条的元素添加无滚动条样式
        const scrollableElements = document.querySelectorAll('div');
        scrollableElements.forEach(el => {
          if (getComputedStyle(el).overflow === 'auto' ||
              getComputedStyle(el).overflowY === 'auto' ||
              getComputedStyle(el).overflow === 'scroll' ||
              getComputedStyle(el).overflowY === 'scroll') {
            el.style.msOverflowStyle = 'none';
            el.style.scrollbarWidth = 'none';
          }
        });
      }
    },
    // 应用全屏相关样式
    applyScreenStyles() {
      const isScreenMode = this.ifScreen;

      // 设置内容区域正确样式
      const content = document.querySelector('.content');
      if (content) {
        Object.assign(content.style, {
          height: isScreenMode ? '100vh' : 'calc(100vh - 50px)',
          maxHeight: isScreenMode ? '100vh' : 'calc(100vh - 50px)',
          width: isScreenMode ? '100vw' : '100%',
          position: isScreenMode ? 'absolute' : '',
          left: isScreenMode ? '0' : '',
          right: isScreenMode ? '0' : '',
          top: isScreenMode ? '0' : '',
          bottom: isScreenMode ? '0' : '',
          margin: isScreenMode ? '0' : '',
          padding: isScreenMode ? '0' : ''
        });
      }

      // 设置路由内容区域正确样式
      const routerContent = document.querySelector('.router-content');
      if (routerContent) {
        Object.assign(routerContent.style, {
          height: isScreenMode ? '100vh' : '',
          minHeight: isScreenMode ? '100vh' : '',
          maxHeight: isScreenMode ? '100vh' : '',
          maxWidth: isScreenMode ? '100%' : '',
          width: isScreenMode ? '100vw' : '',
          margin: isScreenMode ? '0' : '',
          padding: isScreenMode ? '0' : '',
          borderRadius: isScreenMode ? '0' : '',
          position: isScreenMode ? 'absolute' : '',
          top: isScreenMode ? '0' : '',
          left: isScreenMode ? '0' : '',
          right: isScreenMode ? '0' : '',
          bottom: isScreenMode ? '0' : '',
          display: isScreenMode ? 'block' : ''
        });

        // 强制应用特定样式，覆盖可能的CSS优先级问题
        if (isScreenMode) {
          // 添加内联强制样式
          const styleEl = document.getElementById('screen-mode-style') || document.createElement('style');
          if (!document.getElementById('screen-mode-style')) {
            styleEl.id = 'screen-mode-style';
            document.head.appendChild(styleEl);
          }
          styleEl.textContent = `
            .router-content.screen-content {
              height: 100vh !important;
              max-height: 100vh !important;
              min-height: 100vh !important;
              width: 100vw !important;
              max-width: 100% !important;
              margin: 0 !important;
              padding: 0 !important;
              position: absolute !important;
              top: 0 !important;
              left: 0 !important;
              right: 0 !important;
              bottom: 0 !important;
              border-radius: 0 !important;
              overflow-y: auto !important;
            }
          `;
        } else {
          // 移除全屏样式
          const styleEl = document.getElementById('screen-mode-style');
          if (styleEl) {
            styleEl.textContent = '';
          }
        }
      }

      // 强制重新计算布局
      if (isScreenMode) {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 50);
      }
    },
    // 设置主题切换动画
    setupThemeTransition() {
      document.addEventListener('theme-change', this.handleThemeTransition);
    },

    // 处理主题切换动画
    handleThemeTransition(event) {
      // 获取当前主题
      const isDarkMode = event.detail && event.detail.theme === 'dark';

      // 创建过渡元素
      const transitionEl = document.createElement('div');
      transitionEl.className = 'theme-transition-overlay';
      transitionEl.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');

      // 获取窗口尺寸
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // 根据主题变化设置不同的起始位置和样式
      let startPos, endPos;

      if (isDarkMode) {
        // 白天到黑夜：从右上到左下
        startPos = {
          top: '20px',
          right: '20px',
          left: 'auto',
          bottom: 'auto',
          transformOrigin: 'top right'
        };

        endPos = (size) => ({
          top: '-' + (size / 2 - 20) + 'px',
          right: '-' + (size / 2 - 20) + 'px',
          left: 'auto',
          bottom: 'auto'
        });
      } else {
        // 黑夜到白天：从左下到右上
        startPos = {
          top: 'auto',
          right: 'auto',
          left: '20px',
          bottom: '20px',
          transformOrigin: 'bottom left'
        };

        endPos = (size) => ({
          top: 'auto',
          right: 'auto',
          left: '-' + (size / 2 - 20) + 'px',
          bottom: '-' + (size / 2 - 20) + 'px'
        });
      }

      // 设置基本样式
      Object.assign(transitionEl.style, {
        position: 'fixed',
        width: '0',
        height: '0',
        backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
        borderRadius: '50%',
        zIndex: '9999',
        pointerEvents: 'none',
        opacity: '0.8',
        transition: 'all 1.5s cubic-bezier(0.23, 1, 0.32, 1)', // 延长动画时间
        boxShadow: isDarkMode ? '0 0 30px rgba(0, 0, 0, 0.5)' : '0 0 30px rgba(255, 255, 255, 0.5)',
        ...startPos
      });

      // 添加到DOM
      document.body.appendChild(transitionEl);

      // 触发布局重绘
      void transitionEl.offsetWidth;

      // 设置终止状态 - 使圆形能够完全覆盖屏幕
      const screenSize = Math.max(windowWidth, windowHeight) * 2.5;
      Object.assign(transitionEl.style, {
        width: screenSize + 'px',
        height: screenSize + 'px',
        ...endPos(screenSize),
        opacity: '1'
      });

      // 创建太阳/月亮动画元素
      const createIconAnimation = () => {
        // 创建容器
        const iconContainer = document.createElement('div');
        iconContainer.className = 'theme-icon-container';

        // 基础样式
        Object.assign(iconContainer.style, {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '160px',  // 增大容器尺寸
          height: '160px', // 增大容器尺寸
          zIndex: '10000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
          opacity: '0',
          transition: 'opacity 0.3s ease'
        });

        // 创建两个图标元素
        const sunIcon = document.createElement('i');
        sunIcon.className = 'el-icon-sunny theme-icon sun-icon';

        const moonIcon = document.createElement('i');
        moonIcon.className = 'el-icon-moon theme-icon moon-icon';

        // 设置图标样式
        const iconStyle = {
          fontSize: '120px', // 增大图标尺寸
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          transition: 'all 1.8s cubic-bezier(0.34, 1.56, 0.64, 1)' // 延长动画时间
        };

        Object.assign(sunIcon.style, {
          ...iconStyle,
          color: '#f39c12',
          opacity: isDarkMode ? '1' : '0',
          transform: isDarkMode ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0.5) rotate(-180deg)'
        });

        Object.assign(moonIcon.style, {
          ...iconStyle,
          color: '#dcdcdc',
          opacity: isDarkMode ? '0' : '1',
          transform: isDarkMode ? 'translate(-50%, -50%) scale(0.5) rotate(180deg)' : 'translate(-50%, -50%) scale(1)'
        });

        // 添加图标到容器
        iconContainer.appendChild(sunIcon);
        iconContainer.appendChild(moonIcon);

        // 添加容器到DOM
        document.body.appendChild(iconContainer);

        // 显示容器
        setTimeout(() => {
          iconContainer.style.opacity = '1';

          // 执行图标变换动画
          if (isDarkMode) {
            // 太阳变月亮
            sunIcon.style.opacity = '0';
            sunIcon.style.transform = 'translate(-50%, -50%) scale(0.5) rotate(-180deg)';

            moonIcon.style.opacity = '1';
            moonIcon.style.transform = 'translate(-50%, -50%) scale(1)';
          } else {
            // 月亮变太阳
            moonIcon.style.opacity = '0';
            moonIcon.style.transform = 'translate(-50%, -50%) scale(0.5) rotate(180deg)';

            sunIcon.style.opacity = '1';
            sunIcon.style.transform = 'translate(-50%, -50%) scale(1)';
          }

          // 动画完成后移除元素
          setTimeout(() => {
            iconContainer.style.opacity = '0';

            setTimeout(() => {
              if (document.body.contains(iconContainer)) {
                document.body.removeChild(iconContainer);
              }
            }, 300);
          }, 1800); // 延长图标显示时间
        }, 1300); // 在圆形动画接近完成时显示

        return iconContainer;
      };

      // 创建并启动图标动画
      createIconAnimation();

      // 动画完成后不立即移除元素，保持一段时间后再淡出
      setTimeout(() => {
        // 渐变淡出
        transitionEl.style.opacity = '0';

        // 完全淡出后移除元素
        setTimeout(() => {
          if (document.body.contains(transitionEl)) {
            document.body.removeChild(transitionEl);
          }
        }, 300);
      }, 3100); // 延长保持时间，等待图标动画完成
    }
  }
}
</script>

<style lang="scss" scoped>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed; /* 固定定位避免页面滚动 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .head-tab {
    height: 50px;
    max-height: 50px !important; /* 强制最大高度 */
    width: 100%;
    overflow: hidden; /* 确保头部菜单不会产生滚动条 */
    box-sizing: border-box !important; /* 强制使用边框盒模型 */
    padding: 0 !important; /* 移除可能的内边距 */
    border: none !important; /* 移除可能的边框 */
    position: relative; /* 使用相对定位 */
    z-index: 10; /* 确保在其他元素之上 */
  }

  .content {
    height: calc(100vh);
    width: 100%;
    background-color: var(--bg-secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: width 0.3s ease, height 0.3s ease;
    overflow-y: auto; /* 只允许内容区域垂直滚动 */
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; /* 增强移动端滚动体验 */
    box-sizing: border-box !important; /* 强制使用边框盒模型 */
    padding: 0; /* 移除内边距 */
    margin: 0; /* 移除外边距 */

    .router-content {
      min-height: calc(100vh - 130px); /* 减小最小高度，防止内容溢出 */
      background-color: var(--bg-primary);
      border-radius: 5px;
      position: relative;
      overflow: hidden; /* 防止内容溢出 */
      width: 100%;
      max-width: 100%;
      margin: 10px 0;
      box-sizing: border-box;

      &.router-content-width-pc {
        width: calc(100vw - 20px);
        margin: 10px 0; /* PC端恢复默认外边距 */
      }

      &.router-content-width-phone {
        width: 100vw !important;
        max-width: 100% !important;
        margin: 10px 0 !important; /* 手机端只有上下外边距 */
      }

      .nav-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 60px;
        height: 30px;
        line-height: 30px;
        z-index: 9;
        text-align: right;
        .el-icon {
          color: var(--text-primary);
          background-color: #bababab7;
          padding: 8px;
          border-radius: 15px;
          transform: scale(0.65);
          cursor: pointer;
        }
        .el-icon:hover {
          color: var(--bg-primary);
          background-color: rgba(128, 128, 128, 0.774);
        }
      }
    }
    .footer {
      height: 50px;
      width: 100%;
      background-color: var(--bg-primary);
      text-align: center;
      line-height: 50px;
      &-text {
        font-size: 11px;
        color: var(--text-primary);
      }
    }
  }
  .screen-content {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    transition: all 0.3s ease;
  }
  .login-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw !important;
    height: 100vh !important;
    background-color: var(--bg-primary);
    opacity: 0.9;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* 动画相关样式 */
  .screen-transition-enter-active,
  .screen-transition-leave-active {
    transition: all 0.5s ease;
  }
  .screen-transition-enter,
  .screen-transition-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
}
.logo {
  position: absolute;
  top: 50px;
  left: calc(50% - 50px);
  display: flex;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
}

/* 全屏模式下的路由内容 */
.router-content.screen-content {
  max-width: 100% !important; /* 覆盖原有的max-width设置 */
  width: 100vw !important;
  height: 100vh !important; /* 确保填满整个视口高度 */
  margin: 0 !important;
  padding: 0 !important; /* 确保没有内边距 */
  border-radius: 0 !important; /* 移除圆角 */
  min-height: 100vh !important; /* 确保最小高度填满屏幕 */
  position: absolute !important; /* 使用绝对定位 */
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important; /* 四周定位都设为0确保填满 */
  overflow-y: auto !important; /* 允许内容滚动 */
}

/* 主题切换动画 */
.theme-transition-overlay {
  pointer-events: none;
  will-change: width, height, opacity, transform;
  border-radius: 50%;
  transition: width 1.5s cubic-bezier(0.23, 1, 0.32, 1),
              height 1.5s cubic-bezier(0.23, 1, 0.32, 1),
              opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1),
              transform 1.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 9999;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  position: fixed;
  /* 提高性能的属性 */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* 太阳/月亮图标样式 */
.theme-icon-container {
  will-change: opacity;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.theme-icon {
  will-change: transform, opacity;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.4));
}

.sun-icon {
  font-size: 120px !important;
  color: #f39c12 !important;
  text-shadow: 0 0 30px rgba(243, 156, 18, 0.7);
}

.moon-icon {
  font-size: 110px !important;
  color: #e6e6e6 !important;
  text-shadow: 0 0 30px rgba(255, 255, 255, 0.7);
}

/* 为主题切换添加不同方向的变体 */
.theme-transition-overlay[data-theme="dark"] {
  transform-origin: top right;
}

.theme-transition-overlay[data-theme="light"] {
  transform-origin: bottom left;
}

/* 为容器添加一些过渡效果，使主题变化更平滑 */
#app, .router-content, .content, .head-tab, .footer {
  transition: background-color 0.8s ease-in-out,
              color 0.8s ease-in-out,
              border-color 0.8s ease-in-out,
              box-shadow 0.8s ease-in-out;
}

/* 确保所有文本颜色也有过渡效果 */
* {
  transition: color 0.6s ease-in-out,
              background-color 0.6s ease-in-out,
              border-color 0.6s ease-in-out,
              box-shadow 0.6s ease-in-out;
}
</style>