<template>
  <div ref="courseView" class="course-page">
    <!-- 顶部视觉区域 -->
    <div class="hero-section">
      <div class="hero-content">
        <h1 class="hero-title">我的课程</h1>
        <p class="hero-subtitle">持续学习，不断提升自我</p>
      </div>
    </div>

    <div class="course-header">
      <div class="course-stats">
        <div class="stat-item">
          <span class="stat-value">{{total}}</span>
          <span class="stat-label">总课程数</span>
        </div>
        <div class="stat-item">
          <span class="stat-value">{{courses.length}}</span>
          <span class="stat-label">已加载课程数</span>
        </div>
        <div class="stat-item">
          <span class="stat-value">{{getCompletedCount()}}</span>
          <span class="stat-label">已完成</span>
        </div>
        <div class="stat-item">
          <span class="stat-value">{{getAverageProgress()}}%</span>
          <span class="stat-label">平均完成度</span>
        </div>
      </div>
      <div class="course-search-bar">
        <i class="bi bi-search"></i>
        <input type="text" placeholder="搜索课程..." />
      </div>
    </div>

    <div class="course-container">
      <div class="course-masonry">
        <div v-for="(course, index) in courses" :key="index" class="course-item"
             :class="{'course-item--featured': index % 5 === 0}">
          <div class="course-card" @click="toDetail(course)">
            <div class="course-badge" v-if="parseFloat(course.percentage) === 100">已完成</div>
            <div class="course-badge course-badge--in-progress" v-else-if="parseFloat(course.percentage) > 0">进行中</div>
            <div class="course-badge course-badge--new" v-else>未开始</div>

            <div class="course-image">
              <el-image :src="course.course_img"></el-image>
              <div class="course-overlay">
                <span class="course-btn">查看详情</span>
              </div>
            </div>
            <div class="course-content">
              <h3 class="course-title">{{ course.course_name }}</h3>

              <div class="course-progress">
                <div class="progress-track">
                  <div class="progress-fill" :style="`width: ${course.percentage}%`"
                       :class="getProgressColor(course.percentage)"></div>
                </div>
                <span class="progress-text">{{ course.percentage }}% 完成</span>
              </div>

              <div class="course-meta">
                <div class="meta-item">
                  <i class="bi bi-book"></i>
                  <span>{{ course.current_chaptername }}</span>
                </div>
                <div class="meta-item">
                  <i class="bi bi-clock-history"></i>
                  <span>{{ formatTimeAgo(course.updated_at) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="load-more-container" v-if="courses.length > 0">
          <button @click="getMore" class="load-more-btn" :disabled="query.pageNum >= totalPages">
            <span v-if="query.pageNum < totalPages">
              <i class="bi bi-plus-circle"></i> 加载更多课程
            </span>
            <span v-else>已经到底啦 ~</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 无课程时的空状态 -->
    <div class="empty-state" v-if="courses.length === 0 && !loading">
      <div class="empty-icon">
        <i class="bi bi-journal-x"></i>
      </div>
      <h3 class="empty-title">暂无课程</h3>
      <p class="empty-desc">您目前没有任何课程，开始学习吧！</p>
    </div>

    <!-- 动态背景装饰 -->
    <div class="decoration-element decoration-circle-1"></div>
    <div class="decoration-element decoration-circle-2"></div>
    <div class="decoration-element decoration-circle-3"></div>
    <div class="decoration-dots"></div>

    <!-- 悬浮操作按钮 -->
    <div class="floating-actions">
      <button @click="refreshPage" class="action-btn refresh-btn" title="刷新页面">
        <i class="bi bi-arrow-clockwise"></i>
      </button>
      <button @click="scrollToTop" class="action-btn top-btn" title="回到顶部">
        <i class="bi bi-chevron-up"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { getCoursesByEmail } from '@/api/course'
import _ from 'lodash'
export default {
  name: 'Course',
  data() {
    return {
      query: {
        pageSize: 10,
        pageNum: 1
      },
      totalPages: null,
      total: null,
      courses: [],
      loading: false
    };
  },
  computed: {
    showLogin() {
      return this.$store.state.showLogin
    },
    userInfo() {
      return this.$store.state.userInfo || null;
    },
    nickName() {
      const nickName = this.userInfo?.nickname || '';
      return nickName.length > 6 ? nickName.substring(0, 6) + '...' : nickName;
    },
    ifPC() {
      return this.$store.state.ifPC;
    }
  },
  watch: {
    showLogin: _.debounce(function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCourses();
      }
    }, 2500)
  },
  activated() {
    this.$refs.courseView.scrollTop = this.$route.meta.scrollTop
  },
  beforeRouteLeave(to, from, next) { // 离开组件的时候触发
    from.meta.scrollTop = this.$refs.courseView.scrollTop
    next()
  },
  created() {
    this.getCourses()
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString) // 将ISO字符串转换为 Date 对象
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份是从0开始的，需要加1
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')
      // 返回格式化后的日期字符串
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    formatTimeAgo(dateString) {
      const now = new Date();
      const past = new Date(dateString);
      const diffMs = now - past;
      const diffSec = Math.floor(diffMs / 1000);
      const diffMin = Math.floor(diffSec / 60);
      const diffHour = Math.floor(diffMin / 60);
      const diffDay = Math.floor(diffHour / 24);

      if (diffDay > 30) {
        return dateString.substring(0, 10);
      } else if (diffDay > 0) {
        return `${diffDay}天前`;
      } else if (diffHour > 0) {
        return `${diffHour}小时前`;
      } else if (diffMin > 0) {
        return `${diffMin}分钟前`;
      } else {
        return '刚刚';
      }
    },
    // 提取字符串中的时间
    extractDateTime(str) {
      // 定义匹配日期时间的正则表达式
      const regex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/
      const match = str.match(regex) // 使用正则表达式匹配字符串
      return match ? match[0] : null // 如果找到了匹配的结果，则返回第一个匹配项，否则返回 null
    },
    getProgressColor(progress) {
      if (progress < 30) return 'progress-fill--low';
      if (progress < 70) return 'progress-fill--medium';
      return 'progress-fill--high';
    },
    scrollToTop() {
      this.$refs.courseView.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    refreshPage() {
      this.query = this.$options.data().query
      this.getCourses()
    },
    getCompletedCount() {
      return this.courses.filter(course => parseFloat(course.percentage) === 100).length;
    },
    getAverageProgress() {
      if (this.courses.length === 0) return 0;
      const totalProgress = this.courses.reduce((sum, course) => sum + parseFloat(course.percentage), 0);
      return Math.round(totalProgress / this.courses.length);
    },
    // 获取课程信息
    getCourses() {
      this.courses = []
      this.loading = true
      const params = {
        ...this.query,
        email: this.userInfo?.email ? this.userInfo?.email : '-'
      }
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      getCoursesByEmail(params).then(res => {
        this.totalPages = res.data?.totalPages || 0
        this.total = res.data?.total || 0
        this.courses = res.data?.courses || []
        this.courses.forEach(item => {
          const chapter = JSON.parse(item.chapter).filter(item => item.ifTitle !== 1)
          const statusOneCount = chapter.filter(item => item.status === 1).length
          const percentage = (statusOneCount / chapter.length * 100).toFixed(2)
          item.percentage = percentage
          item.current_chaptername = item.current_chapter.split(',')[1]
          item.created_at = this.formatDate(item.created_at)
          item.updated_at = this.formatDate(item.updated_at)
        })
      }).finally(() => {
        loading.close()
        this.loading = false
      })
    },
    getMore() {
      if (this.query.pageNum >= this.totalPages) return
      this.query.pageNum += 1
      const params = {
        ...this.query,
        email: this.userInfo.email
      }
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      getCoursesByEmail(params).then(res => {
        const courses = res.data.courses || []
        courses.forEach(item => {
          const chapter = JSON.parse(item.chapter).filter(item => item.ifTitle !== 1)
          const statusOneCount = chapter.filter(item => item.status === 1).length
          const percentage = (statusOneCount / chapter.length * 100).toFixed(2)
          item.percentage = percentage
          item.current_chaptername = item.current_chapter.split(',')[1]
          item.created_at = this.formatDate(item.created_at)
          item.updated_at = this.formatDate(item.updated_at)
          this.courses.push(item)
        })
      }).finally(() => {
        loading.close()
      })
    },
    toDetail(item) {
      this.$router.push({
        path: '/course-detail',
        query: {
          course_id: item.course_id,
          current_chapter: item.current_chaptername,
          percentage: item.percentage
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
// 全局变量
:root {
  --primary-color: #4f46e5;
  --primary-light: #818cf8;
  --primary-dark: #3730a3;
  --success-color: #10b981;
  --warning-color: #f59e0b;
  --danger-color: #ef4444;
  --card-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.05),
                 0 8px 10px -6px rgba(0, 0, 0, 0.01);
  --transition-bounce: cubic-bezier(0.34, 1.56, 0.64, 1);
}

// 全局样式
.course-page {
  height: 100%;
  background: linear-gradient(135deg, var(--bg-primary) 0%, var(--bg-secondary) 100%);
  position: relative;
  overflow-x: hidden;
  padding-bottom: 5rem;
}

// 英雄区域
.hero-section {
  background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary-color) 100%);
  height: 20vh;
  min-height: 200px;
  max-height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:
      radial-gradient(circle at 20% 80%, rgba(255, 255, 255, 0.1) 0%, transparent 20%),
      radial-gradient(circle at 80% 30%, rgba(255, 255, 255, 0.1) 0%, transparent 20%);
    opacity: 0.6;
  }
}

.hero-content {
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 3rem;
  font-weight: 800;
  margin: 0;
  letter-spacing: -0.025em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, #fff, #e2e8f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
}

.hero-subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  opacity: 0.9;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

// 头部区域
.course-header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 2rem;
  transform: translateY(-50px);
  background: var(--card-bg);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    padding: 1.5rem;
  }
}

.course-stats {
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    justify-content: space-around;
  }
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-value {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--primary-color);
}

.stat-label {
  font-size: 0.875rem;
  color: var(--gray-500);
  margin-top: 0.25rem;
  text-align: center;
}

.course-search-bar {
  position: relative;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }

  i {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
    font-size: 1rem;
  }

  input {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    border-radius: 9999px;
    border: 1px solid var(--border-color);
    background-color: var(--bg-primary);
    color: var(--text-primary);
    font-size: 0.875rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;

    &:focus {
      outline: none;
      border-color: var(--primary-light);
      box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.15);
    }

    &::placeholder {
      color: var(--text-secondary);
    }
  }
}

// 课程容器
.course-container {
  max-width: 1440px;
  margin: -1rem auto 0;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
}

.course-masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

// 课程卡片
.course-item {
  will-change: transform;
  transform: translateZ(0);

  &--featured {
    grid-column: span 1;

    @media (min-width: 768px) {
      grid-column: span 2;
    }

    .course-card {
      background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary-color) 100%);

      .course-title, .progress-text, .meta-item {
      }

      .progress-track {
        background-color: rgba(255, 255, 255, 0.3);
      }

      .course-btn {
        background-color: white;
        color: var(--primary-dark);
      }
    }
  }
}

.course-card {
  background-color: var(--card-bg);
  border-radius: 1.25rem;
  box-shadow: var(--card-shadow);
  overflow: hidden;
  position: relative;
  transition: transform 0.5s var(--transition-bounce),
              box-shadow 0.4s ease;
  height: 100%;
  cursor: pointer;
  border: 1px solid var(--border-color);

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 30px -10px var(--shadow-color),
                0 10px 15px -3px var(--shadow-color);

    .course-overlay {
      opacity: 1;
    }

    .course-image img {
      transform: scale(1.08);
    }
  }
}

.course-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: var(--success-color);
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.35rem 0.75rem;
  border-radius: 9999px;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(16, 185, 129, 0.3);

  &--in-progress {
    background-color: var(--warning-color);
    box-shadow: 0 2px 10px rgba(245, 158, 11, 0.3);
  }

  &--new {
    background-color: var(--primary-light);
    box-shadow: 0 2px 10px rgba(129, 140, 248, 0.3);
  }
}

.course-image {
  height: 180px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 140px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(to bottom, rgba(0,0,0,0.3), transparent);
    z-index: 1;
  }

  img, .el-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s ease;
  }
}

.course-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.course-btn {
  background-color: var(--primary-color);
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  transition: transform 0.2s ease, background-color 0.2s ease;

  &:hover {
    transform: scale(1.05);
    background-color: var(--primary-dark);
  }
}

.course-content {
  padding: 1.5rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.course-title {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0 0 1.25rem;
  line-height: 1.4;
  height: 2.8rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin: 0 0 1rem;
    height: 2.5rem;
  }
}

.course-progress {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
}

.progress-track {
  height: 0.75rem;
  background-color: var(--bg-secondary);
  border-radius: 9999px;
  overflow: hidden;
  margin-bottom: 0.5rem;
  width: 100%;
}

.progress-fill {
  height: 100%;
  border-radius: 9999px;
  transition: width 1s ease;

  &--low {
    background: linear-gradient(90deg, #ef4444, #f87171);
  }

  &--medium {
    background: linear-gradient(90deg, #f59e0b, #fbbf24);
  }

  &--high {
    background: linear-gradient(90deg, #10b981, #34d399);
  }
}

.progress-text {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
  display: block;
  text-align: right;
  align-self: flex-end;
}

.course-meta {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }

  i {
    font-size: 0.95rem;

    @media (max-width: 768px) {
      font-size: 0.85rem;
    }
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
  }
}

// 加载更多按钮
.load-more-container {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.load-more-btn {
  background-color: var(--card-bg);
  color: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: 0.75rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover:not(:disabled) {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  i {
    font-size: 1.125rem;
  }
}

// 空状态样式
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 2rem;
  text-align: center;
  max-width: 500px;
  margin: 3rem auto;
  background: var(--card-bg);
  border-radius: 1rem;
  box-shadow: var(--card-shadow);
}

.empty-icon {
  font-size: 4rem;
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
}

.empty-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 0.75rem;
}

.empty-desc {
  font-size: 1rem;
  color: var(--text-secondary);
  margin: 0;
}

// 装饰元素
.decoration-element {
  position: absolute;
  border-radius: 50%;
  filter: blur(40px);
  opacity: 0.15;
  z-index: -1;
}

.decoration-circle-1 {
  width: 30rem;
  height: 30rem;
  background-color: var(--primary-color);
  top: -10rem;
  right: -5rem;
  animation: float 30s infinite alternate ease-in-out;
}

.decoration-circle-2 {
  width: 25rem;
  height: 25rem;
  background-color: var(--success-color);
  bottom: -5rem;
  left: -10rem;
  animation: float 20s infinite alternate-reverse ease-in-out;
}

.decoration-circle-3 {
  width: 15rem;
  height: 15rem;
  background-color: var(--warning-color);
  top: 50%;
  left: 30%;
  animation: float 25s infinite alternate ease-in-out;
}

.decoration-dots {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(var(--border-color) 1px, transparent 1px);
  background-size: 50px 50px;
  opacity: 0.1;
  z-index: -1;
}

// 悬浮操作按钮
.floating-actions {
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 100;
}

.action-btn {
  width: 3.25rem;
  height: 3.25rem;
  border: none;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s var(--transition-bounce);
  box-shadow: 0 10px 15px -3px var(--shadow-color),
              0 4px 6px -2px var(--shadow-color);
  background-color: var(--card-bg);
  color: var(--text-primary);
  font-size: 1.25rem;

  &:hover {
    transform: translateY(-5px);
  }

  &:active {
    transform: translateY(-2px);
  }
}

// 动画
@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-3%, 3%);
  }
  100% {
    transform: translate(3%, -3%);
  }
}
</style>
