import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import AiChatView from '../views/aichat/index.vue'
import ProjectView from '../views/project/index.vue'
import ToolsView from '../views/tools/index.vue'
import VoicemailView from '../views/voicemail/index.vue'
import UserCenterView from '../views/usercenter/index.vue'
import CourseView from '../views/course/index.vue'
import CourseDetailView from '../views/course/detail.vue'
import ManageView from '../views/manage/index.vue'
import UsingTutorialsView from '../views/usingtutorials/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
    // component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/aichat',
    name: 'AiChatView',
    component: AiChatView,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/course',
    name: 'CourseView',
    component: CourseView,
    meta: {
      keepAlive: true,
      scrollTop: 0
    }
  },
  {
    path: '/course-detail',
    name: 'CourseDetailView',
    component: CourseDetailView,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/project',
    name: 'ProjectView',
    component: ProjectView,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/tools',
    name: 'ToolsView',
    component: ToolsView,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/voicemail',
    name: 'VoicemailView',
    component: VoicemailView,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/usercenter',
    name: 'UserCenter',
    component: UserCenterView,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/manage',
    name: 'Manage',
    component: ManageView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/usingtutorials',
    name: 'UsingTutorials',
    component: UsingTutorialsView,
    meta: {
      keepAlive: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})


// 全局拦截(守卫)

router.beforeEach((to, from, next) => {
  // 白名单：允许未登录访问的页面
  const whiteList = ['/', '/about', '/usingtutorials'];

  // 如果存在 token，直接放行
  if (localStorage.getItem('personlog_token')) {
    next();
  } else {
    // 如果在白名单里，也放行
    if (whiteList.includes(to.path)) {
      next();
    } else {
      // 如果 router.app 尚未挂载，避免报错
      if (router.app && router.app.$message) {
        router.app.$message.error('请先登录！');
        router.app.$store.commit('SET_SHOW_LOGIN', true);
        next();
      }
    }
  }
});



export default router
