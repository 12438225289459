<template>
  <div class="voicemail">
    voicemail
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'VoicemailView',
  components: {
  }
}
</script>
<style lang="scss" scoped>
.voicemail {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 5px;
}
</style>

